var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import moment from "moment";
import { BfLog4js, BfLogAppender, BfLogLevel } from "@tw/tw-log4js";
const ConditionValueTypes = {
  value: "value",
  fontExp: "frontExp",
  thirdExp: "thirdExp"
};
var ConditionMatchTypeDTO = /* @__PURE__ */ ((ConditionMatchTypeDTO2) => {
  ConditionMatchTypeDTO2["AND"] = "AND";
  ConditionMatchTypeDTO2["OR"] = "OR";
  return ConditionMatchTypeDTO2;
})(ConditionMatchTypeDTO || {});
var SortTypeDTO = /* @__PURE__ */ ((SortTypeDTO2) => {
  SortTypeDTO2["ASC"] = "ASC";
  SortTypeDTO2["DESC"] = "DESC";
  return SortTypeDTO2;
})(SortTypeDTO || {});
var ConditionRuleDTO = /* @__PURE__ */ ((ConditionRuleDTO2) => {
  ConditionRuleDTO2["GT"] = "GT";
  ConditionRuleDTO2["GE"] = "GE";
  ConditionRuleDTO2["LT"] = "LT";
  ConditionRuleDTO2["LE"] = "LE";
  ConditionRuleDTO2["EQ"] = "EQ";
  ConditionRuleDTO2["NE"] = "NE";
  ConditionRuleDTO2["IN"] = "IN";
  ConditionRuleDTO2["LIKE"] = "LIKE";
  ConditionRuleDTO2["LEFT_LIKE"] = "LEFT_LIKE";
  ConditionRuleDTO2["RIGHT_LIKE"] = "RIGHT_LIKE";
  ConditionRuleDTO2["IS_NULL"] = "IS_NULL";
  ConditionRuleDTO2["NOT_NULL"] = "NOT_NULL";
  ConditionRuleDTO2["SQL_RULES"] = "SQL_RULES";
  return ConditionRuleDTO2;
})(ConditionRuleDTO || {});
var DeviceType = /* @__PURE__ */ ((DeviceType2) => {
  DeviceType2["WEB"] = "web";
  DeviceType2["ANDROID"] = "android";
  DeviceType2["IOS"] = "ios";
  return DeviceType2;
})(DeviceType || {});
var PushChannelType = /* @__PURE__ */ ((PushChannelType2) => {
  PushChannelType2["UNI_APP"] = "unipush";
  PushChannelType2["SOCKET"] = "socket";
  PushChannelType2["GETUI"] = "getui";
  return PushChannelType2;
})(PushChannelType || {});
const TwStateLvType = {
  primary: "primary",
  secondary: "secondary",
  sub: "sub"
};
const TwCmdOperatorConstrainType = {
  single: "single",
  multiple: "multiple"
};
const TwCmdType = {
  system: "system",
  state: "state"
};
var BfLoginDeviceType = /* @__PURE__ */ ((BfLoginDeviceType2) => {
  BfLoginDeviceType2["web"] = "web";
  BfLoginDeviceType2["desktop"] = "desktop";
  BfLoginDeviceType2["pad"] = "pad";
  BfLoginDeviceType2["ios"] = "ios";
  BfLoginDeviceType2["android"] = "android";
  BfLoginDeviceType2["uniapp"] = "uniapp";
  BfLoginDeviceType2["api"] = "api";
  return BfLoginDeviceType2;
})(BfLoginDeviceType || {});
const BoCommandTypes = {
  system: "system",
  state: "state"
};
var BoCommandAlertType = /* @__PURE__ */ ((BoCommandAlertType2) => {
  BoCommandAlertType2["danger"] = "danger";
  BoCommandAlertType2["dangerPrompt"] = "danger-prompt";
  BoCommandAlertType2["primary"] = "primary";
  BoCommandAlertType2["normal"] = "normal";
  return BoCommandAlertType2;
})(BoCommandAlertType || {});
var freeGlobal = typeof global == "object" && global && global.Object === Object && global;
const freeGlobal$1 = freeGlobal;
var freeSelf = typeof self == "object" && self && self.Object === Object && self;
var root = freeGlobal$1 || freeSelf || Function("return this")();
const root$1 = root;
var Symbol$1 = root$1.Symbol;
const Symbol$2 = Symbol$1;
var objectProto$g = Object.prototype;
var hasOwnProperty$d = objectProto$g.hasOwnProperty;
var nativeObjectToString$1 = objectProto$g.toString;
var symToStringTag$1 = Symbol$2 ? Symbol$2.toStringTag : void 0;
function getRawTag(value) {
  var isOwn = hasOwnProperty$d.call(value, symToStringTag$1), tag = value[symToStringTag$1];
  try {
    value[symToStringTag$1] = void 0;
    var unmasked = true;
  } catch (e) {
  }
  var result = nativeObjectToString$1.call(value);
  if (unmasked) {
    if (isOwn) {
      value[symToStringTag$1] = tag;
    } else {
      delete value[symToStringTag$1];
    }
  }
  return result;
}
var objectProto$f = Object.prototype;
var nativeObjectToString = objectProto$f.toString;
function objectToString(value) {
  return nativeObjectToString.call(value);
}
var nullTag = "[object Null]", undefinedTag = "[object Undefined]";
var symToStringTag = Symbol$2 ? Symbol$2.toStringTag : void 0;
function baseGetTag(value) {
  if (value == null) {
    return value === void 0 ? undefinedTag : nullTag;
  }
  return symToStringTag && symToStringTag in Object(value) ? getRawTag(value) : objectToString(value);
}
function isObjectLike(value) {
  return value != null && typeof value == "object";
}
var symbolTag$3 = "[object Symbol]";
function isSymbol(value) {
  return typeof value == "symbol" || isObjectLike(value) && baseGetTag(value) == symbolTag$3;
}
function arrayMap$1(array, iteratee) {
  var index = -1, length = array == null ? 0 : array.length, result = Array(length);
  while (++index < length) {
    result[index] = iteratee(array[index], index, array);
  }
  return result;
}
var isArray = Array.isArray;
const isArray$1 = isArray;
var INFINITY$1 = 1 / 0;
var symbolProto$2 = Symbol$2 ? Symbol$2.prototype : void 0, symbolToString = symbolProto$2 ? symbolProto$2.toString : void 0;
function baseToString(value) {
  if (typeof value == "string") {
    return value;
  }
  if (isArray$1(value)) {
    return arrayMap$1(value, baseToString) + "";
  }
  if (isSymbol(value)) {
    return symbolToString ? symbolToString.call(value) : "";
  }
  var result = value + "";
  return result == "0" && 1 / value == -INFINITY$1 ? "-0" : result;
}
function isObject(value) {
  var type = typeof value;
  return value != null && (type == "object" || type == "function");
}
function identity(value) {
  return value;
}
var asyncTag = "[object AsyncFunction]", funcTag$2 = "[object Function]", genTag$1 = "[object GeneratorFunction]", proxyTag = "[object Proxy]";
function isFunction(value) {
  if (!isObject(value)) {
    return false;
  }
  var tag = baseGetTag(value);
  return tag == funcTag$2 || tag == genTag$1 || tag == asyncTag || tag == proxyTag;
}
var coreJsData = root$1["__core-js_shared__"];
const coreJsData$1 = coreJsData;
var maskSrcKey = function() {
  var uid2 = /[^.]+$/.exec(coreJsData$1 && coreJsData$1.keys && coreJsData$1.keys.IE_PROTO || "");
  return uid2 ? "Symbol(src)_1." + uid2 : "";
}();
function isMasked(func) {
  return !!maskSrcKey && maskSrcKey in func;
}
var funcProto$2 = Function.prototype;
var funcToString$2 = funcProto$2.toString;
function toSource(func) {
  if (func != null) {
    try {
      return funcToString$2.call(func);
    } catch (e) {
    }
    try {
      return func + "";
    } catch (e) {
    }
  }
  return "";
}
var reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
var reIsHostCtor = /^\[object .+?Constructor\]$/;
var funcProto$1 = Function.prototype, objectProto$e = Object.prototype;
var funcToString$1 = funcProto$1.toString;
var hasOwnProperty$c = objectProto$e.hasOwnProperty;
var reIsNative = RegExp(
  "^" + funcToString$1.call(hasOwnProperty$c).replace(reRegExpChar, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function baseIsNative(value) {
  if (!isObject(value) || isMasked(value)) {
    return false;
  }
  var pattern = isFunction(value) ? reIsNative : reIsHostCtor;
  return pattern.test(toSource(value));
}
function getValue(object, key) {
  return object == null ? void 0 : object[key];
}
function getNative(object, key) {
  var value = getValue(object, key);
  return baseIsNative(value) ? value : void 0;
}
var WeakMap = getNative(root$1, "WeakMap");
const WeakMap$1 = WeakMap;
var objectCreate = Object.create;
var baseCreate = function() {
  function object() {
  }
  return function(proto) {
    if (!isObject(proto)) {
      return {};
    }
    if (objectCreate) {
      return objectCreate(proto);
    }
    object.prototype = proto;
    var result = new object();
    object.prototype = void 0;
    return result;
  };
}();
const baseCreate$1 = baseCreate;
function apply(func, thisArg, args) {
  switch (args.length) {
    case 0:
      return func.call(thisArg);
    case 1:
      return func.call(thisArg, args[0]);
    case 2:
      return func.call(thisArg, args[0], args[1]);
    case 3:
      return func.call(thisArg, args[0], args[1], args[2]);
  }
  return func.apply(thisArg, args);
}
function copyArray(source, array) {
  var index = -1, length = source.length;
  array || (array = Array(length));
  while (++index < length) {
    array[index] = source[index];
  }
  return array;
}
var HOT_COUNT = 800, HOT_SPAN = 16;
var nativeNow = Date.now;
function shortOut(func) {
  var count = 0, lastCalled = 0;
  return function() {
    var stamp = nativeNow(), remaining = HOT_SPAN - (stamp - lastCalled);
    lastCalled = stamp;
    if (remaining > 0) {
      if (++count >= HOT_COUNT) {
        return arguments[0];
      }
    } else {
      count = 0;
    }
    return func.apply(void 0, arguments);
  };
}
function constant(value) {
  return function() {
    return value;
  };
}
var defineProperty = function() {
  try {
    var func = getNative(Object, "defineProperty");
    func({}, "", {});
    return func;
  } catch (e) {
  }
}();
const defineProperty$1 = defineProperty;
var baseSetToString = !defineProperty$1 ? identity : function(func, string) {
  return defineProperty$1(func, "toString", {
    "configurable": true,
    "enumerable": false,
    "value": constant(string),
    "writable": true
  });
};
const baseSetToString$1 = baseSetToString;
var setToString = shortOut(baseSetToString$1);
const setToString$1 = setToString;
function arrayEach(array, iteratee) {
  var index = -1, length = array == null ? 0 : array.length;
  while (++index < length) {
    if (iteratee(array[index], index, array) === false) {
      break;
    }
  }
  return array;
}
var MAX_SAFE_INTEGER$1 = 9007199254740991;
var reIsUint = /^(?:0|[1-9]\d*)$/;
function isIndex(value, length) {
  var type = typeof value;
  length = length == null ? MAX_SAFE_INTEGER$1 : length;
  return !!length && (type == "number" || type != "symbol" && reIsUint.test(value)) && (value > -1 && value % 1 == 0 && value < length);
}
function baseAssignValue(object, key, value) {
  if (key == "__proto__" && defineProperty$1) {
    defineProperty$1(object, key, {
      "configurable": true,
      "enumerable": true,
      "value": value,
      "writable": true
    });
  } else {
    object[key] = value;
  }
}
function eq(value, other) {
  return value === other || value !== value && other !== other;
}
var objectProto$d = Object.prototype;
var hasOwnProperty$b = objectProto$d.hasOwnProperty;
function assignValue(object, key, value) {
  var objValue = object[key];
  if (!(hasOwnProperty$b.call(object, key) && eq(objValue, value)) || value === void 0 && !(key in object)) {
    baseAssignValue(object, key, value);
  }
}
function copyObject(source, props, object, customizer) {
  var isNew = !object;
  object || (object = {});
  var index = -1, length = props.length;
  while (++index < length) {
    var key = props[index];
    var newValue = customizer ? customizer(object[key], source[key], key, object, source) : void 0;
    if (newValue === void 0) {
      newValue = source[key];
    }
    if (isNew) {
      baseAssignValue(object, key, newValue);
    } else {
      assignValue(object, key, newValue);
    }
  }
  return object;
}
var nativeMax = Math.max;
function overRest(func, start, transform) {
  start = nativeMax(start === void 0 ? func.length - 1 : start, 0);
  return function() {
    var args = arguments, index = -1, length = nativeMax(args.length - start, 0), array = Array(length);
    while (++index < length) {
      array[index] = args[start + index];
    }
    index = -1;
    var otherArgs = Array(start + 1);
    while (++index < start) {
      otherArgs[index] = args[index];
    }
    otherArgs[start] = transform(array);
    return apply(func, this, otherArgs);
  };
}
function baseRest(func, start) {
  return setToString$1(overRest(func, start, identity), func + "");
}
var MAX_SAFE_INTEGER = 9007199254740991;
function isLength(value) {
  return typeof value == "number" && value > -1 && value % 1 == 0 && value <= MAX_SAFE_INTEGER;
}
function isArrayLike(value) {
  return value != null && isLength(value.length) && !isFunction(value);
}
function isIterateeCall(value, index, object) {
  if (!isObject(object)) {
    return false;
  }
  var type = typeof index;
  if (type == "number" ? isArrayLike(object) && isIndex(index, object.length) : type == "string" && index in object) {
    return eq(object[index], value);
  }
  return false;
}
function createAssigner(assigner) {
  return baseRest(function(object, sources) {
    var index = -1, length = sources.length, customizer = length > 1 ? sources[length - 1] : void 0, guard = length > 2 ? sources[2] : void 0;
    customizer = assigner.length > 3 && typeof customizer == "function" ? (length--, customizer) : void 0;
    if (guard && isIterateeCall(sources[0], sources[1], guard)) {
      customizer = length < 3 ? void 0 : customizer;
      length = 1;
    }
    object = Object(object);
    while (++index < length) {
      var source = sources[index];
      if (source) {
        assigner(object, source, index, customizer);
      }
    }
    return object;
  });
}
var objectProto$c = Object.prototype;
function isPrototype(value) {
  var Ctor = value && value.constructor, proto = typeof Ctor == "function" && Ctor.prototype || objectProto$c;
  return value === proto;
}
function baseTimes(n2, iteratee) {
  var index = -1, result = Array(n2);
  while (++index < n2) {
    result[index] = iteratee(index);
  }
  return result;
}
var argsTag$3 = "[object Arguments]";
function baseIsArguments(value) {
  return isObjectLike(value) && baseGetTag(value) == argsTag$3;
}
var objectProto$b = Object.prototype;
var hasOwnProperty$a = objectProto$b.hasOwnProperty;
var propertyIsEnumerable$1 = objectProto$b.propertyIsEnumerable;
var isArguments = baseIsArguments(function() {
  return arguments;
}()) ? baseIsArguments : function(value) {
  return isObjectLike(value) && hasOwnProperty$a.call(value, "callee") && !propertyIsEnumerable$1.call(value, "callee");
};
const isArguments$1 = isArguments;
function stubFalse() {
  return false;
}
var freeExports$2 = typeof exports == "object" && exports && !exports.nodeType && exports;
var freeModule$2 = freeExports$2 && typeof module == "object" && module && !module.nodeType && module;
var moduleExports$2 = freeModule$2 && freeModule$2.exports === freeExports$2;
var Buffer$1 = moduleExports$2 ? root$1.Buffer : void 0;
var nativeIsBuffer = Buffer$1 ? Buffer$1.isBuffer : void 0;
var isBuffer = nativeIsBuffer || stubFalse;
const isBuffer$1 = isBuffer;
var argsTag$2 = "[object Arguments]", arrayTag$2 = "[object Array]", boolTag$3 = "[object Boolean]", dateTag$3 = "[object Date]", errorTag$2 = "[object Error]", funcTag$1 = "[object Function]", mapTag$6 = "[object Map]", numberTag$3 = "[object Number]", objectTag$4 = "[object Object]", regexpTag$3 = "[object RegExp]", setTag$6 = "[object Set]", stringTag$3 = "[object String]", weakMapTag$2 = "[object WeakMap]";
var arrayBufferTag$3 = "[object ArrayBuffer]", dataViewTag$4 = "[object DataView]", float32Tag$2 = "[object Float32Array]", float64Tag$2 = "[object Float64Array]", int8Tag$2 = "[object Int8Array]", int16Tag$2 = "[object Int16Array]", int32Tag$2 = "[object Int32Array]", uint8Tag$2 = "[object Uint8Array]", uint8ClampedTag$2 = "[object Uint8ClampedArray]", uint16Tag$2 = "[object Uint16Array]", uint32Tag$2 = "[object Uint32Array]";
var typedArrayTags = {};
typedArrayTags[float32Tag$2] = typedArrayTags[float64Tag$2] = typedArrayTags[int8Tag$2] = typedArrayTags[int16Tag$2] = typedArrayTags[int32Tag$2] = typedArrayTags[uint8Tag$2] = typedArrayTags[uint8ClampedTag$2] = typedArrayTags[uint16Tag$2] = typedArrayTags[uint32Tag$2] = true;
typedArrayTags[argsTag$2] = typedArrayTags[arrayTag$2] = typedArrayTags[arrayBufferTag$3] = typedArrayTags[boolTag$3] = typedArrayTags[dataViewTag$4] = typedArrayTags[dateTag$3] = typedArrayTags[errorTag$2] = typedArrayTags[funcTag$1] = typedArrayTags[mapTag$6] = typedArrayTags[numberTag$3] = typedArrayTags[objectTag$4] = typedArrayTags[regexpTag$3] = typedArrayTags[setTag$6] = typedArrayTags[stringTag$3] = typedArrayTags[weakMapTag$2] = false;
function baseIsTypedArray(value) {
  return isObjectLike(value) && isLength(value.length) && !!typedArrayTags[baseGetTag(value)];
}
function baseUnary(func) {
  return function(value) {
    return func(value);
  };
}
var freeExports$1 = typeof exports == "object" && exports && !exports.nodeType && exports;
var freeModule$1 = freeExports$1 && typeof module == "object" && module && !module.nodeType && module;
var moduleExports$1 = freeModule$1 && freeModule$1.exports === freeExports$1;
var freeProcess = moduleExports$1 && freeGlobal$1.process;
var nodeUtil = function() {
  try {
    var types = freeModule$1 && freeModule$1.require && freeModule$1.require("util").types;
    if (types) {
      return types;
    }
    return freeProcess && freeProcess.binding && freeProcess.binding("util");
  } catch (e) {
  }
}();
const nodeUtil$1 = nodeUtil;
var nodeIsTypedArray = nodeUtil$1 && nodeUtil$1.isTypedArray;
var isTypedArray = nodeIsTypedArray ? baseUnary(nodeIsTypedArray) : baseIsTypedArray;
const isTypedArray$1 = isTypedArray;
var objectProto$a = Object.prototype;
var hasOwnProperty$9 = objectProto$a.hasOwnProperty;
function arrayLikeKeys(value, inherited) {
  var isArr = isArray$1(value), isArg = !isArr && isArguments$1(value), isBuff = !isArr && !isArg && isBuffer$1(value), isType = !isArr && !isArg && !isBuff && isTypedArray$1(value), skipIndexes = isArr || isArg || isBuff || isType, result = skipIndexes ? baseTimes(value.length, String) : [], length = result.length;
  for (var key in value) {
    if ((inherited || hasOwnProperty$9.call(value, key)) && !(skipIndexes && (key == "length" || isBuff && (key == "offset" || key == "parent") || isType && (key == "buffer" || key == "byteLength" || key == "byteOffset") || isIndex(key, length)))) {
      result.push(key);
    }
  }
  return result;
}
function overArg(func, transform) {
  return function(arg) {
    return func(transform(arg));
  };
}
var nativeKeys = overArg(Object.keys, Object);
const nativeKeys$1 = nativeKeys;
var objectProto$9 = Object.prototype;
var hasOwnProperty$8 = objectProto$9.hasOwnProperty;
function baseKeys(object) {
  if (!isPrototype(object)) {
    return nativeKeys$1(object);
  }
  var result = [];
  for (var key in Object(object)) {
    if (hasOwnProperty$8.call(object, key) && key != "constructor") {
      result.push(key);
    }
  }
  return result;
}
function keys(object) {
  return isArrayLike(object) ? arrayLikeKeys(object) : baseKeys(object);
}
function nativeKeysIn(object) {
  var result = [];
  if (object != null) {
    for (var key in Object(object)) {
      result.push(key);
    }
  }
  return result;
}
var objectProto$8 = Object.prototype;
var hasOwnProperty$7 = objectProto$8.hasOwnProperty;
function baseKeysIn(object) {
  if (!isObject(object)) {
    return nativeKeysIn(object);
  }
  var isProto = isPrototype(object), result = [];
  for (var key in object) {
    if (!(key == "constructor" && (isProto || !hasOwnProperty$7.call(object, key)))) {
      result.push(key);
    }
  }
  return result;
}
function keysIn(object) {
  return isArrayLike(object) ? arrayLikeKeys(object, true) : baseKeysIn(object);
}
var reIsDeepProp = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/, reIsPlainProp = /^\w*$/;
function isKey(value, object) {
  if (isArray$1(value)) {
    return false;
  }
  var type = typeof value;
  if (type == "number" || type == "symbol" || type == "boolean" || value == null || isSymbol(value)) {
    return true;
  }
  return reIsPlainProp.test(value) || !reIsDeepProp.test(value) || object != null && value in Object(object);
}
var nativeCreate = getNative(Object, "create");
const nativeCreate$1 = nativeCreate;
function hashClear() {
  this.__data__ = nativeCreate$1 ? nativeCreate$1(null) : {};
  this.size = 0;
}
function hashDelete(key) {
  var result = this.has(key) && delete this.__data__[key];
  this.size -= result ? 1 : 0;
  return result;
}
var HASH_UNDEFINED$2 = "__lodash_hash_undefined__";
var objectProto$7 = Object.prototype;
var hasOwnProperty$6 = objectProto$7.hasOwnProperty;
function hashGet(key) {
  var data = this.__data__;
  if (nativeCreate$1) {
    var result = data[key];
    return result === HASH_UNDEFINED$2 ? void 0 : result;
  }
  return hasOwnProperty$6.call(data, key) ? data[key] : void 0;
}
var objectProto$6 = Object.prototype;
var hasOwnProperty$5 = objectProto$6.hasOwnProperty;
function hashHas(key) {
  var data = this.__data__;
  return nativeCreate$1 ? data[key] !== void 0 : hasOwnProperty$5.call(data, key);
}
var HASH_UNDEFINED$1 = "__lodash_hash_undefined__";
function hashSet(key, value) {
  var data = this.__data__;
  this.size += this.has(key) ? 0 : 1;
  data[key] = nativeCreate$1 && value === void 0 ? HASH_UNDEFINED$1 : value;
  return this;
}
function Hash(entries) {
  var index = -1, length = entries == null ? 0 : entries.length;
  this.clear();
  while (++index < length) {
    var entry = entries[index];
    this.set(entry[0], entry[1]);
  }
}
Hash.prototype.clear = hashClear;
Hash.prototype["delete"] = hashDelete;
Hash.prototype.get = hashGet;
Hash.prototype.has = hashHas;
Hash.prototype.set = hashSet;
function listCacheClear() {
  this.__data__ = [];
  this.size = 0;
}
function assocIndexOf(array, key) {
  var length = array.length;
  while (length--) {
    if (eq(array[length][0], key)) {
      return length;
    }
  }
  return -1;
}
var arrayProto = Array.prototype;
var splice = arrayProto.splice;
function listCacheDelete(key) {
  var data = this.__data__, index = assocIndexOf(data, key);
  if (index < 0) {
    return false;
  }
  var lastIndex = data.length - 1;
  if (index == lastIndex) {
    data.pop();
  } else {
    splice.call(data, index, 1);
  }
  --this.size;
  return true;
}
function listCacheGet(key) {
  var data = this.__data__, index = assocIndexOf(data, key);
  return index < 0 ? void 0 : data[index][1];
}
function listCacheHas(key) {
  return assocIndexOf(this.__data__, key) > -1;
}
function listCacheSet(key, value) {
  var data = this.__data__, index = assocIndexOf(data, key);
  if (index < 0) {
    ++this.size;
    data.push([key, value]);
  } else {
    data[index][1] = value;
  }
  return this;
}
function ListCache(entries) {
  var index = -1, length = entries == null ? 0 : entries.length;
  this.clear();
  while (++index < length) {
    var entry = entries[index];
    this.set(entry[0], entry[1]);
  }
}
ListCache.prototype.clear = listCacheClear;
ListCache.prototype["delete"] = listCacheDelete;
ListCache.prototype.get = listCacheGet;
ListCache.prototype.has = listCacheHas;
ListCache.prototype.set = listCacheSet;
var Map$1 = getNative(root$1, "Map");
const Map$2 = Map$1;
function mapCacheClear() {
  this.size = 0;
  this.__data__ = {
    "hash": new Hash(),
    "map": new (Map$2 || ListCache)(),
    "string": new Hash()
  };
}
function isKeyable(value) {
  var type = typeof value;
  return type == "string" || type == "number" || type == "symbol" || type == "boolean" ? value !== "__proto__" : value === null;
}
function getMapData(map, key) {
  var data = map.__data__;
  return isKeyable(key) ? data[typeof key == "string" ? "string" : "hash"] : data.map;
}
function mapCacheDelete(key) {
  var result = getMapData(this, key)["delete"](key);
  this.size -= result ? 1 : 0;
  return result;
}
function mapCacheGet(key) {
  return getMapData(this, key).get(key);
}
function mapCacheHas(key) {
  return getMapData(this, key).has(key);
}
function mapCacheSet(key, value) {
  var data = getMapData(this, key), size = data.size;
  data.set(key, value);
  this.size += data.size == size ? 0 : 1;
  return this;
}
function MapCache(entries) {
  var index = -1, length = entries == null ? 0 : entries.length;
  this.clear();
  while (++index < length) {
    var entry = entries[index];
    this.set(entry[0], entry[1]);
  }
}
MapCache.prototype.clear = mapCacheClear;
MapCache.prototype["delete"] = mapCacheDelete;
MapCache.prototype.get = mapCacheGet;
MapCache.prototype.has = mapCacheHas;
MapCache.prototype.set = mapCacheSet;
var FUNC_ERROR_TEXT = "Expected a function";
function memoize(func, resolver) {
  if (typeof func != "function" || resolver != null && typeof resolver != "function") {
    throw new TypeError(FUNC_ERROR_TEXT);
  }
  var memoized = function() {
    var args = arguments, key = resolver ? resolver.apply(this, args) : args[0], cache = memoized.cache;
    if (cache.has(key)) {
      return cache.get(key);
    }
    var result = func.apply(this, args);
    memoized.cache = cache.set(key, result) || cache;
    return result;
  };
  memoized.cache = new (memoize.Cache || MapCache)();
  return memoized;
}
memoize.Cache = MapCache;
var MAX_MEMOIZE_SIZE = 500;
function memoizeCapped(func) {
  var result = memoize(func, function(key) {
    if (cache.size === MAX_MEMOIZE_SIZE) {
      cache.clear();
    }
    return key;
  });
  var cache = result.cache;
  return result;
}
var rePropName = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g;
var reEscapeChar = /\\(\\)?/g;
var stringToPath = memoizeCapped(function(string) {
  var result = [];
  if (string.charCodeAt(0) === 46) {
    result.push("");
  }
  string.replace(rePropName, function(match, number, quote, subString) {
    result.push(quote ? subString.replace(reEscapeChar, "$1") : number || match);
  });
  return result;
});
const stringToPath$1 = stringToPath;
function toString(value) {
  return value == null ? "" : baseToString(value);
}
function castPath(value, object) {
  if (isArray$1(value)) {
    return value;
  }
  return isKey(value, object) ? [value] : stringToPath$1(toString(value));
}
var INFINITY = 1 / 0;
function toKey(value) {
  if (typeof value == "string" || isSymbol(value)) {
    return value;
  }
  var result = value + "";
  return result == "0" && 1 / value == -INFINITY ? "-0" : result;
}
function baseGet(object, path) {
  path = castPath(path, object);
  var index = 0, length = path.length;
  while (object != null && index < length) {
    object = object[toKey(path[index++])];
  }
  return index && index == length ? object : void 0;
}
function get(object, path, defaultValue) {
  var result = object == null ? void 0 : baseGet(object, path);
  return result === void 0 ? defaultValue : result;
}
function arrayPush(array, values) {
  var index = -1, length = values.length, offset = array.length;
  while (++index < length) {
    array[offset + index] = values[index];
  }
  return array;
}
var spreadableSymbol = Symbol$2 ? Symbol$2.isConcatSpreadable : void 0;
function isFlattenable(value) {
  return isArray$1(value) || isArguments$1(value) || !!(spreadableSymbol && value && value[spreadableSymbol]);
}
function baseFlatten(array, depth, predicate, isStrict, result) {
  var index = -1, length = array.length;
  predicate || (predicate = isFlattenable);
  result || (result = []);
  while (++index < length) {
    var value = array[index];
    if (depth > 0 && predicate(value)) {
      if (depth > 1) {
        baseFlatten(value, depth - 1, predicate, isStrict, result);
      } else {
        arrayPush(result, value);
      }
    } else if (!isStrict) {
      result[result.length] = value;
    }
  }
  return result;
}
function flatten(array) {
  var length = array == null ? 0 : array.length;
  return length ? baseFlatten(array, 1) : [];
}
function flatRest(func) {
  return setToString$1(overRest(func, void 0, flatten), func + "");
}
var getPrototype = overArg(Object.getPrototypeOf, Object);
const getPrototype$1 = getPrototype;
var objectTag$3 = "[object Object]";
var funcProto = Function.prototype, objectProto$5 = Object.prototype;
var funcToString = funcProto.toString;
var hasOwnProperty$4 = objectProto$5.hasOwnProperty;
var objectCtorString = funcToString.call(Object);
function isPlainObject(value) {
  if (!isObjectLike(value) || baseGetTag(value) != objectTag$3) {
    return false;
  }
  var proto = getPrototype$1(value);
  if (proto === null) {
    return true;
  }
  var Ctor = hasOwnProperty$4.call(proto, "constructor") && proto.constructor;
  return typeof Ctor == "function" && Ctor instanceof Ctor && funcToString.call(Ctor) == objectCtorString;
}
function baseSlice(array, start, end) {
  var index = -1, length = array.length;
  if (start < 0) {
    start = -start > length ? 0 : length + start;
  }
  end = end > length ? length : end;
  if (end < 0) {
    end += length;
  }
  length = start > end ? 0 : end - start >>> 0;
  start >>>= 0;
  var result = Array(length);
  while (++index < length) {
    result[index] = array[index + start];
  }
  return result;
}
function stackClear() {
  this.__data__ = new ListCache();
  this.size = 0;
}
function stackDelete(key) {
  var data = this.__data__, result = data["delete"](key);
  this.size = data.size;
  return result;
}
function stackGet(key) {
  return this.__data__.get(key);
}
function stackHas(key) {
  return this.__data__.has(key);
}
var LARGE_ARRAY_SIZE = 200;
function stackSet(key, value) {
  var data = this.__data__;
  if (data instanceof ListCache) {
    var pairs = data.__data__;
    if (!Map$2 || pairs.length < LARGE_ARRAY_SIZE - 1) {
      pairs.push([key, value]);
      this.size = ++data.size;
      return this;
    }
    data = this.__data__ = new MapCache(pairs);
  }
  data.set(key, value);
  this.size = data.size;
  return this;
}
function Stack(entries) {
  var data = this.__data__ = new ListCache(entries);
  this.size = data.size;
}
Stack.prototype.clear = stackClear;
Stack.prototype["delete"] = stackDelete;
Stack.prototype.get = stackGet;
Stack.prototype.has = stackHas;
Stack.prototype.set = stackSet;
function baseAssign(object, source) {
  return object && copyObject(source, keys(source), object);
}
function baseAssignIn(object, source) {
  return object && copyObject(source, keysIn(source), object);
}
var freeExports = typeof exports == "object" && exports && !exports.nodeType && exports;
var freeModule = freeExports && typeof module == "object" && module && !module.nodeType && module;
var moduleExports = freeModule && freeModule.exports === freeExports;
var Buffer2 = moduleExports ? root$1.Buffer : void 0, allocUnsafe = Buffer2 ? Buffer2.allocUnsafe : void 0;
function cloneBuffer(buffer, isDeep) {
  if (isDeep) {
    return buffer.slice();
  }
  var length = buffer.length, result = allocUnsafe ? allocUnsafe(length) : new buffer.constructor(length);
  buffer.copy(result);
  return result;
}
function arrayFilter(array, predicate) {
  var index = -1, length = array == null ? 0 : array.length, resIndex = 0, result = [];
  while (++index < length) {
    var value = array[index];
    if (predicate(value, index, array)) {
      result[resIndex++] = value;
    }
  }
  return result;
}
function stubArray() {
  return [];
}
var objectProto$4 = Object.prototype;
var propertyIsEnumerable = objectProto$4.propertyIsEnumerable;
var nativeGetSymbols$1 = Object.getOwnPropertySymbols;
var getSymbols = !nativeGetSymbols$1 ? stubArray : function(object) {
  if (object == null) {
    return [];
  }
  object = Object(object);
  return arrayFilter(nativeGetSymbols$1(object), function(symbol) {
    return propertyIsEnumerable.call(object, symbol);
  });
};
const getSymbols$1 = getSymbols;
function copySymbols(source, object) {
  return copyObject(source, getSymbols$1(source), object);
}
var nativeGetSymbols = Object.getOwnPropertySymbols;
var getSymbolsIn = !nativeGetSymbols ? stubArray : function(object) {
  var result = [];
  while (object) {
    arrayPush(result, getSymbols$1(object));
    object = getPrototype$1(object);
  }
  return result;
};
const getSymbolsIn$1 = getSymbolsIn;
function copySymbolsIn(source, object) {
  return copyObject(source, getSymbolsIn$1(source), object);
}
function baseGetAllKeys(object, keysFunc, symbolsFunc) {
  var result = keysFunc(object);
  return isArray$1(object) ? result : arrayPush(result, symbolsFunc(object));
}
function getAllKeys(object) {
  return baseGetAllKeys(object, keys, getSymbols$1);
}
function getAllKeysIn(object) {
  return baseGetAllKeys(object, keysIn, getSymbolsIn$1);
}
var DataView = getNative(root$1, "DataView");
const DataView$1 = DataView;
var Promise$1 = getNative(root$1, "Promise");
const Promise$2 = Promise$1;
var Set$1 = getNative(root$1, "Set");
const Set$2 = Set$1;
var mapTag$5 = "[object Map]", objectTag$2 = "[object Object]", promiseTag = "[object Promise]", setTag$5 = "[object Set]", weakMapTag$1 = "[object WeakMap]";
var dataViewTag$3 = "[object DataView]";
var dataViewCtorString = toSource(DataView$1), mapCtorString = toSource(Map$2), promiseCtorString = toSource(Promise$2), setCtorString = toSource(Set$2), weakMapCtorString = toSource(WeakMap$1);
var getTag = baseGetTag;
if (DataView$1 && getTag(new DataView$1(new ArrayBuffer(1))) != dataViewTag$3 || Map$2 && getTag(new Map$2()) != mapTag$5 || Promise$2 && getTag(Promise$2.resolve()) != promiseTag || Set$2 && getTag(new Set$2()) != setTag$5 || WeakMap$1 && getTag(new WeakMap$1()) != weakMapTag$1) {
  getTag = function(value) {
    var result = baseGetTag(value), Ctor = result == objectTag$2 ? value.constructor : void 0, ctorString = Ctor ? toSource(Ctor) : "";
    if (ctorString) {
      switch (ctorString) {
        case dataViewCtorString:
          return dataViewTag$3;
        case mapCtorString:
          return mapTag$5;
        case promiseCtorString:
          return promiseTag;
        case setCtorString:
          return setTag$5;
        case weakMapCtorString:
          return weakMapTag$1;
      }
    }
    return result;
  };
}
const getTag$1 = getTag;
var objectProto$3 = Object.prototype;
var hasOwnProperty$3 = objectProto$3.hasOwnProperty;
function initCloneArray(array) {
  var length = array.length, result = new array.constructor(length);
  if (length && typeof array[0] == "string" && hasOwnProperty$3.call(array, "index")) {
    result.index = array.index;
    result.input = array.input;
  }
  return result;
}
var Uint8Array2 = root$1.Uint8Array;
const Uint8Array$1 = Uint8Array2;
function cloneArrayBuffer(arrayBuffer) {
  var result = new arrayBuffer.constructor(arrayBuffer.byteLength);
  new Uint8Array$1(result).set(new Uint8Array$1(arrayBuffer));
  return result;
}
function cloneDataView(dataView, isDeep) {
  var buffer = isDeep ? cloneArrayBuffer(dataView.buffer) : dataView.buffer;
  return new dataView.constructor(buffer, dataView.byteOffset, dataView.byteLength);
}
var reFlags = /\w*$/;
function cloneRegExp(regexp) {
  var result = new regexp.constructor(regexp.source, reFlags.exec(regexp));
  result.lastIndex = regexp.lastIndex;
  return result;
}
var symbolProto$1 = Symbol$2 ? Symbol$2.prototype : void 0, symbolValueOf$1 = symbolProto$1 ? symbolProto$1.valueOf : void 0;
function cloneSymbol(symbol) {
  return symbolValueOf$1 ? Object(symbolValueOf$1.call(symbol)) : {};
}
function cloneTypedArray(typedArray, isDeep) {
  var buffer = isDeep ? cloneArrayBuffer(typedArray.buffer) : typedArray.buffer;
  return new typedArray.constructor(buffer, typedArray.byteOffset, typedArray.length);
}
var boolTag$2 = "[object Boolean]", dateTag$2 = "[object Date]", mapTag$4 = "[object Map]", numberTag$2 = "[object Number]", regexpTag$2 = "[object RegExp]", setTag$4 = "[object Set]", stringTag$2 = "[object String]", symbolTag$2 = "[object Symbol]";
var arrayBufferTag$2 = "[object ArrayBuffer]", dataViewTag$2 = "[object DataView]", float32Tag$1 = "[object Float32Array]", float64Tag$1 = "[object Float64Array]", int8Tag$1 = "[object Int8Array]", int16Tag$1 = "[object Int16Array]", int32Tag$1 = "[object Int32Array]", uint8Tag$1 = "[object Uint8Array]", uint8ClampedTag$1 = "[object Uint8ClampedArray]", uint16Tag$1 = "[object Uint16Array]", uint32Tag$1 = "[object Uint32Array]";
function initCloneByTag(object, tag, isDeep) {
  var Ctor = object.constructor;
  switch (tag) {
    case arrayBufferTag$2:
      return cloneArrayBuffer(object);
    case boolTag$2:
    case dateTag$2:
      return new Ctor(+object);
    case dataViewTag$2:
      return cloneDataView(object, isDeep);
    case float32Tag$1:
    case float64Tag$1:
    case int8Tag$1:
    case int16Tag$1:
    case int32Tag$1:
    case uint8Tag$1:
    case uint8ClampedTag$1:
    case uint16Tag$1:
    case uint32Tag$1:
      return cloneTypedArray(object, isDeep);
    case mapTag$4:
      return new Ctor();
    case numberTag$2:
    case stringTag$2:
      return new Ctor(object);
    case regexpTag$2:
      return cloneRegExp(object);
    case setTag$4:
      return new Ctor();
    case symbolTag$2:
      return cloneSymbol(object);
  }
}
function initCloneObject(object) {
  return typeof object.constructor == "function" && !isPrototype(object) ? baseCreate$1(getPrototype$1(object)) : {};
}
var mapTag$3 = "[object Map]";
function baseIsMap(value) {
  return isObjectLike(value) && getTag$1(value) == mapTag$3;
}
var nodeIsMap = nodeUtil$1 && nodeUtil$1.isMap;
var isMap = nodeIsMap ? baseUnary(nodeIsMap) : baseIsMap;
const isMap$1 = isMap;
var setTag$3 = "[object Set]";
function baseIsSet(value) {
  return isObjectLike(value) && getTag$1(value) == setTag$3;
}
var nodeIsSet = nodeUtil$1 && nodeUtil$1.isSet;
var isSet = nodeIsSet ? baseUnary(nodeIsSet) : baseIsSet;
const isSet$1 = isSet;
var CLONE_DEEP_FLAG$2 = 1, CLONE_FLAT_FLAG$1 = 2, CLONE_SYMBOLS_FLAG$3 = 4;
var argsTag$1 = "[object Arguments]", arrayTag$1 = "[object Array]", boolTag$1 = "[object Boolean]", dateTag$1 = "[object Date]", errorTag$1 = "[object Error]", funcTag = "[object Function]", genTag = "[object GeneratorFunction]", mapTag$2 = "[object Map]", numberTag$1 = "[object Number]", objectTag$1 = "[object Object]", regexpTag$1 = "[object RegExp]", setTag$2 = "[object Set]", stringTag$1 = "[object String]", symbolTag$1 = "[object Symbol]", weakMapTag = "[object WeakMap]";
var arrayBufferTag$1 = "[object ArrayBuffer]", dataViewTag$1 = "[object DataView]", float32Tag = "[object Float32Array]", float64Tag = "[object Float64Array]", int8Tag = "[object Int8Array]", int16Tag = "[object Int16Array]", int32Tag = "[object Int32Array]", uint8Tag = "[object Uint8Array]", uint8ClampedTag = "[object Uint8ClampedArray]", uint16Tag = "[object Uint16Array]", uint32Tag = "[object Uint32Array]";
var cloneableTags = {};
cloneableTags[argsTag$1] = cloneableTags[arrayTag$1] = cloneableTags[arrayBufferTag$1] = cloneableTags[dataViewTag$1] = cloneableTags[boolTag$1] = cloneableTags[dateTag$1] = cloneableTags[float32Tag] = cloneableTags[float64Tag] = cloneableTags[int8Tag] = cloneableTags[int16Tag] = cloneableTags[int32Tag] = cloneableTags[mapTag$2] = cloneableTags[numberTag$1] = cloneableTags[objectTag$1] = cloneableTags[regexpTag$1] = cloneableTags[setTag$2] = cloneableTags[stringTag$1] = cloneableTags[symbolTag$1] = cloneableTags[uint8Tag] = cloneableTags[uint8ClampedTag] = cloneableTags[uint16Tag] = cloneableTags[uint32Tag] = true;
cloneableTags[errorTag$1] = cloneableTags[funcTag] = cloneableTags[weakMapTag] = false;
function baseClone(value, bitmask, customizer, key, object, stack) {
  var result, isDeep = bitmask & CLONE_DEEP_FLAG$2, isFlat = bitmask & CLONE_FLAT_FLAG$1, isFull = bitmask & CLONE_SYMBOLS_FLAG$3;
  if (customizer) {
    result = object ? customizer(value, key, object, stack) : customizer(value);
  }
  if (result !== void 0) {
    return result;
  }
  if (!isObject(value)) {
    return value;
  }
  var isArr = isArray$1(value);
  if (isArr) {
    result = initCloneArray(value);
    if (!isDeep) {
      return copyArray(value, result);
    }
  } else {
    var tag = getTag$1(value), isFunc = tag == funcTag || tag == genTag;
    if (isBuffer$1(value)) {
      return cloneBuffer(value, isDeep);
    }
    if (tag == objectTag$1 || tag == argsTag$1 || isFunc && !object) {
      result = isFlat || isFunc ? {} : initCloneObject(value);
      if (!isDeep) {
        return isFlat ? copySymbolsIn(value, baseAssignIn(result, value)) : copySymbols(value, baseAssign(result, value));
      }
    } else {
      if (!cloneableTags[tag]) {
        return object ? value : {};
      }
      result = initCloneByTag(value, tag, isDeep);
    }
  }
  stack || (stack = new Stack());
  var stacked = stack.get(value);
  if (stacked) {
    return stacked;
  }
  stack.set(value, result);
  if (isSet$1(value)) {
    value.forEach(function(subValue) {
      result.add(baseClone(subValue, bitmask, customizer, subValue, value, stack));
    });
  } else if (isMap$1(value)) {
    value.forEach(function(subValue, key2) {
      result.set(key2, baseClone(subValue, bitmask, customizer, key2, value, stack));
    });
  }
  var keysFunc = isFull ? isFlat ? getAllKeysIn : getAllKeys : isFlat ? keysIn : keys;
  var props = isArr ? void 0 : keysFunc(value);
  arrayEach(props || value, function(subValue, key2) {
    if (props) {
      key2 = subValue;
      subValue = value[key2];
    }
    assignValue(result, key2, baseClone(subValue, bitmask, customizer, key2, value, stack));
  });
  return result;
}
var CLONE_SYMBOLS_FLAG$2 = 4;
function clone(value) {
  return baseClone(value, CLONE_SYMBOLS_FLAG$2);
}
var CLONE_DEEP_FLAG$1 = 1, CLONE_SYMBOLS_FLAG$1 = 4;
function cloneDeep(value) {
  return baseClone(value, CLONE_DEEP_FLAG$1 | CLONE_SYMBOLS_FLAG$1);
}
var HASH_UNDEFINED = "__lodash_hash_undefined__";
function setCacheAdd(value) {
  this.__data__.set(value, HASH_UNDEFINED);
  return this;
}
function setCacheHas(value) {
  return this.__data__.has(value);
}
function SetCache(values) {
  var index = -1, length = values == null ? 0 : values.length;
  this.__data__ = new MapCache();
  while (++index < length) {
    this.add(values[index]);
  }
}
SetCache.prototype.add = SetCache.prototype.push = setCacheAdd;
SetCache.prototype.has = setCacheHas;
function arraySome(array, predicate) {
  var index = -1, length = array == null ? 0 : array.length;
  while (++index < length) {
    if (predicate(array[index], index, array)) {
      return true;
    }
  }
  return false;
}
function cacheHas(cache, key) {
  return cache.has(key);
}
var COMPARE_PARTIAL_FLAG$3 = 1, COMPARE_UNORDERED_FLAG$1 = 2;
function equalArrays(array, other, bitmask, customizer, equalFunc, stack) {
  var isPartial = bitmask & COMPARE_PARTIAL_FLAG$3, arrLength = array.length, othLength = other.length;
  if (arrLength != othLength && !(isPartial && othLength > arrLength)) {
    return false;
  }
  var arrStacked = stack.get(array);
  var othStacked = stack.get(other);
  if (arrStacked && othStacked) {
    return arrStacked == other && othStacked == array;
  }
  var index = -1, result = true, seen = bitmask & COMPARE_UNORDERED_FLAG$1 ? new SetCache() : void 0;
  stack.set(array, other);
  stack.set(other, array);
  while (++index < arrLength) {
    var arrValue = array[index], othValue = other[index];
    if (customizer) {
      var compared = isPartial ? customizer(othValue, arrValue, index, other, array, stack) : customizer(arrValue, othValue, index, array, other, stack);
    }
    if (compared !== void 0) {
      if (compared) {
        continue;
      }
      result = false;
      break;
    }
    if (seen) {
      if (!arraySome(other, function(othValue2, othIndex) {
        if (!cacheHas(seen, othIndex) && (arrValue === othValue2 || equalFunc(arrValue, othValue2, bitmask, customizer, stack))) {
          return seen.push(othIndex);
        }
      })) {
        result = false;
        break;
      }
    } else if (!(arrValue === othValue || equalFunc(arrValue, othValue, bitmask, customizer, stack))) {
      result = false;
      break;
    }
  }
  stack["delete"](array);
  stack["delete"](other);
  return result;
}
function mapToArray(map) {
  var index = -1, result = Array(map.size);
  map.forEach(function(value, key) {
    result[++index] = [key, value];
  });
  return result;
}
function setToArray(set) {
  var index = -1, result = Array(set.size);
  set.forEach(function(value) {
    result[++index] = value;
  });
  return result;
}
var COMPARE_PARTIAL_FLAG$2 = 1, COMPARE_UNORDERED_FLAG = 2;
var boolTag = "[object Boolean]", dateTag = "[object Date]", errorTag = "[object Error]", mapTag$1 = "[object Map]", numberTag = "[object Number]", regexpTag = "[object RegExp]", setTag$1 = "[object Set]", stringTag = "[object String]", symbolTag = "[object Symbol]";
var arrayBufferTag = "[object ArrayBuffer]", dataViewTag = "[object DataView]";
var symbolProto = Symbol$2 ? Symbol$2.prototype : void 0, symbolValueOf = symbolProto ? symbolProto.valueOf : void 0;
function equalByTag(object, other, tag, bitmask, customizer, equalFunc, stack) {
  switch (tag) {
    case dataViewTag:
      if (object.byteLength != other.byteLength || object.byteOffset != other.byteOffset) {
        return false;
      }
      object = object.buffer;
      other = other.buffer;
    case arrayBufferTag:
      if (object.byteLength != other.byteLength || !equalFunc(new Uint8Array$1(object), new Uint8Array$1(other))) {
        return false;
      }
      return true;
    case boolTag:
    case dateTag:
    case numberTag:
      return eq(+object, +other);
    case errorTag:
      return object.name == other.name && object.message == other.message;
    case regexpTag:
    case stringTag:
      return object == other + "";
    case mapTag$1:
      var convert = mapToArray;
    case setTag$1:
      var isPartial = bitmask & COMPARE_PARTIAL_FLAG$2;
      convert || (convert = setToArray);
      if (object.size != other.size && !isPartial) {
        return false;
      }
      var stacked = stack.get(object);
      if (stacked) {
        return stacked == other;
      }
      bitmask |= COMPARE_UNORDERED_FLAG;
      stack.set(object, other);
      var result = equalArrays(convert(object), convert(other), bitmask, customizer, equalFunc, stack);
      stack["delete"](object);
      return result;
    case symbolTag:
      if (symbolValueOf) {
        return symbolValueOf.call(object) == symbolValueOf.call(other);
      }
  }
  return false;
}
var COMPARE_PARTIAL_FLAG$1 = 1;
var objectProto$2 = Object.prototype;
var hasOwnProperty$2 = objectProto$2.hasOwnProperty;
function equalObjects(object, other, bitmask, customizer, equalFunc, stack) {
  var isPartial = bitmask & COMPARE_PARTIAL_FLAG$1, objProps = getAllKeys(object), objLength = objProps.length, othProps = getAllKeys(other), othLength = othProps.length;
  if (objLength != othLength && !isPartial) {
    return false;
  }
  var index = objLength;
  while (index--) {
    var key = objProps[index];
    if (!(isPartial ? key in other : hasOwnProperty$2.call(other, key))) {
      return false;
    }
  }
  var objStacked = stack.get(object);
  var othStacked = stack.get(other);
  if (objStacked && othStacked) {
    return objStacked == other && othStacked == object;
  }
  var result = true;
  stack.set(object, other);
  stack.set(other, object);
  var skipCtor = isPartial;
  while (++index < objLength) {
    key = objProps[index];
    var objValue = object[key], othValue = other[key];
    if (customizer) {
      var compared = isPartial ? customizer(othValue, objValue, key, other, object, stack) : customizer(objValue, othValue, key, object, other, stack);
    }
    if (!(compared === void 0 ? objValue === othValue || equalFunc(objValue, othValue, bitmask, customizer, stack) : compared)) {
      result = false;
      break;
    }
    skipCtor || (skipCtor = key == "constructor");
  }
  if (result && !skipCtor) {
    var objCtor = object.constructor, othCtor = other.constructor;
    if (objCtor != othCtor && ("constructor" in object && "constructor" in other) && !(typeof objCtor == "function" && objCtor instanceof objCtor && typeof othCtor == "function" && othCtor instanceof othCtor)) {
      result = false;
    }
  }
  stack["delete"](object);
  stack["delete"](other);
  return result;
}
var COMPARE_PARTIAL_FLAG = 1;
var argsTag = "[object Arguments]", arrayTag = "[object Array]", objectTag = "[object Object]";
var objectProto$1 = Object.prototype;
var hasOwnProperty$1 = objectProto$1.hasOwnProperty;
function baseIsEqualDeep(object, other, bitmask, customizer, equalFunc, stack) {
  var objIsArr = isArray$1(object), othIsArr = isArray$1(other), objTag = objIsArr ? arrayTag : getTag$1(object), othTag = othIsArr ? arrayTag : getTag$1(other);
  objTag = objTag == argsTag ? objectTag : objTag;
  othTag = othTag == argsTag ? objectTag : othTag;
  var objIsObj = objTag == objectTag, othIsObj = othTag == objectTag, isSameTag = objTag == othTag;
  if (isSameTag && isBuffer$1(object)) {
    if (!isBuffer$1(other)) {
      return false;
    }
    objIsArr = true;
    objIsObj = false;
  }
  if (isSameTag && !objIsObj) {
    stack || (stack = new Stack());
    return objIsArr || isTypedArray$1(object) ? equalArrays(object, other, bitmask, customizer, equalFunc, stack) : equalByTag(object, other, objTag, bitmask, customizer, equalFunc, stack);
  }
  if (!(bitmask & COMPARE_PARTIAL_FLAG)) {
    var objIsWrapped = objIsObj && hasOwnProperty$1.call(object, "__wrapped__"), othIsWrapped = othIsObj && hasOwnProperty$1.call(other, "__wrapped__");
    if (objIsWrapped || othIsWrapped) {
      var objUnwrapped = objIsWrapped ? object.value() : object, othUnwrapped = othIsWrapped ? other.value() : other;
      stack || (stack = new Stack());
      return equalFunc(objUnwrapped, othUnwrapped, bitmask, customizer, stack);
    }
  }
  if (!isSameTag) {
    return false;
  }
  stack || (stack = new Stack());
  return equalObjects(object, other, bitmask, customizer, equalFunc, stack);
}
function baseIsEqual(value, other, bitmask, customizer, stack) {
  if (value === other) {
    return true;
  }
  if (value == null || other == null || !isObjectLike(value) && !isObjectLike(other)) {
    return value !== value && other !== other;
  }
  return baseIsEqualDeep(value, other, bitmask, customizer, baseIsEqual, stack);
}
function createBaseFor(fromRight) {
  return function(object, iteratee, keysFunc) {
    var index = -1, iterable = Object(object), props = keysFunc(object), length = props.length;
    while (length--) {
      var key = props[fromRight ? length : ++index];
      if (iteratee(iterable[key], key, iterable) === false) {
        break;
      }
    }
    return object;
  };
}
var baseFor = createBaseFor();
const baseFor$1 = baseFor;
function assignMergeValue(object, key, value) {
  if (value !== void 0 && !eq(object[key], value) || value === void 0 && !(key in object)) {
    baseAssignValue(object, key, value);
  }
}
function isArrayLikeObject(value) {
  return isObjectLike(value) && isArrayLike(value);
}
function safeGet(object, key) {
  if (key === "constructor" && typeof object[key] === "function") {
    return;
  }
  if (key == "__proto__") {
    return;
  }
  return object[key];
}
function toPlainObject(value) {
  return copyObject(value, keysIn(value));
}
function baseMergeDeep(object, source, key, srcIndex, mergeFunc, customizer, stack) {
  var objValue = safeGet(object, key), srcValue = safeGet(source, key), stacked = stack.get(srcValue);
  if (stacked) {
    assignMergeValue(object, key, stacked);
    return;
  }
  var newValue = customizer ? customizer(objValue, srcValue, key + "", object, source, stack) : void 0;
  var isCommon = newValue === void 0;
  if (isCommon) {
    var isArr = isArray$1(srcValue), isBuff = !isArr && isBuffer$1(srcValue), isTyped = !isArr && !isBuff && isTypedArray$1(srcValue);
    newValue = srcValue;
    if (isArr || isBuff || isTyped) {
      if (isArray$1(objValue)) {
        newValue = objValue;
      } else if (isArrayLikeObject(objValue)) {
        newValue = copyArray(objValue);
      } else if (isBuff) {
        isCommon = false;
        newValue = cloneBuffer(srcValue, true);
      } else if (isTyped) {
        isCommon = false;
        newValue = cloneTypedArray(srcValue, true);
      } else {
        newValue = [];
      }
    } else if (isPlainObject(srcValue) || isArguments$1(srcValue)) {
      newValue = objValue;
      if (isArguments$1(objValue)) {
        newValue = toPlainObject(objValue);
      } else if (!isObject(objValue) || isFunction(objValue)) {
        newValue = initCloneObject(srcValue);
      }
    } else {
      isCommon = false;
    }
  }
  if (isCommon) {
    stack.set(srcValue, newValue);
    mergeFunc(newValue, srcValue, srcIndex, customizer, stack);
    stack["delete"](srcValue);
  }
  assignMergeValue(object, key, newValue);
}
function baseMerge(object, source, srcIndex, customizer, stack) {
  if (object === source) {
    return;
  }
  baseFor$1(source, function(srcValue, key) {
    stack || (stack = new Stack());
    if (isObject(srcValue)) {
      baseMergeDeep(object, source, key, srcIndex, baseMerge, customizer, stack);
    } else {
      var newValue = customizer ? customizer(safeGet(object, key), srcValue, key + "", object, source, stack) : void 0;
      if (newValue === void 0) {
        newValue = srcValue;
      }
      assignMergeValue(object, key, newValue);
    }
  }, keysIn);
}
function last(array) {
  var length = array == null ? 0 : array.length;
  return length ? array[length - 1] : void 0;
}
function parent(object, path) {
  return path.length < 2 ? object : baseGet(object, baseSlice(path, 0, -1));
}
var mapTag = "[object Map]", setTag = "[object Set]";
var objectProto = Object.prototype;
var hasOwnProperty = objectProto.hasOwnProperty;
function isEmpty$1(value) {
  if (value == null) {
    return true;
  }
  if (isArrayLike(value) && (isArray$1(value) || typeof value == "string" || typeof value.splice == "function" || isBuffer$1(value) || isTypedArray$1(value) || isArguments$1(value))) {
    return !value.length;
  }
  var tag = getTag$1(value);
  if (tag == mapTag || tag == setTag) {
    return !value.size;
  }
  if (isPrototype(value)) {
    return !baseKeys(value).length;
  }
  for (var key in value) {
    if (hasOwnProperty.call(value, key)) {
      return false;
    }
  }
  return true;
}
function isEqual(value, other) {
  return baseIsEqual(value, other);
}
function isNil(value) {
  return value == null;
}
function isNull(value) {
  return value === null;
}
function isUndefined(value) {
  return value === void 0;
}
var merge = createAssigner(function(object, source, srcIndex) {
  baseMerge(object, source, srcIndex);
});
const merge$1 = merge;
function baseUnset(object, path) {
  path = castPath(path, object);
  object = parent(object, path);
  return object == null || delete object[toKey(last(path))];
}
function customOmitClone(value) {
  return isPlainObject(value) ? void 0 : value;
}
var CLONE_DEEP_FLAG = 1, CLONE_FLAT_FLAG = 2, CLONE_SYMBOLS_FLAG = 4;
var omit = flatRest(function(object, paths) {
  var result = {};
  if (object == null) {
    return result;
  }
  var isDeep = false;
  paths = arrayMap$1(paths, function(path) {
    path = castPath(path, object);
    isDeep || (isDeep = path.length > 1);
    return path;
  });
  copyObject(object, getAllKeysIn(object), result);
  if (isDeep) {
    result = baseClone(result, CLONE_DEEP_FLAG | CLONE_FLAT_FLAG | CLONE_SYMBOLS_FLAG, customOmitClone);
  }
  var length = paths.length;
  while (length--) {
    baseUnset(result, paths[length]);
  }
  return result;
});
const omit$1 = omit;
const ApiServiceErrorCodes = {
  token: [
    "error.token.0",
    "error.token.1",
    "error.token.2",
    "error.token.3",
    "error.token.4",
    "error.token.5",
    "error.token.6",
    "error.token.9"
  ]
};
const mergeQueryConfig = (query, config2) => {
  if (!query) {
    return config2;
  }
  if (!config2) {
    return {
      params: query
    };
  }
  return merge$1(
    {
      params: query
    },
    config2
  );
};
const isValidateCondition = (condition) => {
  return condition.val;
};
function newBoValue(id, idKey = "id") {
  return {
    [idKey]: id
  };
}
function isInvalidateTokenErrorCode(errCode) {
  return ApiServiceErrorCodes.token.includes(errCode);
}
const ApiUtils = {
  mergeQueryConfig,
  isValidateCondition,
  newBoValue,
  isInvalidateTokenErrorCode
};
function rangeInForce(oriValue, range) {
  const min = range[0];
  const max = range[1];
  oriValue = oriValue < min ? min : oriValue > max ? max : oriValue;
  return oriValue;
}
const omitUndefined = (obj) => {
  const newObj = {};
  Object.keys(obj || {}).forEach((key) => {
    if (obj[key] !== void 0) {
      newObj[key] = obj[key];
    }
  });
  if (Object.keys(newObj).length < 1) {
    return void 0;
  }
  return newObj;
};
const DataUtils = {
  omitUndefined
};
const tuple = (...args) => args;
const tupleNum = (...args) => args;
function getBirthday(idNo) {
  if (!idNo)
    return;
  return moment(idNo.substr(6, 8), "YYYYMMDD").valueOf();
}
const IdCardUtils = {
  getBirthday
};
const options = {
  toneType: "none",
  type: "array",
  multiple: false
};
const loadPinyinPro = () => import("./index.esm.5e5b6fa0.mjs");
async function getPinyinAsync(word) {
  if (!word) {
    return void 0;
  }
  const { pinyin } = await loadPinyinPro();
  const result = pinyin(word, options);
  if (result) {
    return result.join("");
  }
}
const PinyinUtils = {
  zhToPinyinAsync: getPinyinAsync
};
const isEmpty = (value) => {
  return typeof value === "undefined" || value === "" || value === null;
};
const replaceWhiteSpace = (value) => {
  if (value && typeof value === "string") {
    return value.replace(/\s/g, "&nbsp;");
  }
};
const formatString = (messageFormat, ...args) => {
  if (!messageFormat)
    return null;
  let str = messageFormat;
  for (let i2 = 0; i2 < args.length; i2++) {
    const re = new RegExp("\\{" + i2 + "\\}", "gm");
    str = str.replace(re, args[i2]);
  }
  return str;
};
const StringUtils = {
  isEmpty,
  replaceWithNbsp: replaceWhiteSpace,
  format: formatString
};
function isDateRangeObject(value) {
  return isPlainObject(value) && !isNil(value.from) && !isNil(value.to);
}
const momentable = (value, format2) => {
  return Array.isArray(value) ? value.map((val) => moment(val, format2)) : isDateRangeObject(value) ? [moment(value.from), moment(value.to)] : value ? moment(value, format2) : value;
};
const formatMomentValue = (value, format2, placeholder) => {
  const formatDate = (date, format22, i2 = 0) => {
    if (!date)
      return placeholder;
    if (isArray$1(format22)) {
      const _format = format22[i2];
      if (isFunction(_format)) {
        return _format(date);
      }
      return (date == null ? void 0 : date.format) ? date.format(_format) : date;
    } else {
      if (isFunction(format22)) {
        return format22(date);
      }
      return (date == null ? void 0 : date.format) ? date.format(format22) : date;
    }
  };
  if (isArray$1(value)) {
    if (value.length) {
      return value.map((val, index) => {
        return formatDate(val, format2, index);
      });
    }
    return placeholder || value;
  } else {
    return value ? formatDate(value, format2) : value || placeholder;
  }
};
const decodeMomentValue = (value) => {
  const decodeValue = (m) => {
    return m == null ? void 0 : m.valueOf();
  };
  if (isArray$1(value)) {
    return value == null ? void 0 : value.map((item) => decodeValue(item));
  }
  return decodeValue(value);
};
const decodeMomentValueAsObject = (value) => {
  var _a, _b;
  if (isArray$1(value)) {
    return {
      from: (_a = value[0]) == null ? void 0 : _a.valueOf(),
      to: (_b = value[1]) == null ? void 0 : _b.valueOf()
    };
  }
  return {
    from: void 0,
    to: void 0
  };
};
const MomentUtils = {
  isDateRangeObject,
  momentable,
  formatMomentValue,
  decodeMomentValue,
  decodeMomentValueAsObject
};
const fileSizeFormat = (size, pointLength = 2, units = ["B", "KB", "MB", "GB", "TB"]) => {
  let unit;
  while ((unit = units.shift()) && size > 1024) {
    size = size / 1024;
  }
  return (unit === "B" ? size : size.toFixed(pointLength)) + unit;
};
const FileUtils = {
  fileSizeFormat
};
const getZhNum = (num) => {
  const NUM = ["\u96F6", "\u4E00", "\u4E8C", "\u4E09", "\u56DB", "\u4E94", "\u516D", "\u4E03", "\u516B", "\u4E5D", "\u5341"];
  const UNIT = ["", "\u5341", "\u767E", "\u5343"];
  const CUNIT = ["\u4E07", "\u4EBF"];
  const chunk = [];
  let temp = [];
  for (let i2 = String(num).length - 1, j2 = 0; i2 >= 0; i2--, j2++) {
    temp.unshift(String(num)[i2]);
    if ((j2 + 1) % 4 === 0 || i2 === 0) {
      chunk.unshift(temp);
      temp = [];
    }
  }
  const res = [];
  for (let i2 = chunk.length - 1, cu = 0; i2 >= 0; i2--, cu++) {
    let temp2 = "";
    for (let j2 = chunk[i2].length - 1, u2 = 0; j2 >= 0; j2--, u2++) {
      temp2 = NUM[chunk[i2][j2]] + (parseInt(chunk[i2][j2], 10) === 0 ? "" : UNIT[u2]) + temp2;
    }
    temp2 = temp2.replace(/零+/g, "\u96F6").replace(/零$/, "").replace(/^一十/, "\u5341");
    if (cu >= 1) {
      temp2 += CUNIT[(cu - 1) % 2];
    }
    res.unshift(temp2);
  }
  return res.join("");
};
const ZhNumUtils = {
  numToZh: getZhNum
};
function uid(len) {
  const hex = getHex();
  let str = "", num = len || 11;
  while (num--)
    str += hex[Math.random() * 36 | 0];
  return str;
}
let HEX = "";
function getHex() {
  if (!HEX) {
    let IDX = 36;
    while (IDX--)
      HEX += IDX.toString(36);
  }
  return HEX;
}
const UidUtils = {
  uidHex: uid
};
function treeBuildIdsMap(tree, options2) {
  var _a;
  const cache = /* @__PURE__ */ new Map();
  const idKey = (_a = options2 == null ? void 0 : options2.idKey) != null ? _a : "id";
  treeTraverse(
    tree,
    (item, parent2) => {
      cache.set(item[idKey], parent2 == null ? void 0 : parent2[idKey]);
    },
    options2
  );
  return cache;
}
function treeBuildFlatMap(tree, options2) {
  var _a;
  const result = /* @__PURE__ */ new Map();
  const idKey = (_a = options2 == null ? void 0 : options2.idKey) != null ? _a : "id";
  treeTraverse(
    tree,
    (item) => {
      result.set(item[idKey], item);
    },
    options2
  );
  return result;
}
function treeFlatten(tree, options2) {
  const result = [];
  treeTraverse(
    tree,
    (item) => {
      result.push(item);
    },
    options2
  );
  return result;
}
function treeFilter(tree, filter, options2) {
  var _a;
  if (!tree) {
    return [];
  }
  const childrenKey = (_a = options2 == null ? void 0 : options2.childrenKey) != null ? _a : "children";
  const treeNodes = tree.filter(filter);
  for (let i2 = 0; i2 < treeNodes.length; i2++) {
    let node = treeNodes[i2];
    if (isArray$1(node[childrenKey])) {
      node = clone(node);
      treeNodes[i2] = node;
      node[childrenKey] = treeFilter(node[childrenKey], filter, options2);
    }
  }
  return treeNodes;
}
function treeFind(tree, filter, options2) {
  var _a;
  if (!tree) {
    return void 0;
  }
  const childrenKey = (_a = options2 == null ? void 0 : options2.childrenKey) != null ? _a : "children";
  for (let i2 = 0; i2 < tree.length; i2++) {
    const node = tree[i2];
    if (filter(node)) {
      return node;
    }
    const children = node[childrenKey];
    if (children) {
      const result = treeFind(children, filter, options2);
      if (result) {
        return result;
      }
    }
  }
}
function treeMap(tree, mapper, options2) {
  var _a;
  if (!tree) {
    return void 0;
  }
  const childrenKey = (_a = options2 == null ? void 0 : options2.childrenKey) != null ? _a : "children";
  const mapTree = (treeNodes, parent2) => {
    return treeNodes.map((item) => {
      const children = isArray$1(item[childrenKey]) ? mapTree(item[childrenKey], item) : void 0;
      return mapper(item, parent2, children);
    });
  };
  return mapTree(tree, void 0);
}
const internalTraverse = (tree, parent2, handler, options2) => {
  if (!tree) {
    return;
  }
  tree.forEach((item) => {
    var _a;
    const ret = handler(item, parent2);
    if (ret) {
      return;
    }
    const childrenKey = (_a = options2 == null ? void 0 : options2.childrenKey) != null ? _a : "children";
    if (isArray$1(item[childrenKey])) {
      internalTraverse(item[childrenKey], item, handler, options2);
    }
  });
};
function treeTraverse(tree, handler, options2) {
  internalTraverse(tree, void 0, handler, options2);
}
const TreeUtils = {
  buildIdsMap: treeBuildIdsMap,
  buildFlatMap: treeBuildFlatMap,
  flatten: treeFlatten,
  filter: treeFilter,
  find: treeFind,
  traverse: treeTraverse
};
function mapValuesToArray(map) {
  return Array.from(map, ([, value]) => value);
}
function mapKeysToArray(map) {
  return Array.from(map, ([key, value]) => key);
}
const MapUtils = {
  mapValuesToArray,
  mapKeysToArray
};
function arrayBuildIdsMap(array, options2) {
  var _a;
  const map = /* @__PURE__ */ new Map();
  const idKey = (_a = options2 == null ? void 0 : options2.idKey) != null ? _a : "id";
  if (!array) {
    return map;
  }
  array.forEach((item) => {
    map.set(item[idKey], item);
  });
  return map;
}
function arrayToIdList(array, options2) {
  var _a;
  const idKey = (_a = options2 == null ? void 0 : options2.idKey) != null ? _a : "id";
  if (!array) {
    return [];
  }
  return array == null ? void 0 : array.map((item) => item[idKey]);
}
function arrayMap(array, options2) {
  if (!array) {
    return [];
  }
  const keys2 = Object.entries(options2);
  if (!(keys2 == null ? void 0 : keys2.length)) {
    return [];
  }
  return array.map((item) => {
    const result = {};
    keys2.forEach((entries) => {
      result[entries[0]] = get(item, entries[1]);
    });
    return result;
  });
}
function toArray(value) {
  if (isNil(value)) {
    return void 0;
  }
  if (isPlainObject(value)) {
    return [value];
  }
  return value;
}
const ArrayUtils = {
  buildIdsMap: arrayBuildIdsMap,
  toIdList: arrayToIdList,
  toMap: arrayMap,
  toArray
};
async function promiseSafeRun(executor) {
  try {
    return await executor();
  } catch (e) {
    console.error(e);
  }
}
function promiseWait(timeMs) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeMs != null ? timeMs : 0);
  });
}
const PromiseUtils = {
  safeRun: promiseSafeRun,
  wait: promiseWait
};
var commonjsGlobal = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
var md5$1 = { exports: {} };
(function(module2) {
  (function($) {
    function safeAdd(x2, y2) {
      var lsw = (x2 & 65535) + (y2 & 65535);
      var msw = (x2 >> 16) + (y2 >> 16) + (lsw >> 16);
      return msw << 16 | lsw & 65535;
    }
    function bitRotateLeft(num, cnt) {
      return num << cnt | num >>> 32 - cnt;
    }
    function md5cmn(q2, a2, b2, x2, s2, t2) {
      return safeAdd(bitRotateLeft(safeAdd(safeAdd(a2, q2), safeAdd(x2, t2)), s2), b2);
    }
    function md5ff(a2, b2, c2, d2, x2, s2, t2) {
      return md5cmn(b2 & c2 | ~b2 & d2, a2, b2, x2, s2, t2);
    }
    function md5gg(a2, b2, c2, d2, x2, s2, t2) {
      return md5cmn(b2 & d2 | c2 & ~d2, a2, b2, x2, s2, t2);
    }
    function md5hh(a2, b2, c2, d2, x2, s2, t2) {
      return md5cmn(b2 ^ c2 ^ d2, a2, b2, x2, s2, t2);
    }
    function md5ii(a2, b2, c2, d2, x2, s2, t2) {
      return md5cmn(c2 ^ (b2 | ~d2), a2, b2, x2, s2, t2);
    }
    function binlMD5(x2, len) {
      x2[len >> 5] |= 128 << len % 32;
      x2[(len + 64 >>> 9 << 4) + 14] = len;
      var i2;
      var olda;
      var oldb;
      var oldc;
      var oldd;
      var a2 = 1732584193;
      var b2 = -271733879;
      var c2 = -1732584194;
      var d2 = 271733878;
      for (i2 = 0; i2 < x2.length; i2 += 16) {
        olda = a2;
        oldb = b2;
        oldc = c2;
        oldd = d2;
        a2 = md5ff(a2, b2, c2, d2, x2[i2], 7, -680876936);
        d2 = md5ff(d2, a2, b2, c2, x2[i2 + 1], 12, -389564586);
        c2 = md5ff(c2, d2, a2, b2, x2[i2 + 2], 17, 606105819);
        b2 = md5ff(b2, c2, d2, a2, x2[i2 + 3], 22, -1044525330);
        a2 = md5ff(a2, b2, c2, d2, x2[i2 + 4], 7, -176418897);
        d2 = md5ff(d2, a2, b2, c2, x2[i2 + 5], 12, 1200080426);
        c2 = md5ff(c2, d2, a2, b2, x2[i2 + 6], 17, -1473231341);
        b2 = md5ff(b2, c2, d2, a2, x2[i2 + 7], 22, -45705983);
        a2 = md5ff(a2, b2, c2, d2, x2[i2 + 8], 7, 1770035416);
        d2 = md5ff(d2, a2, b2, c2, x2[i2 + 9], 12, -1958414417);
        c2 = md5ff(c2, d2, a2, b2, x2[i2 + 10], 17, -42063);
        b2 = md5ff(b2, c2, d2, a2, x2[i2 + 11], 22, -1990404162);
        a2 = md5ff(a2, b2, c2, d2, x2[i2 + 12], 7, 1804603682);
        d2 = md5ff(d2, a2, b2, c2, x2[i2 + 13], 12, -40341101);
        c2 = md5ff(c2, d2, a2, b2, x2[i2 + 14], 17, -1502002290);
        b2 = md5ff(b2, c2, d2, a2, x2[i2 + 15], 22, 1236535329);
        a2 = md5gg(a2, b2, c2, d2, x2[i2 + 1], 5, -165796510);
        d2 = md5gg(d2, a2, b2, c2, x2[i2 + 6], 9, -1069501632);
        c2 = md5gg(c2, d2, a2, b2, x2[i2 + 11], 14, 643717713);
        b2 = md5gg(b2, c2, d2, a2, x2[i2], 20, -373897302);
        a2 = md5gg(a2, b2, c2, d2, x2[i2 + 5], 5, -701558691);
        d2 = md5gg(d2, a2, b2, c2, x2[i2 + 10], 9, 38016083);
        c2 = md5gg(c2, d2, a2, b2, x2[i2 + 15], 14, -660478335);
        b2 = md5gg(b2, c2, d2, a2, x2[i2 + 4], 20, -405537848);
        a2 = md5gg(a2, b2, c2, d2, x2[i2 + 9], 5, 568446438);
        d2 = md5gg(d2, a2, b2, c2, x2[i2 + 14], 9, -1019803690);
        c2 = md5gg(c2, d2, a2, b2, x2[i2 + 3], 14, -187363961);
        b2 = md5gg(b2, c2, d2, a2, x2[i2 + 8], 20, 1163531501);
        a2 = md5gg(a2, b2, c2, d2, x2[i2 + 13], 5, -1444681467);
        d2 = md5gg(d2, a2, b2, c2, x2[i2 + 2], 9, -51403784);
        c2 = md5gg(c2, d2, a2, b2, x2[i2 + 7], 14, 1735328473);
        b2 = md5gg(b2, c2, d2, a2, x2[i2 + 12], 20, -1926607734);
        a2 = md5hh(a2, b2, c2, d2, x2[i2 + 5], 4, -378558);
        d2 = md5hh(d2, a2, b2, c2, x2[i2 + 8], 11, -2022574463);
        c2 = md5hh(c2, d2, a2, b2, x2[i2 + 11], 16, 1839030562);
        b2 = md5hh(b2, c2, d2, a2, x2[i2 + 14], 23, -35309556);
        a2 = md5hh(a2, b2, c2, d2, x2[i2 + 1], 4, -1530992060);
        d2 = md5hh(d2, a2, b2, c2, x2[i2 + 4], 11, 1272893353);
        c2 = md5hh(c2, d2, a2, b2, x2[i2 + 7], 16, -155497632);
        b2 = md5hh(b2, c2, d2, a2, x2[i2 + 10], 23, -1094730640);
        a2 = md5hh(a2, b2, c2, d2, x2[i2 + 13], 4, 681279174);
        d2 = md5hh(d2, a2, b2, c2, x2[i2], 11, -358537222);
        c2 = md5hh(c2, d2, a2, b2, x2[i2 + 3], 16, -722521979);
        b2 = md5hh(b2, c2, d2, a2, x2[i2 + 6], 23, 76029189);
        a2 = md5hh(a2, b2, c2, d2, x2[i2 + 9], 4, -640364487);
        d2 = md5hh(d2, a2, b2, c2, x2[i2 + 12], 11, -421815835);
        c2 = md5hh(c2, d2, a2, b2, x2[i2 + 15], 16, 530742520);
        b2 = md5hh(b2, c2, d2, a2, x2[i2 + 2], 23, -995338651);
        a2 = md5ii(a2, b2, c2, d2, x2[i2], 6, -198630844);
        d2 = md5ii(d2, a2, b2, c2, x2[i2 + 7], 10, 1126891415);
        c2 = md5ii(c2, d2, a2, b2, x2[i2 + 14], 15, -1416354905);
        b2 = md5ii(b2, c2, d2, a2, x2[i2 + 5], 21, -57434055);
        a2 = md5ii(a2, b2, c2, d2, x2[i2 + 12], 6, 1700485571);
        d2 = md5ii(d2, a2, b2, c2, x2[i2 + 3], 10, -1894986606);
        c2 = md5ii(c2, d2, a2, b2, x2[i2 + 10], 15, -1051523);
        b2 = md5ii(b2, c2, d2, a2, x2[i2 + 1], 21, -2054922799);
        a2 = md5ii(a2, b2, c2, d2, x2[i2 + 8], 6, 1873313359);
        d2 = md5ii(d2, a2, b2, c2, x2[i2 + 15], 10, -30611744);
        c2 = md5ii(c2, d2, a2, b2, x2[i2 + 6], 15, -1560198380);
        b2 = md5ii(b2, c2, d2, a2, x2[i2 + 13], 21, 1309151649);
        a2 = md5ii(a2, b2, c2, d2, x2[i2 + 4], 6, -145523070);
        d2 = md5ii(d2, a2, b2, c2, x2[i2 + 11], 10, -1120210379);
        c2 = md5ii(c2, d2, a2, b2, x2[i2 + 2], 15, 718787259);
        b2 = md5ii(b2, c2, d2, a2, x2[i2 + 9], 21, -343485551);
        a2 = safeAdd(a2, olda);
        b2 = safeAdd(b2, oldb);
        c2 = safeAdd(c2, oldc);
        d2 = safeAdd(d2, oldd);
      }
      return [a2, b2, c2, d2];
    }
    function binl2rstr(input) {
      var i2;
      var output = "";
      var length32 = input.length * 32;
      for (i2 = 0; i2 < length32; i2 += 8) {
        output += String.fromCharCode(input[i2 >> 5] >>> i2 % 32 & 255);
      }
      return output;
    }
    function rstr2binl(input) {
      var i2;
      var output = [];
      output[(input.length >> 2) - 1] = void 0;
      for (i2 = 0; i2 < output.length; i2 += 1) {
        output[i2] = 0;
      }
      var length8 = input.length * 8;
      for (i2 = 0; i2 < length8; i2 += 8) {
        output[i2 >> 5] |= (input.charCodeAt(i2 / 8) & 255) << i2 % 32;
      }
      return output;
    }
    function rstrMD5(s2) {
      return binl2rstr(binlMD5(rstr2binl(s2), s2.length * 8));
    }
    function rstrHMACMD5(key, data) {
      var i2;
      var bkey = rstr2binl(key);
      var ipad = [];
      var opad = [];
      var hash;
      ipad[15] = opad[15] = void 0;
      if (bkey.length > 16) {
        bkey = binlMD5(bkey, key.length * 8);
      }
      for (i2 = 0; i2 < 16; i2 += 1) {
        ipad[i2] = bkey[i2] ^ 909522486;
        opad[i2] = bkey[i2] ^ 1549556828;
      }
      hash = binlMD5(ipad.concat(rstr2binl(data)), 512 + data.length * 8);
      return binl2rstr(binlMD5(opad.concat(hash), 512 + 128));
    }
    function rstr2hex(input) {
      var hexTab = "0123456789abcdef";
      var output = "";
      var x2;
      var i2;
      for (i2 = 0; i2 < input.length; i2 += 1) {
        x2 = input.charCodeAt(i2);
        output += hexTab.charAt(x2 >>> 4 & 15) + hexTab.charAt(x2 & 15);
      }
      return output;
    }
    function str2rstrUTF8(input) {
      return unescape(encodeURIComponent(input));
    }
    function rawMD5(s2) {
      return rstrMD5(str2rstrUTF8(s2));
    }
    function hexMD5(s2) {
      return rstr2hex(rawMD5(s2));
    }
    function rawHMACMD5(k2, d2) {
      return rstrHMACMD5(str2rstrUTF8(k2), str2rstrUTF8(d2));
    }
    function hexHMACMD5(k2, d2) {
      return rstr2hex(rawHMACMD5(k2, d2));
    }
    function md52(string, key, raw) {
      if (!key) {
        if (!raw) {
          return hexMD5(string);
        }
        return rawMD5(string);
      }
      if (!raw) {
        return hexHMACMD5(key, string);
      }
      return rawHMACMD5(key, string);
    }
    if (module2.exports) {
      module2.exports = md52;
    } else {
      $.md5 = md52;
    }
  })(commonjsGlobal);
})(md5$1);
const md5 = md5$1.exports;
const md5Of = md5;
const Md5Utils = {
  md5Of
};
const colors = ["#f50", "#2db7f5", "#87d068", "#108ee9"];
const createColor = (id) => {
  const index = id.charCodeAt(0) % colors.length;
  return colors[index];
};
const ColorUtils = {
  createColor
};
const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
const Base64Utils = {
  btoa: (input = "") => {
    const str = input;
    let output = "";
    for (let block = 0, charCode, i2 = 0, map = chars; str.charAt(i2 | 0) || (map = "=", i2 % 1); output += map.charAt(63 & block >> 8 - i2 % 1 * 8)) {
      charCode = str.charCodeAt(i2 += 3 / 4);
      if (charCode > 255) {
        throw new Error(
          "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range."
        );
      }
      block = block << 8 | charCode;
    }
    return output;
  },
  atob: (input = "") => {
    const str = input.replace(/=+$/, "");
    let output = "";
    if (str.length % 4 == 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (let bc = 0, bs = 0, buffer, i2 = 0; buffer = str.charAt(i2++); ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer, bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0) {
      buffer = chars.indexOf(buffer);
    }
    return output;
  }
};
function between(value, min, max) {
  let result = value;
  if (!isNil(min)) {
    result = result < min ? min : result;
  }
  if (!isNil(max)) {
    result = result > max ? max : result;
  }
  return result;
}
function isBetween(value, min, max) {
  return !isNil(min) && value >= min && !isNil(max) && value <= max;
}
function preservePrecision(value, precision) {
  if (isNil(precision)) {
    return value;
  }
  return Number(value.toFixed(precision));
}
function isPrecisionMatched(value, precision) {
  if (isNil(precision) || isNil(value)) {
    return true;
  }
  const [, decimalPart] = String(value).split(".");
  const len = decimalPart ? decimalPart.length : 0;
  return len <= precision;
}
const NumberUtils = {
  between,
  isBetween,
  preservePrecision,
  isPrecisionMatched
};
const FIX_PHONE_AREA_CODES_3 = [
  "010",
  "020",
  "021",
  "022",
  "023",
  "024",
  "025",
  "027",
  "028",
  "029"
];
function format(phone, splitChar = " ") {
  const type = getPhoneNumberTypeByPhoneNumber(phone);
  if (type === null) {
    return "";
  }
  if (type === PhoneNumberType.fixed) {
    return formatFixedNumber(phone, splitChar);
  }
  return formatMobileNumber(phone, splitChar);
}
function removeSpecialChar(phone) {
  if (!phone) {
    return phone;
  }
  return phone.replace(/\D/g, "");
}
function formatFixedNumber(phone, splitChar = " ") {
  phone = removeSpecialChar(phone);
  if (!phone) {
    return "";
  }
  if (phone.length === 7) {
    return phone.replace(/^(\d{3})(\d{4})$/, "$1" + splitChar + "$2");
  }
  if (phone.length === 8) {
    return phone.replace(/^(\d{4})(\d{4})$/, "$1" + splitChar + "$2");
  }
  const prefix = phone.substring(0, 3);
  const is3AreaCode = FIX_PHONE_AREA_CODES_3.includes(prefix);
  if (is3AreaCode && phone.length === 10) {
    return phone.replace(/^(\d{3})(\d{3})(\d{4})$/, "$1" + splitChar + "$2" + splitChar + "$3");
  }
  if (is3AreaCode && phone.length === 11) {
    return phone.replace(/^(\d{3})(\d{4})(\d{4})$/, "$1" + splitChar + "$2" + splitChar + "$3");
  }
  if (!is3AreaCode && phone.length === 11) {
    return phone.replace(/^(\d{4})(\d{3})(\d{4})$/, "$1" + splitChar + "$2" + splitChar + "$3");
  }
  if (!is3AreaCode && phone.length === 12) {
    return phone.replace(/^(\d{4})(\d{4})(\d{4})$/, "$1" + splitChar + "$2" + splitChar + "$3");
  }
  return phone;
}
function formatMobileNumber(phone, splitChar = " ") {
  phone = removeSpecialChar(phone);
  if (!phone) {
    return "";
  }
  return phone.replace(/^(\d{3})(\d{4})(\d{4})$/, "$1" + splitChar + "$2" + splitChar + "$3");
}
function unFormat(phoneStr) {
  return phoneStr.replace(/\D/g, "");
}
function getPhoneNumberTypeByPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return null;
  }
  if (phoneNumber[0] === "0" && (phoneNumber.length == 10 || phoneNumber.length == 11 || phoneNumber.length == 12)) {
    return PhoneNumberType.fixed;
  }
  if (phoneNumber.length === 8 || phoneNumber.length === 7) {
    return PhoneNumberType.fixed;
  }
  return PhoneNumberType.mobile;
}
var PhoneNumberType = /* @__PURE__ */ ((PhoneNumberType2) => {
  PhoneNumberType2["fixed"] = "fixed";
  PhoneNumberType2["mobile"] = "mobile";
  return PhoneNumberType2;
})(PhoneNumberType || {});
const PhoneUtils = {
  removeSpecialChar,
  format,
  unFormat
};
class CountDownTimer {
  constructor(options2) {
    __publicField(this, "period", 0);
    __publicField(this, "timeRemind", 0);
    __publicField(this, "timer");
    __publicField(this, "onFinish");
    __publicField(this, "onTimer");
    this.onFinish = options2 == null ? void 0 : options2.onFinish;
    this.onTimer = options2 == null ? void 0 : options2.onTimer;
  }
  setOnFinish(onFinish) {
    this.onFinish = onFinish;
  }
  setOnTimer(onTimer) {
    this.onTimer = onTimer;
  }
  start(timeSum, period = 1e3) {
    this.period = period;
    this.timeRemind = timeSum;
    if (this.hasRunning()) {
      this.stop();
    }
    this.timer = setInterval(() => {
      this.onTimerCalc();
    }, period);
  }
  hasRunning() {
    return !!this.timer;
  }
  stop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = void 0;
    }
  }
  onTimerCalc() {
    var _a;
    this.timeRemind -= this.period;
    (_a = this.onTimer) == null ? void 0 : _a.call(this, this.timeRemind);
    if (this.timeRemind <= 0) {
      this.onTimeUp();
    }
  }
  onTimeUp() {
    var _a;
    this.stop();
    (_a = this.onFinish) == null ? void 0 : _a.call(this);
  }
}
const PerspectiveTypes = tuple("list", "kanban");
var BfEditMode = /* @__PURE__ */ ((BfEditMode2) => {
  BfEditMode2["Edit"] = "Edit";
  BfEditMode2["Create"] = "Create";
  BfEditMode2["Detail"] = "Detail";
  return BfEditMode2;
})(BfEditMode || {});
var BfPageMode = /* @__PURE__ */ ((BfPageMode2) => {
  BfPageMode2["Modal"] = "Modal";
  BfPageMode2["NewPage"] = "NewPage";
  return BfPageMode2;
})(BfPageMode || {});
const BoValidationTriggerTypes = tuple("blur", "focus", "input");
const BoAttributeTypes = tuple(
  "text",
  "textarea",
  "number",
  "richText",
  "bool",
  "date",
  "dateTime",
  "money",
  "email",
  "phone",
  "reference",
  "enumValue",
  "file",
  "pic",
  "dateScope",
  "address",
  "json",
  "areaProvince",
  "areaCity",
  "areaCounty",
  "website",
  "apiModel",
  "coordinates",
  "subForm",
  "container",
  "group",
  "object",
  "signature"
);
var StructureQNameType = /* @__PURE__ */ ((StructureQNameType2) => {
  StructureQNameType2["doc"] = "doc";
  StructureQNameType2["dataview"] = "dataview";
  StructureQNameType2["bo"] = "bo";
  return StructureQNameType2;
})(StructureQNameType || {});
class AbstractBoIdentifiableProvider {
  getKey(data) {
    return data.id;
  }
  getName(data) {
    return data.name;
  }
  getIcon(data) {
    return data.icon;
  }
}
class AbstractBoCommandProvider extends AbstractBoIdentifiableProvider {
  constructor(actions) {
    super();
    __publicField(this, "commands");
    this.commands = actions || [];
  }
  getItemById(actionId) {
    return this.commands.find((item) => this.getKey(item) === actionId);
  }
  setItems(items) {
    this.commands = items || [];
  }
  getItems() {
    return this.commands;
  }
}
class StandardCommandProvider extends AbstractBoCommandProvider {
  getConfirmController(item) {
    return item.confirmController;
  }
  getDisplayType(item) {
    var _a;
    return (_a = item.mode) != null ? _a : "IConText";
  }
}
const TwRTConst = {
  ID_NULL: ""
};
const TwRTErrorCodes = {
  UNDEFINED: "undefined",
  ILLEGAL_ARGUMENTS: "illegal_arguments",
  ENGINE_TYPE_NOT_SUPPORTABLE: "engine_type_not_supportable",
  UN_IMPL: "unimplemented",
  NO_MENU_PERMISSION: "no_menu_permission",
  NOTICE_INIT_ERR: "notice_init_err",
  PUSH_INIT_ERR: "push_init_err",
  MENU_ILLEGAL: "menu_illegal",
  NOT_NULL: "not_null",
  CONDITION: "condition",
  ILLEGAL_STATE: "illegal_state",
  TAB_NOT_SUPPORT: "tab_not_supported"
};
const TwRTErrorMessageMap = {
  [TwRTErrorCodes.UNDEFINED]: "\u53C2\u6570\u672A\u5B9A\u4E49",
  [TwRTErrorCodes.ILLEGAL_ARGUMENTS]: "\u53C2\u6570\u4E0D\u7B26\u5408\u8981\u6C42",
  [TwRTErrorCodes.ENGINE_TYPE_NOT_SUPPORTABLE]: "\u4E0D\u652F\u6301\u7684\u5F15\u64CE\u7C7B\u578B",
  [TwRTErrorCodes.UN_IMPL]: "\u65B9\u6CD5\u6682\u672A\u5B9E\u73B0",
  [TwRTErrorCodes.NO_MENU_PERMISSION]: "\u6CA1\u6709\u83DC\u5355\u8BBF\u95EE\u6743\u9650",
  [TwRTErrorCodes.NOTICE_INIT_ERR]: "\u6D88\u606F\u901A\u77E5\u521D\u59CB\u5316\u5931\u8D25",
  [TwRTErrorCodes.PUSH_INIT_ERR]: "\u63A8\u9001\u670D\u52A1\u521D\u59CB\u5316\u5931\u8D25",
  [TwRTErrorCodes.MENU_ILLEGAL]: "\u83DC\u5355\u8FD4\u56DE\u6570\u636E\u4E3A\u7A7A\u503C",
  [TwRTErrorCodes.ILLEGAL_STATE]: "\u975E\u6CD5\u72B6\u6001",
  [TwRTErrorCodes.TAB_NOT_SUPPORT]: "API \u4E0D\u53EF\u7528\uFF0C\u5E94\u7528\u88AB\u8BBE\u7F6E\u4E0D\u652F\u6301\u9875\u7B7E"
};
class TwRTError extends Error {
  constructor(errorCode, message) {
    super(message);
    __publicField(this, "errorCode");
    this.errorCode = errorCode;
  }
  getErrorCode() {
    return this.errorCode;
  }
  getMessage() {
    return this.message;
  }
}
function getErrorMsg(errorCode) {
  const message = TwRTErrorMessageMap[errorCode];
  if (!message) {
    throw new Error(`\u672A\u5B9A\u4E49 ${errorCode} \u7684\u9519\u8BEF\u4FE1\u606F,\u8BF7\u5728 runtime \u4E2D\u5B9A\u4E49`);
  }
  return message;
}
function throwRuntimeError(errorCode, ...args) {
  const message = getErrorMsg(errorCode);
  const resultMessage = "" + formatString(message, ...args);
  throw new TwRTError(errorCode, resultMessage);
}
function mustNotBeNull(value, message, ...args) {
  if (isEmpty(value)) {
    console.error(message, ...args);
    throw new TwRTError(TwRTErrorCodes.NOT_NULL, message);
  }
}
function mustBe(condition, message) {
  if (!condition) {
    throw new TwRTError(TwRTErrorCodes.CONDITION, message);
  }
}
function warnEmpty(value, message, ...args) {
  if (isEmpty(value)) {
    console.warn(message, ...args);
  }
}
function warnNotArrayType(value, message, ...args) {
  if (isNil(value)) {
    return;
  }
  if (!isArray$1(value)) {
    console.warn(message != null ? message : "\u503C\u5FC5\u987B\u662F\u6570\u7EC4", ...args);
  }
}
class Observable {
  constructor() {
    __publicField(this, "listeners", []);
    __publicField(this, "_value");
  }
  get value() {
    return this._value;
  }
  observer(lis) {
    this.listeners.push(lis);
  }
  unObserver(lis) {
    const index = this.listeners.findIndex((item) => item === lis);
    if (index > -1) {
      this.listeners.splice(index, 1);
    }
  }
  set(value) {
    var _a;
    if (this._value === value) {
      return;
    }
    this._value = value;
    (_a = this.listeners) == null ? void 0 : _a.forEach((lis) => {
      lis(value);
    });
  }
}
var HttpLogLevel = /* @__PURE__ */ ((HttpLogLevel2) => {
  HttpLogLevel2[HttpLogLevel2["NONE"] = 0] = "NONE";
  HttpLogLevel2[HttpLogLevel2["HEADER"] = 1] = "HEADER";
  HttpLogLevel2[HttpLogLevel2["BODY"] = 2] = "BODY";
  return HttpLogLevel2;
})(HttpLogLevel || {});
class HttpclientError extends Error {
  constructor(code, message) {
    super(message);
    __publicField(this, "_code");
    this._code = code;
  }
  get code() {
    return this._code;
  }
}
class HttpClient {
  constructor(options2 = {}) {
    __publicField(this, "realClient");
    __publicField(this, "dynamicHeaders");
    __publicField(this, "logLevel", HttpLogLevel.NONE);
    __publicField(this, "useTransformResponse");
    this.parseOptions(options2);
    this.realClient = this.createClient(options2);
    this.addReqInterceptor(this.handleBeforeRequest.bind(this), this.onInternalError.bind(this));
    this.addResInterceptor(this.handleResponse.bind(this), this.onNetworkError.bind(this));
  }
  parseOptions(options2) {
    var _a, _b;
    const rawHeaders = options2.headers;
    const dynamicHeaders = {};
    const staticHeaders = {};
    if (rawHeaders) {
      Object.keys(rawHeaders).forEach((key) => {
        const val = rawHeaders[key];
        typeof val === "function" ? dynamicHeaders[key] = val : staticHeaders[key] = val;
      });
    }
    options2.headers = staticHeaders;
    this.dynamicHeaders = dynamicHeaders;
    this.logLevel = (_a = options2.logLevel) != null ? _a : HttpLogLevel.NONE;
    this.useTransformResponse = (_b = options2.useTransformResponse) != null ? _b : true;
  }
  get(url, query, config) {
    return this.realClient.get(url, mergeQueryConfig(query, config));
  }
  post(url, data, query, config) {
    return this.realClient.post(url, data, mergeQueryConfig(query, config));
  }
  put(url, data, config) {
    return this.realClient.put(url, data, {
      ...config
    });
  }
  delete(url, config) {
    return this.realClient.delete(url, config);
  }
  getDynamicHeaders(config) {
    const headers = {};
    if (this.dynamicHeaders) {
      Object.keys(this.dynamicHeaders).forEach((key) => {
        const value = this.dynamicHeaders[key].call(this, config);
        if (value) {
          headers[key] = value;
        }
      });
    }
    return headers;
  }
  handleReqHeader(config) {
    const dynamicHeaders = this.getDynamicHeaders(config);
    const oriHeaders = config.headers;
    config.headers = oriHeaders ? { ...oriHeaders, ...dynamicHeaders } : { ...dynamicHeaders };
    this.onRequestStart(config);
    return config;
  }
  handleBeforeRequest(config) {
    var _a;
    this.useTransformResponse = (_a = config.useTransformResponse) != null ? _a : "true";
    config = this.handleReqHeader(config);
    return config;
  }
  addReqInterceptor(fulfilled, rejected) {
    this.realClient.interceptors.request.use(fulfilled, rejected);
  }
  addResInterceptor(fulfilled, rejected) {
    this.realClient.interceptors.response.use(fulfilled, rejected);
  }
  handleResponse(rawRes) {
    if (!this.isResultOk(rawRes)) {
      this.onResponseError(rawRes);
    }
    this.onRequestComplete(rawRes);
    return this.transformResponse(rawRes);
  }
  isResultOk(rawRes) {
    return true;
  }
  transformResponse(rawRes) {
    if (this.useTransformResponse) {
      return rawRes.data;
    } else {
      return rawRes;
    }
  }
  onRequestStart(config) {
    if (this.logLevel > HttpLogLevel.NONE) {
      console.group(
        `%c [api] => ${config.method}@${config.url}`,
        "color:#9932cd;font-weight:bold;"
      );
      console.log(config.data);
      console.groupEnd();
    }
  }
  onRequestComplete(rawRes) {
    if (this.logLevel > HttpLogLevel.NONE) {
      console.group(
        `%c [api] <= ${rawRes.config.method}@${rawRes.config.url}`,
        "color:#2196F3;font-weight:bold;"
      );
      console.log(rawRes.data);
      console.groupEnd();
    }
  }
  log(tag, message, ...data) {
    console.info(`%c [${tag}] ${message}`, "color:#9932cd;font-weight:bold;", ...data);
  }
}
const Locale = {
  zh_CN: "zh_cn",
  en_US: "en",
  zh_TW: "zh_tw",
  ja_JP: "ja_jp"
};
const DEFAULT_LOCALE = Locale.zh_CN;
const RE_NARGS = /(%|)\{([0-9a-zA-Z_]+)\}/g;
function hasOwn(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}
class Intl {
  constructor(props = {}) {
    __publicField(this, "locale");
    __publicField(this, "messages");
    __publicField(this, "i18nHandler");
    this.locale = props.locale || DEFAULT_LOCALE;
    if (!props.messages) {
      throw new Error("no message defined");
    }
    this.messages = props.messages;
    this.i18nHandler = props.i18nHandler || this._defaultI18nHandler;
  }
  setI18nHandler(handler) {
    this.i18nHandler = handler || this._defaultI18nHandler;
  }
  setLocale(locale) {
    this.locale = locale || DEFAULT_LOCALE;
  }
  setMessages(messages) {
    this.messages = messages;
  }
  translate(path, options2) {
    return this.i18nHandler(path, options2);
  }
  _defaultI18nHandler(path, options2) {
    const array = path.split(".");
    const locale = (options2 == null ? void 0 : options2.locale) ? options2.locale : this.locale;
    let current = this.messages[locale];
    if (!current)
      return "";
    let value;
    for (let i2 = 0, j2 = array.length; i2 < j2; i2++) {
      const property = array[i2];
      value = current[property];
      if (!value)
        return path;
      if (i2 === j2 - 1)
        return this._template(value, options2);
      current = value;
    }
    return "";
  }
  _template(string, ...args) {
    let childArg = args;
    if (args.length === 1 && typeof args[0] === "object") {
      childArg = args[0];
    }
    if (!args || !args.hasOwnProperty) {
      childArg = {};
    }
    return string.replace(RE_NARGS, (match, prefix, i2, index) => {
      let result;
      if (string[index - 1] === "{" && string[index + match.length] === "}") {
        return i2;
      } else {
        result = hasOwn(childArg, i2) ? childArg[i2] : null;
        if (result === null || result === void 0) {
          return "";
        }
        return result;
      }
    });
  }
}
const LOCALE = Locale;
class StorageBase {
  isEmpty(key) {
    return this.get(key, null) === null;
  }
  deserialize(strVal, defaultValue) {
    if (!strVal) {
      return defaultValue != null ? defaultValue : null;
    }
    let val = null;
    try {
      val = JSON.parse(strVal);
    } catch (e) {
      return null;
    }
    return val !== void 0 ? val : defaultValue != null ? defaultValue : null;
  }
  serialize(data) {
    return JSON.stringify(data);
  }
}
var StorageType = /* @__PURE__ */ ((StorageType2) => {
  StorageType2["longTerm"] = "everlasting";
  StorageType2["temporary"] = "temporary";
  return StorageType2;
})(StorageType || {});
class AbstractBoDataProvider extends AbstractBoIdentifiableProvider {
  constructor(items) {
    super();
    __publicField(this, "items");
    this.items = items != null ? items : [];
  }
  setItems(items) {
    this.items = items || [];
  }
  getItems() {
    return this.items;
  }
  hasData() {
    return this.items.length > 0;
  }
  getKey(data) {
    if (data == null)
      return TwRTConst.ID_NULL;
    return this.internalGetKey(data);
  }
  internalGetKey(data) {
    return data.id;
  }
  getDefaultActiveItem() {
    var _a;
    return (_a = this.items) == null ? void 0 : _a[0];
  }
}
class AbstractBoLabelProvider {
}
class DefaultLabelProvider extends AbstractBoLabelProvider {
  getDescription(data) {
    return data.description;
  }
  getHint(data) {
    return data.hint;
  }
  getIcon(data) {
    return data.icon;
  }
  getLabel(data) {
    return data.name;
  }
}
class AbstractBoListDataProvider extends AbstractBoDataProvider {
  getItemByKey(key) {
    return this.items.find((item) => key === this.getKey(item));
  }
  getItemsByKeys(keys2) {
    return this.items.filter((item) => keys2.includes(this.getKey(item)));
  }
  getKeysByItems(items) {
    return this.items.map((item) => this.getKey(item));
  }
}
class DefaultBoListDataProvider extends AbstractBoListDataProvider {
}
class AbstractBoPerspectiveProvider extends AbstractBoIdentifiableProvider {
  constructor(items) {
    super();
    __publicField(this, "items");
    __publicField(this, "labelProvider");
    this.items = items;
    this.labelProvider = new DefaultLabelProvider();
  }
  getDefaultItems() {
    return [];
  }
  getItems() {
    return this.items.map((item) => this.convert2ViewModel(item));
  }
  getPerspectiveType(data) {
    return "list";
  }
  getLabel(data) {
    return this.labelProvider.getLabel(data);
  }
  getIcon(data) {
    return this.labelProvider.getIcon(data);
  }
  getDescription(data) {
    return this.labelProvider.getDescription(data);
  }
  getHint(data) {
    return this.labelProvider.getHint(data);
  }
  convert2ViewModel(data) {
    var _a;
    return {
      id: this.getKey(data),
      name: (_a = this.getLabel(data)) != null ? _a : "",
      icon: this.getIcon(data),
      type: this.getPerspectiveType(data)
    };
  }
}
class AbstractBoTreeDataProvider extends AbstractBoListDataProvider {
  constructor(items) {
    super(items || []);
    __publicField(this, "keyValues");
    this.keyValues = /* @__PURE__ */ new Map();
    this.buildKeyValues(items);
  }
  getName(data) {
    return data.title;
  }
  internalGetItems() {
    return this.items;
  }
  getChildren(data) {
    return data.children;
  }
  getItems() {
    return this.internalGetItems();
  }
  buildKeyValues(res) {
    this.reset();
    if (!res) {
      return;
    }
    const each = (data) => {
      data.forEach((item) => {
        const key = this.getKey(item);
        const children = this.getChildren(item);
        this.keyValues.set(key, item);
        if (children == null ? void 0 : children.length) {
          each(children);
        }
      });
    };
    each(res);
  }
  getItemByKey(key) {
    if (!this.keyValues.has(key)) {
      return void 0;
    }
    return this.keyValues.get(key);
  }
  getItemsByKeys(keys2) {
    const items = [];
    keys2.forEach((key) => {
      const item = this.getItemByKey(key);
      if (item) {
        items.push(item);
      }
    });
    return items;
  }
  reset() {
    this.keyValues.clear();
  }
  getDataListByKeys(keys2) {
    return [];
  }
  getKeysByDataList(dataList) {
    return [];
  }
  isSelectable(data) {
    if (isNil(data)) {
      return false;
    }
    return data.selectable;
  }
  hasChildren(data) {
    if (isNil(data)) {
      return false;
    }
    return !isEmpty$1(this.getChildren(data)) || data.isLeaf;
  }
  getDefaultActiveItem() {
    if (!this.items || !this.items.length) {
      return void 0;
    }
    const getFirstChild = (items) => {
      if (!(items == null ? void 0 : items.length)) {
        return void 0;
      }
      if (this.hasChildren(items[0])) {
        return getFirstChild(items[0].children);
      }
      return items[0];
    };
    return getFirstChild(this.items);
  }
}
class DefaultBoTreeDataProvider extends AbstractBoTreeDataProvider {
}
class AbstractBoFilterViewProvider extends AbstractBoIdentifiableProvider {
  constructor(filters) {
    super();
    __publicField(this, "filters");
    this.filters = filters || [];
  }
  getSortNo(data) {
    return 0;
  }
  getQuery(data) {
    return data.query;
  }
  setItems(filters) {
    this.filters = filters || [];
  }
  getItems() {
    return this.filters;
  }
  convert2FilterView(data) {
    var _a;
    const id = this.getKey(data);
    const name = (_a = this.getName(data)) != null ? _a : "";
    const icon = this.getIcon(data);
    const sortNo = this.getSortNo(data);
    const query = this.getQuery(data);
    return {
      id,
      name,
      icon,
      sortNo,
      query
    };
  }
  getDescription(data) {
    return data.description;
  }
  getHint(data) {
    return data.hint;
  }
  getLabel(data) {
    return data.name;
  }
}
class DefaultFilterViewProvider extends AbstractBoFilterViewProvider {
  getDefaultItem() {
    return this.getItems()[0];
  }
}
class BoMemoUtil {
  constructor() {
    __publicField(this, "oldValue");
    __publicField(this, "action");
    __publicField(this, "memo", []);
  }
  startRecord(action, initValue) {
    this.action = action;
    this.memo = [];
    if (!isNil(initValue)) {
      this.setOldValue(initValue);
    }
  }
  setOldValue(initValue) {
    this.oldValue = cloneDeep(initValue);
  }
  updateRecord(record) {
    Object.keys(record).forEach((key) => {
      this.updateField(key, record[key]);
    });
  }
  updateField(field, value) {
    this.updateMemo(field, value);
  }
  updateMemo(field, value) {
    var _a;
    if (this.action === "CREATE") {
      return;
    }
    const exits = this.isFieldMemoExits(field);
    if (!exits) {
      const actionType = isNil((_a = this.oldValue) == null ? void 0 : _a[field]) ? "CREATE" : "UPDATE";
      this.memo.push({
        attributeId: field,
        actionType,
        oldValue: this.getOldValueOf(field)
      });
    }
  }
  isFieldMemoExits(field) {
    return this.memo.some((memo) => memo.attributeId === field);
  }
  dumpMemo() {
    return {
      _actionType: this.action,
      _memo: {
        attributeMemo: this.memo
      }
    };
  }
  mergeValueMemo(value) {
    return {
      ...value,
      ...this.dumpMemo()
    };
  }
  getOldValueOf(field) {
    var _a;
    return (_a = this.oldValue) == null ? void 0 : _a[field];
  }
}
function isCommandGroup(bo) {
  return !!bo.commands;
}
function boListCommandStatusDecode(commands, bo) {
  if (!bo || !commands) {
    return commands;
  }
  const pipeItem = (command) => {
    const { disabledOn, hiddenOn, reactions, ...restProps } = command;
    const newCommand = clone(restProps);
    newCommand.hidden = hiddenOn == null ? void 0 : hiddenOn(bo);
    newCommand.disabled = disabledOn == null ? void 0 : disabledOn(bo);
    return newCommand;
  };
  const pipeArray = (commands2) => {
    return commands2.map((item) => pipeItem(item));
  };
  return commands.map((item) => {
    if (isCommandGroup(item)) {
      const { commands: commands2, ...restProps } = item;
      const newItem = {
        ...restProps,
        commands: []
      };
      newItem.commands = pipeArray(item.commands);
      return newItem;
    } else {
      return pipeItem(item);
    }
  });
}
function getRefStInfo(refEntity) {
  if (refEntity.refDoc) {
    return refEntity.refDoc;
  }
  if (refEntity.refDataview) {
    return refEntity.refDataview;
  }
  return refEntity.refBO;
}
function createRefService(refEntity) {
  return BfAppContext.getInstance().createStructureDataService(refEntity);
}
const RefEntityUtils = {
  getRefStInfo,
  createRefService
};
const DEBUG_KEY = "3will";
const DEFAULT_LOGIN = "/user/login";
const _BfAppContext = class {
  constructor() {
    __publicField(this, "services", {});
    __publicField(this, "stores", {});
    __publicField(this, "env", {});
    __publicField(this, "routePaths", {});
  }
  static getInstance() {
    return _BfAppContext.instance;
  }
  setAreaService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.areaService] = service;
  }
  getAreaService() {
    return this.acquireService(_BfAppContext.SERVICE_NAMES.areaService);
  }
  setEnumService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.enumService] = service;
  }
  getEnumService() {
    return this.acquireService(_BfAppContext.SERVICE_NAMES.enumService);
  }
  setApiService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.apiService] = service;
  }
  getApiService() {
    return this.acquireService(_BfAppContext.SERVICE_NAMES.apiService);
  }
  setStorageService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.storageService] = service;
  }
  getStorageService() {
    return this.acquireService(_BfAppContext.SERVICE_NAMES.storageService);
  }
  setMessageService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.messageService] = service;
  }
  getMessageService() {
    return this.acquireService(_BfAppContext.SERVICE_NAMES.messageService);
  }
  setPreferenceService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.preferenceService] = service;
  }
  getPreferenceService() {
    return this.acquireService(_BfAppContext.SERVICE_NAMES.preferenceService);
  }
  setFileService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.fileService] = service;
  }
  getFileService() {
    return this.acquireService(_BfAppContext.SERVICE_NAMES.fileService);
  }
  setRouteService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.routeService] = service;
  }
  getRouteService() {
    return this.acquireService(_BfAppContext.SERVICE_NAMES.routeService);
  }
  setAuthStorageService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.authStorageService] = service;
  }
  getAuthStorageService() {
    return this.acquireService(_BfAppContext.SERVICE_NAMES.authStorageService);
  }
  setUserService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.userService] = service;
  }
  getUserService() {
    return this.acquireService(_BfAppContext.SERVICE_NAMES.userService);
  }
  setMenuService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.menuService] = service;
  }
  getMenuService() {
    return this.acquireService(_BfAppContext.SERVICE_NAMES.menuService);
  }
  setBoStateService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.boStateService] = service;
  }
  getBoStateService() {
    return this.acquireService(_BfAppContext.SERVICE_NAMES.boStateService);
  }
  setStructureDataServiceFactory(factory) {
    this.services[_BfAppContext.SERVICE_NAMES.stDataServiceFactory] = factory;
  }
  setSystemInfoService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.systemInfoService] = service;
  }
  getSystemInfoService() {
    return this.services[_BfAppContext.SERVICE_NAMES.systemInfoService];
  }
  createStructureDataService(refEntity) {
    const factory = this.acquireService(
      _BfAppContext.SERVICE_NAMES.stDataServiceFactory
    );
    const apiService = this.getApiService();
    return factory(apiService, refEntity);
  }
  setBoAuditHistoryService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.boAuditHistoryService] = service;
  }
  getBoAuditHistoryService() {
    return this.services[_BfAppContext.SERVICE_NAMES.boAuditHistoryService];
  }
  setPageSchemaService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.pageSchemaService] = service;
  }
  getPageSchemaService() {
    return this.services[_BfAppContext.SERVICE_NAMES.pageSchemaService];
  }
  setMonitorService(service) {
    this.services[_BfAppContext.SERVICE_NAMES.monitorService] = service;
  }
  getMonitorService() {
    return this.services[_BfAppContext.SERVICE_NAMES.monitorService];
  }
  setAppStore(store) {
    this.stores[_BfAppContext.STORE_NAMES.appStore] = store;
  }
  getAppStore() {
    return this.acquireStore(_BfAppContext.STORE_NAMES.appStore);
  }
  setMenuStore(store) {
    this.stores[_BfAppContext.STORE_NAMES.menuStore] = store;
  }
  getMenuStore() {
    return this.acquireStore(_BfAppContext.STORE_NAMES.menuStore);
  }
  setUserStore(store) {
    this.stores[_BfAppContext.STORE_NAMES.userStore] = store;
  }
  getUserStore() {
    return this.acquireStore(_BfAppContext.STORE_NAMES.userStore);
  }
  setNoticeStore(store) {
    this.stores[_BfAppContext.STORE_NAMES.noticeStore] = store;
  }
  getNoticeStore() {
    return this.acquireStore(_BfAppContext.STORE_NAMES.noticeStore);
  }
  setPushStore(store) {
    this.stores[_BfAppContext.STORE_NAMES.pushStore] = store;
  }
  getPushStore() {
    return this.acquireStore(_BfAppContext.STORE_NAMES.pushStore);
  }
  setLoginStore(store) {
    this.stores[_BfAppContext.STORE_NAMES.loginStore] = store;
  }
  getLoginStore() {
    return this.acquireStore(_BfAppContext.STORE_NAMES.loginStore);
  }
  isDebug() {
    return this.env.isDebug;
  }
  isDemo() {
    return this.env.isDemo;
  }
  setDebug(debug) {
    this.env.isDebug = debug;
  }
  setDemo(demo) {
    this.env.isDemo = demo;
  }
  initialize() {
    const debugKey = localStorage.getItem("debug");
    this.env.isDebug = debugKey === DEBUG_KEY;
    this.setLoginPath(DEFAULT_LOGIN);
  }
  setLoginPath(path) {
    this.routePaths[_BfAppContext.ROUTE_PATHS.login] = path;
  }
  getLoginPath() {
    return this.acquirePath(_BfAppContext.ROUTE_PATHS.login);
  }
  acquireService(name) {
    const value = this.services[name];
    mustNotBeNull(value, `\u670D\u52A1${name}\u6CA1\u6709\u6CE8\u518C\u5230 BfAppContext`);
    return value;
  }
  acquireStore(name) {
    const value = this.stores[name];
    mustNotBeNull(value, `store ${name} \u6CA1\u6709\u6CE8\u518C\u5230 BfAppContext`);
    return value;
  }
  acquirePath(name) {
    const value = this.routePaths[name];
    mustNotBeNull(value, `path ${name} \u6CA1\u6709\u6CE8\u518C\u5230 BfAppContext`);
    return value;
  }
};
let BfAppContext = _BfAppContext;
__publicField(BfAppContext, "instance", new _BfAppContext());
__publicField(BfAppContext, "SERVICE_NAMES", {
  apiService: "apiService",
  storageService: "storageService",
  areaService: "areaService",
  enumService: "enumService",
  fileService: "fileService",
  randomService: "randomService",
  messageService: "messageService",
  routeService: "routeService",
  metaService: "metaService",
  authStorageService: "authStorage",
  menuService: "menuService",
  userService: "userService",
  pushService: "pushService",
  stDataServiceFactory: "stDataServiceFactory",
  boStateService: "boStateService",
  monitorService: "monitorService",
  systemInfoService: "systemInfoService",
  boAuditHistoryService: "boAuditHistoryService",
  pageSchemaService: "pageSchemaService",
  preferenceService: "preferenceService"
});
__publicField(BfAppContext, "STORE_NAMES", {
  appStore: "appStore",
  userStore: "userStore",
  menuStore: "menuStore",
  noticeStore: "noticeStore",
  pushStore: "pushStore",
  loginStore: "loginStore"
});
__publicField(BfAppContext, "ROUTE_PATHS", {
  login: "login"
});
function createEnumService(enumDes) {
  const enumService = BfAppContext.getInstance().getEnumService();
  return {
    query: (params) => enumService.getEnumOptions(enumDes.id)
  };
}
function newEnumValue(code) {
  return {
    code
  };
}
const EnumUtils = {
  createEnumService,
  newEnumValue
};
const newRuleCondition = (field, value, rule, matchType) => {
  const isValueNil = isNull(value) || isUndefined(value);
  return {
    field,
    rule,
    value: isValueNil ? void 0 : {
      type: "value",
      value
    },
    matchType
  };
};
const newEqualCondition = (field, value, matchType) => {
  return newRuleCondition(field, value, ConditionRuleDTO.EQ, matchType);
};
const newLikeCondition = (field, value, matchType) => {
  return newRuleCondition(field, value, ConditionRuleDTO.LIKE, matchType);
};
const newSubCondition = (subCondition, matchType) => {
  return {
    subCondition,
    matchType
  };
};
const updateCondition = (conditions, config) => {
  if (!config || !config.field) {
    return conditions;
  }
  const { field, appendIfNotExits } = config;
  conditions = conditions || [];
  let newConditions = conditions.slice(0);
  const index = findConditionIndexByField(conditions, field);
  if (index > -1) {
    if (config.remove) {
      newConditions = conditions.filter((cond) => cond.field !== config.field);
    } else {
      newConditions[index] = {
        ...newConditions[index],
        ...config.condition
      };
    }
  } else {
    if (appendIfNotExits) {
      newConditions.push({
        field,
        ...config.condition
      });
    }
  }
  return newConditions;
};
const ifConditionExits = (conditions, field) => {
  if (!conditions) {
    return false;
  }
  return !!findConditionByField(conditions, field);
};
const findConditionByField = (conditions, field) => {
  if (!conditions) {
    return void 0;
  }
  return conditions.find((cond) => cond.field === field);
};
const findConditionIndexByField = (conditions, field) => {
  var _a;
  return (_a = conditions == null ? void 0 : conditions.findIndex((cond) => cond.field === field)) != null ? _a : -1;
};
const queryConditionsByField = (conditions, field) => {
  return conditions == null ? void 0 : conditions.filter((cond) => cond.field === field);
};
function newConditionFromAttribute(id, type, value) {
  mustNotBeNull(id, "id \u4E0D\u80FD\u4E3A\u7A7A");
  mustNotBeNull(value, "Condition\u7684\u503C\u4E0D\u80FD\u4E3A\u7A7A");
  mustNotBeNull(type, "\u751F\u6210condition\u5931\u8D25,\u7C7B\u578B\u4E0D\u80FD\u4E3A\u7A7A");
  console.log("newConditionFromAttribute==", id, type, value);
  if (type === "reference") {
    return newEqualCondition(id, unboxBoValueId(value));
  }
  if (type === "enumValue") {
    return newEqualCondition(id, unboxBoValueValue(value));
  }
  if (type === "text" || type === "richText") {
    return newLikeCondition(id, value);
  }
  if (type === "dateTime") {
    if (isArray$1(value) && value.length > 1) {
      return newSubCondition([
        newRuleCondition(id, value[0], ConditionRuleDTO.GE),
        newRuleCondition(id, value[1], ConditionRuleDTO.LE)
      ]);
    }
  }
  return newEqualCondition(id, value);
}
function unboxBoValueId(value) {
  if (isPlainObject(value)) {
    return value.id;
  }
  return value;
}
function unboxBoValueValue(value) {
  if (isPlainObject(value)) {
    return value.value;
  }
  return value;
}
const ConditionDtoUtils = {
  newRuleCondition,
  newEqualCondition,
  newLikeCondition,
  newSubCondition,
  updateCondition,
  ifConditionExits,
  findConditionByField,
  findConditionIndexByField,
  filterConditionsByField: queryConditionsByField,
  newConditionFromAttribute,
  unboxBoValueId,
  unboxBoValueValue
};
const newSortAsc = (field) => {
  return newRuleSort(field, SortTypeDTO.ASC);
};
const newSortDesc = (field) => {
  return newRuleSort(field, SortTypeDTO.DESC);
};
const newRuleSort = (field, sortType) => {
  return {
    field,
    sortType
  };
};
function structureMetaFill(structureMeta, qnamePrefix = "bo") {
  if (structureMeta.domain && structureMeta.qname && structureMeta.id) {
    return structureMeta;
  }
  if ((!structureMeta.domain || !structureMeta.id) && structureMeta.qname) {
    mustNotBeNull(structureMeta.qname);
    const result = /bo:\/\/(\w+)\/(\w+)/.exec(structureMeta.qname);
    mustNotBeNull(result, "StructureName \u4E0D\u7B26\u5408\u89C4\u8303\u8981\u6C42");
    const domain = result[1];
    const id = result[2];
    return {
      domain,
      id,
      qname: structureMeta.qname
    };
  }
  if (structureMeta.domain && structureMeta.id && !structureMeta.qname) {
    const qname = `${qnamePrefix}://${structureMeta.domain}/${structureMeta.id}`;
    return {
      domain: structureMeta.domain,
      id: structureMeta.id,
      qname
    };
  }
  throw new Error("\u89E3\u6790StructureMeta\u5931\u8D25, \u4E0D\u7B26\u5408\u683C\u5F0F\u8981\u6C42" + JSON.stringify(structureMeta));
}
function getQNameSchema(qname) {
  if (!qname) {
    return qname;
  }
  const result = /^(\w+):\/\//.exec(qname);
  return result == null ? void 0 : result[1];
}
const StructureMetaUtils = {
  fill: structureMetaFill,
  getQNameSchema
};
class AbstractBfStatePlugin {
  constructor(stateApi) {
    __publicField(this, "stateApi");
    this.stateApi = stateApi;
  }
  afterSetState(state, replace, action) {
  }
  beforeSetState(state, replace, action) {
    var _a;
    return (_a = this.stateApi) == null ? void 0 : _a.setState(state, replace);
  }
  destroy() {
    this.stateApi = void 0;
  }
  getState() {
    var _a;
    return (_a = this.stateApi) == null ? void 0 : _a.getState();
  }
}
class BfStatePluginDevtools extends AbstractBfStatePlugin {
  constructor(state, options2) {
    super(state);
    __publicField(this, "extension");
    __publicField(this, "options");
    __publicField(this, "initialState");
    __publicField(this, "isRecording", true);
    this.options = options2;
  }
  onInit() {
    this.initDevTools(this.options);
  }
  initDevTools(options2) {
    mustNotBeNull(this.stateApi, "store api \u521D\u59CB\u5316\u4E0D\u6B63\u786E");
    const connector = window["__REDUX_DEVTOOLS_EXTENSION__"];
    if (!connector) {
      console.log("redux \u8C03\u8BD5\u5DE5\u5177\u8FDE\u63A5\u5F02\u5E38,\u8BF7\u5B89\u88C5\u5E76\u542F\u7528");
      return;
    }
    const connectOptions = isNil(options2) || isNil(options2.name) ? {} : {
      name: options2 == null ? void 0 : options2.name
    };
    this.extension = connector.connect(connectOptions);
    if (!this.extension) {
      console.log("redux \u8C03\u8BD5\u5DE5\u5177\u8FDE\u63A5\u5F02\u5E38, \u8BF7\u68C0\u67E5\u53C2\u6570");
      return;
    }
    this.initialState = cloneDeep(this.stateApi.getState());
    this.extension.init(this.initialState);
    this.extension.subscribe((message) => this.handleDevToolsAction(message));
  }
  handleDevToolsAction(message) {
    var _a;
    switch (message.type) {
      case "ACTION":
        if (typeof message.payload !== "string") {
          console.error("Unsupported action format");
          return;
        }
        break;
      case "DISPATCH":
        switch (message.payload.type) {
          case "RESET":
            console.log("RESET");
            this.extension.init(cloneDeep(this.initialState));
            break;
          case "COMMIT":
            console.log("COMMIT");
            this.extension.init((_a = this.stateApi) == null ? void 0 : _a.getState());
            break;
          case "ROLLBACK":
            this.handleRollback(message);
            break;
          case "JUMP_TO_STATE":
          case "JUMP_TO_ACTION":
            this.handleJumpTo(message);
            break;
          case "IMPORT_STATE":
            this.handleImportState(message);
            break;
          case "PAUSE_RECORDING":
            this.isRecording = !this.isRecording;
            break;
        }
    }
  }
  handleRollback(message) {
    var _a;
    try {
      const state = JSON.parse(message.state);
      (_a = this.stateApi) == null ? void 0 : _a.setState(state);
      this.extension.init(state);
    } catch (e) {
      console.error(e);
    }
  }
  handleJumpTo(message) {
    var _a;
    try {
      const state = JSON.parse(message.state);
      (_a = this.stateApi) == null ? void 0 : _a.setState(state);
    } catch (e) {
      console.error(e);
    }
  }
  handleImportState(message) {
    var _a, _b;
    const { nextLiftedState } = message.payload;
    const lastComputedState = (_a = nextLiftedState.computedStates.slice(-1)[0]) == null ? void 0 : _a.state;
    if (!lastComputedState)
      return;
    (_b = this.stateApi) == null ? void 0 : _b.setState(lastComputedState);
    this.extension.send(
      null,
      nextLiftedState
    );
  }
  beforeSetState(state, replace, action) {
  }
  afterSetState(state, replace, action) {
    var _a;
    if (!this.stateApi) {
      console.error("store api \u521D\u59CB\u5316\u4E0D\u6B63\u786E");
      return;
    }
    const type = action ? action : "setState";
    const payload = isFunction(state) ? "function" : state;
    const actionObject = {
      type,
      payload
    };
    const result = this.stateApi.getState();
    if (this.isRecording) {
      (_a = this.extension) == null ? void 0 : _a.send(actionObject, result);
    }
    return result;
  }
  destroy() {
    super.destroy();
    if (this.extension) {
      this.extension.unsubscribe();
    }
  }
}
function n(n2) {
  for (var r2 = arguments.length, t2 = Array(r2 > 1 ? r2 - 1 : 0), e = 1; e < r2; e++)
    t2[e - 1] = arguments[e];
  if ("production" !== process.env.NODE_ENV) {
    var i2 = Y[n2], o2 = i2 ? "function" == typeof i2 ? i2.apply(null, t2) : i2 : "unknown error nr: " + n2;
    throw Error("[Immer] " + o2);
  }
  throw Error("[Immer] minified error nr: " + n2 + (t2.length ? " " + t2.map(function(n3) {
    return "'" + n3 + "'";
  }).join(",") : "") + ". Find the full error at: https://bit.ly/3cXEKWf");
}
function r(n2) {
  return !!n2 && !!n2[Q];
}
function t(n2) {
  return !!n2 && (function(n3) {
    if (!n3 || "object" != typeof n3)
      return false;
    var r2 = Object.getPrototypeOf(n3);
    if (null === r2)
      return true;
    var t2 = Object.hasOwnProperty.call(r2, "constructor") && r2.constructor;
    return t2 === Object || "function" == typeof t2 && Function.toString.call(t2) === Z;
  }(n2) || Array.isArray(n2) || !!n2[L] || !!n2.constructor[L] || s(n2) || v(n2));
}
function i(n2, r2, t2) {
  void 0 === t2 && (t2 = false), 0 === o(n2) ? (t2 ? Object.keys : nn)(n2).forEach(function(e) {
    t2 && "symbol" == typeof e || r2(e, n2[e], n2);
  }) : n2.forEach(function(t3, e) {
    return r2(e, t3, n2);
  });
}
function o(n2) {
  var r2 = n2[Q];
  return r2 ? r2.i > 3 ? r2.i - 4 : r2.i : Array.isArray(n2) ? 1 : s(n2) ? 2 : v(n2) ? 3 : 0;
}
function u(n2, r2) {
  return 2 === o(n2) ? n2.has(r2) : Object.prototype.hasOwnProperty.call(n2, r2);
}
function a(n2, r2) {
  return 2 === o(n2) ? n2.get(r2) : n2[r2];
}
function f(n2, r2, t2) {
  var e = o(n2);
  2 === e ? n2.set(r2, t2) : 3 === e ? (n2.delete(r2), n2.add(t2)) : n2[r2] = t2;
}
function c(n2, r2) {
  return n2 === r2 ? 0 !== n2 || 1 / n2 == 1 / r2 : n2 != n2 && r2 != r2;
}
function s(n2) {
  return X && n2 instanceof Map;
}
function v(n2) {
  return q && n2 instanceof Set;
}
function p(n2) {
  return n2.o || n2.t;
}
function l(n2) {
  if (Array.isArray(n2))
    return Array.prototype.slice.call(n2);
  var r2 = rn(n2);
  delete r2[Q];
  for (var t2 = nn(r2), e = 0; e < t2.length; e++) {
    var i2 = t2[e], o2 = r2[i2];
    false === o2.writable && (o2.writable = true, o2.configurable = true), (o2.get || o2.set) && (r2[i2] = { configurable: true, writable: true, enumerable: o2.enumerable, value: n2[i2] });
  }
  return Object.create(Object.getPrototypeOf(n2), r2);
}
function d(n2, e) {
  return void 0 === e && (e = false), y(n2) || r(n2) || !t(n2) ? n2 : (o(n2) > 1 && (n2.set = n2.add = n2.clear = n2.delete = h), Object.freeze(n2), e && i(n2, function(n3, r2) {
    return d(r2, true);
  }, true), n2);
}
function h() {
  n(2);
}
function y(n2) {
  return null == n2 || "object" != typeof n2 || Object.isFrozen(n2);
}
function b(r2) {
  var t2 = tn[r2];
  return t2 || n(18, r2), t2;
}
function _() {
  return "production" === process.env.NODE_ENV || U || n(0), U;
}
function j(n2, r2) {
  r2 && (b("Patches"), n2.u = [], n2.s = [], n2.v = r2);
}
function O(n2) {
  g(n2), n2.p.forEach(S), n2.p = null;
}
function g(n2) {
  n2 === U && (U = n2.l);
}
function w(n2) {
  return U = { p: [], l: U, h: n2, m: true, _: 0 };
}
function S(n2) {
  var r2 = n2[Q];
  0 === r2.i || 1 === r2.i ? r2.j() : r2.O = true;
}
function P(r2, e) {
  e._ = e.p.length;
  var i2 = e.p[0], o2 = void 0 !== r2 && r2 !== i2;
  return e.h.g || b("ES5").S(e, r2, o2), o2 ? (i2[Q].P && (O(e), n(4)), t(r2) && (r2 = M(e, r2), e.l || x(e, r2)), e.u && b("Patches").M(i2[Q].t, r2, e.u, e.s)) : r2 = M(e, i2, []), O(e), e.u && e.v(e.u, e.s), r2 !== H ? r2 : void 0;
}
function M(n2, r2, t2) {
  if (y(r2))
    return r2;
  var e = r2[Q];
  if (!e)
    return i(r2, function(i2, o3) {
      return A(n2, e, r2, i2, o3, t2);
    }, true), r2;
  if (e.A !== n2)
    return r2;
  if (!e.P)
    return x(n2, e.t, true), e.t;
  if (!e.I) {
    e.I = true, e.A._--;
    var o2 = 4 === e.i || 5 === e.i ? e.o = l(e.k) : e.o;
    i(3 === e.i ? new Set(o2) : o2, function(r3, i2) {
      return A(n2, e, o2, r3, i2, t2);
    }), x(n2, o2, false), t2 && n2.u && b("Patches").R(e, t2, n2.u, n2.s);
  }
  return e.o;
}
function A(e, i2, o2, a2, c2, s2) {
  if ("production" !== process.env.NODE_ENV && c2 === o2 && n(5), r(c2)) {
    var v2 = M(e, c2, s2 && i2 && 3 !== i2.i && !u(i2.D, a2) ? s2.concat(a2) : void 0);
    if (f(o2, a2, v2), !r(v2))
      return;
    e.m = false;
  }
  if (t(c2) && !y(c2)) {
    if (!e.h.F && e._ < 1)
      return;
    M(e, c2), i2 && i2.A.l || x(e, c2);
  }
}
function x(n2, r2, t2) {
  void 0 === t2 && (t2 = false), n2.h.F && n2.m && d(r2, t2);
}
function z(n2, r2) {
  var t2 = n2[Q];
  return (t2 ? p(t2) : n2)[r2];
}
function I(n2, r2) {
  if (r2 in n2)
    for (var t2 = Object.getPrototypeOf(n2); t2; ) {
      var e = Object.getOwnPropertyDescriptor(t2, r2);
      if (e)
        return e;
      t2 = Object.getPrototypeOf(t2);
    }
}
function k(n2) {
  n2.P || (n2.P = true, n2.l && k(n2.l));
}
function E(n2) {
  n2.o || (n2.o = l(n2.t));
}
function R(n2, r2, t2) {
  var e = s(r2) ? b("MapSet").N(r2, t2) : v(r2) ? b("MapSet").T(r2, t2) : n2.g ? function(n3, r3) {
    var t3 = Array.isArray(n3), e2 = { i: t3 ? 1 : 0, A: r3 ? r3.A : _(), P: false, I: false, D: {}, l: r3, t: n3, k: null, o: null, j: null, C: false }, i2 = e2, o2 = en;
    t3 && (i2 = [e2], o2 = on);
    var u2 = Proxy.revocable(i2, o2), a2 = u2.revoke, f2 = u2.proxy;
    return e2.k = f2, e2.j = a2, f2;
  }(r2, t2) : b("ES5").J(r2, t2);
  return (t2 ? t2.A : _()).p.push(e), e;
}
function D(e) {
  return r(e) || n(22, e), function n2(r2) {
    if (!t(r2))
      return r2;
    var e2, u2 = r2[Q], c2 = o(r2);
    if (u2) {
      if (!u2.P && (u2.i < 4 || !b("ES5").K(u2)))
        return u2.t;
      u2.I = true, e2 = F(r2, c2), u2.I = false;
    } else
      e2 = F(r2, c2);
    return i(e2, function(r3, t2) {
      u2 && a(u2.t, r3) === t2 || f(e2, r3, n2(t2));
    }), 3 === c2 ? new Set(e2) : e2;
  }(e);
}
function F(n2, r2) {
  switch (r2) {
    case 2:
      return new Map(n2);
    case 3:
      return Array.from(n2);
  }
  return l(n2);
}
var G, U, W = "undefined" != typeof Symbol && "symbol" == typeof Symbol("x"), X = "undefined" != typeof Map, q = "undefined" != typeof Set, B = "undefined" != typeof Proxy && void 0 !== Proxy.revocable && "undefined" != typeof Reflect, H = W ? Symbol.for("immer-nothing") : ((G = {})["immer-nothing"] = true, G), L = W ? Symbol.for("immer-draftable") : "__$immer_draftable", Q = W ? Symbol.for("immer-state") : "__$immer_state", Y = { 0: "Illegal state", 1: "Immer drafts cannot have computed properties", 2: "This object has been frozen and should not be mutated", 3: function(n2) {
  return "Cannot use a proxy that has been revoked. Did you pass an object from inside an immer function to an async process? " + n2;
}, 4: "An immer producer returned a new value *and* modified its draft. Either return a new value *or* modify the draft.", 5: "Immer forbids circular references", 6: "The first or second argument to `produce` must be a function", 7: "The third argument to `produce` must be a function or undefined", 8: "First argument to `createDraft` must be a plain object, an array, or an immerable object", 9: "First argument to `finishDraft` must be a draft returned by `createDraft`", 10: "The given draft is already finalized", 11: "Object.defineProperty() cannot be used on an Immer draft", 12: "Object.setPrototypeOf() cannot be used on an Immer draft", 13: "Immer only supports deleting array indices", 14: "Immer only supports setting array indices and the 'length' property", 15: function(n2) {
  return "Cannot apply patch, path doesn't resolve: " + n2;
}, 16: 'Sets cannot have "replace" patches.', 17: function(n2) {
  return "Unsupported patch operation: " + n2;
}, 18: function(n2) {
  return "The plugin for '" + n2 + "' has not been loaded into Immer. To enable the plugin, import and call `enable" + n2 + "()` when initializing your application.";
}, 20: "Cannot use proxies if Proxy, Proxy.revocable or Reflect are not available", 21: function(n2) {
  return "produce can only be called on things that are draftable: plain objects, arrays, Map, Set or classes that are marked with '[immerable]: true'. Got '" + n2 + "'";
}, 22: function(n2) {
  return "'current' expects a draft, got: " + n2;
}, 23: function(n2) {
  return "'original' expects a draft, got: " + n2;
}, 24: "Patching reserved attributes like __proto__, prototype and constructor is not allowed" }, Z = "" + Object.prototype.constructor, nn = "undefined" != typeof Reflect && Reflect.ownKeys ? Reflect.ownKeys : void 0 !== Object.getOwnPropertySymbols ? function(n2) {
  return Object.getOwnPropertyNames(n2).concat(Object.getOwnPropertySymbols(n2));
} : Object.getOwnPropertyNames, rn = Object.getOwnPropertyDescriptors || function(n2) {
  var r2 = {};
  return nn(n2).forEach(function(t2) {
    r2[t2] = Object.getOwnPropertyDescriptor(n2, t2);
  }), r2;
}, tn = {}, en = { get: function(n2, r2) {
  if (r2 === Q)
    return n2;
  var e = p(n2);
  if (!u(e, r2))
    return function(n3, r3, t2) {
      var e2, i3 = I(r3, t2);
      return i3 ? "value" in i3 ? i3.value : null === (e2 = i3.get) || void 0 === e2 ? void 0 : e2.call(n3.k) : void 0;
    }(n2, e, r2);
  var i2 = e[r2];
  return n2.I || !t(i2) ? i2 : i2 === z(n2.t, r2) ? (E(n2), n2.o[r2] = R(n2.A.h, i2, n2)) : i2;
}, has: function(n2, r2) {
  return r2 in p(n2);
}, ownKeys: function(n2) {
  return Reflect.ownKeys(p(n2));
}, set: function(n2, r2, t2) {
  var e = I(p(n2), r2);
  if (null == e ? void 0 : e.set)
    return e.set.call(n2.k, t2), true;
  if (!n2.P) {
    var i2 = z(p(n2), r2), o2 = null == i2 ? void 0 : i2[Q];
    if (o2 && o2.t === t2)
      return n2.o[r2] = t2, n2.D[r2] = false, true;
    if (c(t2, i2) && (void 0 !== t2 || u(n2.t, r2)))
      return true;
    E(n2), k(n2);
  }
  return n2.o[r2] === t2 && "number" != typeof t2 && (void 0 !== t2 || r2 in n2.o) || (n2.o[r2] = t2, n2.D[r2] = true, true);
}, deleteProperty: function(n2, r2) {
  return void 0 !== z(n2.t, r2) || r2 in n2.t ? (n2.D[r2] = false, E(n2), k(n2)) : delete n2.D[r2], n2.o && delete n2.o[r2], true;
}, getOwnPropertyDescriptor: function(n2, r2) {
  var t2 = p(n2), e = Reflect.getOwnPropertyDescriptor(t2, r2);
  return e ? { writable: true, configurable: 1 !== n2.i || "length" !== r2, enumerable: e.enumerable, value: t2[r2] } : e;
}, defineProperty: function() {
  n(11);
}, getPrototypeOf: function(n2) {
  return Object.getPrototypeOf(n2.t);
}, setPrototypeOf: function() {
  n(12);
} }, on = {};
i(en, function(n2, r2) {
  on[n2] = function() {
    return arguments[0] = arguments[0][0], r2.apply(this, arguments);
  };
}), on.deleteProperty = function(r2, t2) {
  return "production" !== process.env.NODE_ENV && isNaN(parseInt(t2)) && n(13), on.set.call(this, r2, t2, void 0);
}, on.set = function(r2, t2, e) {
  return "production" !== process.env.NODE_ENV && "length" !== t2 && isNaN(parseInt(t2)) && n(14), en.set.call(this, r2[0], t2, e, r2[0]);
};
var un = function() {
  function e(r2) {
    var e2 = this;
    this.g = B, this.F = true, this.produce = function(r3, i3, o2) {
      if ("function" == typeof r3 && "function" != typeof i3) {
        var u2 = i3;
        i3 = r3;
        var a2 = e2;
        return function(n2) {
          var r4 = this;
          void 0 === n2 && (n2 = u2);
          for (var t2 = arguments.length, e3 = Array(t2 > 1 ? t2 - 1 : 0), o3 = 1; o3 < t2; o3++)
            e3[o3 - 1] = arguments[o3];
          return a2.produce(n2, function(n3) {
            var t3;
            return (t3 = i3).call.apply(t3, [r4, n3].concat(e3));
          });
        };
      }
      var f2;
      if ("function" != typeof i3 && n(6), void 0 !== o2 && "function" != typeof o2 && n(7), t(r3)) {
        var c2 = w(e2), s2 = R(e2, r3, void 0), v2 = true;
        try {
          f2 = i3(s2), v2 = false;
        } finally {
          v2 ? O(c2) : g(c2);
        }
        return "undefined" != typeof Promise && f2 instanceof Promise ? f2.then(function(n2) {
          return j(c2, o2), P(n2, c2);
        }, function(n2) {
          throw O(c2), n2;
        }) : (j(c2, o2), P(f2, c2));
      }
      if (!r3 || "object" != typeof r3) {
        if (void 0 === (f2 = i3(r3)) && (f2 = r3), f2 === H && (f2 = void 0), e2.F && d(f2, true), o2) {
          var p2 = [], l2 = [];
          b("Patches").M(r3, f2, p2, l2), o2(p2, l2);
        }
        return f2;
      }
      n(21, r3);
    }, this.produceWithPatches = function(n2, r3) {
      if ("function" == typeof n2)
        return function(r4) {
          for (var t3 = arguments.length, i4 = Array(t3 > 1 ? t3 - 1 : 0), o3 = 1; o3 < t3; o3++)
            i4[o3 - 1] = arguments[o3];
          return e2.produceWithPatches(r4, function(r5) {
            return n2.apply(void 0, [r5].concat(i4));
          });
        };
      var t2, i3, o2 = e2.produce(n2, r3, function(n3, r4) {
        t2 = n3, i3 = r4;
      });
      return "undefined" != typeof Promise && o2 instanceof Promise ? o2.then(function(n3) {
        return [n3, t2, i3];
      }) : [o2, t2, i3];
    }, "boolean" == typeof (null == r2 ? void 0 : r2.useProxies) && this.setUseProxies(r2.useProxies), "boolean" == typeof (null == r2 ? void 0 : r2.autoFreeze) && this.setAutoFreeze(r2.autoFreeze);
  }
  var i2 = e.prototype;
  return i2.createDraft = function(e2) {
    t(e2) || n(8), r(e2) && (e2 = D(e2));
    var i3 = w(this), o2 = R(this, e2, void 0);
    return o2[Q].C = true, g(i3), o2;
  }, i2.finishDraft = function(r2, t2) {
    var e2 = r2 && r2[Q];
    "production" !== process.env.NODE_ENV && (e2 && e2.C || n(9), e2.I && n(10));
    var i3 = e2.A;
    return j(i3, t2), P(void 0, i3);
  }, i2.setAutoFreeze = function(n2) {
    this.F = n2;
  }, i2.setUseProxies = function(r2) {
    r2 && !B && n(20), this.g = r2;
  }, i2.applyPatches = function(n2, t2) {
    var e2;
    for (e2 = t2.length - 1; e2 >= 0; e2--) {
      var i3 = t2[e2];
      if (0 === i3.path.length && "replace" === i3.op) {
        n2 = i3.value;
        break;
      }
    }
    e2 > -1 && (t2 = t2.slice(e2 + 1));
    var o2 = b("Patches").$;
    return r(n2) ? o2(n2, t2) : this.produce(n2, function(n3) {
      return o2(n3, t2);
    });
  }, e;
}(), an = new un(), fn = an.produce;
an.produceWithPatches.bind(an);
var sn = an.setAutoFreeze.bind(an), vn = an.setUseProxies.bind(an);
an.applyPatches.bind(an);
an.createDraft.bind(an);
an.finishDraft.bind(an);
class BfStatePluginImmer extends AbstractBfStatePlugin {
  onInit() {
    var _a;
    if (!this.stateApi) {
      console.error("stateApi \u4E3A\u7A7A, \u63D2\u4EF6\u521D\u59CB\u5316\u5931\u8D25");
      return;
    }
    vn(true);
    sn(false);
    const oldSetState = (_a = this.stateApi) == null ? void 0 : _a.setState;
    this.stateApi.setState = (state, replace) => {
      const result = this.getStateByImmer(state);
      return oldSetState(result, replace);
    };
  }
  getStateByImmer(state) {
    return isFunction(state) ? fn(state) : state;
  }
}
class BfStatePluginCommon {
  static createPlugins(api, options2) {
    const basePlugins = [new BfStatePluginImmer(api)];
    if (!BfAppContext.getInstance().isDebug()) {
      return basePlugins;
    }
    if ((options2 == null ? void 0 : options2.devTools) === null) {
      return basePlugins;
    }
    basePlugins.push(new BfStatePluginDevtools(api, options2 == null ? void 0 : options2.devTools));
    return basePlugins;
  }
}
class BfStates {
  constructor(state, options2) {
    __publicField(this, "state");
    __publicField(this, "listeners", /* @__PURE__ */ new Set());
    __publicField(this, "plugins", /* @__PURE__ */ new Set());
    __publicField(this, "isDied", false);
    this.state = state;
    const api = {
      setState: this.internalSetState.bind(this),
      getState: this.internalGetState.bind(this)
    };
    const plugins = BfStatePluginCommon.createPlugins(api, options2);
    plugins.forEach((plugin) => {
      this.addPlugins(plugin);
    });
  }
  setState(partial2, replace, action) {
    if (this.isDied) {
      console.error("\u72B6\u6001\u5DF2\u7ECF\u9500\u6BC1\uFF0C\u65E0\u6CD5\u518D\u8C03\u7528 setState");
      return {};
    }
    let lastState = partial2;
    this.plugins.forEach((plugin) => {
      lastState = plugin.beforeSetState(partial2, replace, action);
      this.internalSetState(lastState, replace);
      plugin.afterSetState(partial2, replace, action);
    });
    return lastState;
  }
  internalSetState(partial2, replace) {
    const nextState = typeof partial2 === "function" ? partial2(this.state) : partial2;
    if (nextState !== this.state) {
      const previousState = this.state;
      this.state = replace ? nextState : Object.assign({}, this.state, nextState);
      this.listeners.forEach((listener) => listener(this.state, previousState));
    }
    return this.state;
  }
  subscribe(listener) {
    if (isNil(listener)) {
      throwRuntimeError(TwRTErrorCodes.ILLEGAL_ARGUMENTS);
    }
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }
  addPlugins(plugin) {
    mustNotBeNull(plugin, "\u63D2\u4EF6\u4E0D\u80FD\u4E3A\u7A7A");
    this.plugins.add(plugin);
    plugin.onInit();
  }
  removePlugin(plugin) {
    plugin.destroy();
    this.plugins.delete(plugin);
  }
  removeAllPlugins() {
    this.plugins.forEach((plugin) => {
      plugin.destroy();
    });
    this.plugins.clear();
  }
  getState() {
    return this.internalGetState();
  }
  internalGetState() {
    return this.state;
  }
  destroy() {
    this.isDied = true;
    this.listeners.clear();
    this.removeAllPlugins();
  }
}
class BfObjectRef {
  constructor(value) {
    __publicField(this, "value");
    this.value = value;
  }
  static newInstance(value) {
    return new BfObjectRef(value);
  }
  getValue() {
    return this.value;
  }
}
class BfStore {
  constructor(context) {
    __publicField(this, "states");
    __publicField(this, "logger");
    __publicField(this, "slices");
    this.slices = this.buildSlices();
    this.states = new BfStates(this.getInitialState(), context.pluginOptions);
    this.logger = this.createLogger();
  }
  buildSlices() {
    return {};
  }
  getInitialState() {
    const result = {};
    Object.keys(this.slices).forEach((key) => {
      const slice = this.slices[key];
      slice.setKey(key);
      result[key] = slice.getInitialState();
    });
    return result;
  }
  setState(partial, replace, action) {
    return this.states.setState(partial, replace, action);
  }
  getState() {
    return this.states.getState();
  }
  getLogger() {
    return this.logger;
  }
  getSlices() {
    return this.slices;
  }
  subscribeState(listener) {
    return this.states.subscribe(listener);
  }
  createLogger() {
    return BfLog4js.getLogger("store");
  }
  destroy() {
    this.states.destroy();
  }
}
class BfSlice {
  constructor(store) {
    __publicField(this, "key");
    __publicField(this, "store");
    this.store = store;
  }
  getKey() {
    return this.key;
  }
  setKey(key) {
    this.key = key;
  }
  setState(state, replace, action) {
    const updateState = (rootState, key, replace2, state2) => {
      if (isFunction(state2)) {
        rootState[key] = state2(rootState[key]);
      } else {
        if (replace2) {
          rootState[this.key] = state2;
        } else {
          rootState[this.key] = rootState[this.key] || {};
          Object.assign(rootState[this.key], state2);
        }
      }
    };
    this.store.setState(
      (rootState) => {
        updateState(rootState, this.key, replace, state);
        return rootState;
      },
      false,
      action
    );
  }
  getState() {
    return this.store.getState()[this.key];
  }
  getLogger() {
    return this.store.getLogger();
  }
  subscribeState(listener) {
    return this.store.subscribeState((pCurState, pPreState) => {
      const preState = pPreState[this.key];
      const curState = pCurState[this.key];
      if (pCurState === curState) {
        return;
      }
      listener(curState, preState);
    });
  }
}
const pageEvents = /* @__PURE__ */ new Map();
let currentIndex = 0;
class BfPageChannels {
  static addChannel() {
    currentIndex++;
    const event = new BfPageChannel(currentIndex);
    pageEvents.set(currentIndex, event);
    return event;
  }
  static removeChannel(chanelId) {
    pageEvents.delete(chanelId);
  }
  static getChannel(channelId) {
    return pageEvents.get(channelId);
  }
}
class BfPageChannel {
  constructor(id) {
    __publicField(this, "id");
    __publicField(this, "callback");
    this.id = id;
  }
  getId() {
    return this.id;
  }
  emitEvent(event, data) {
    if (this.callback) {
      this.callback(event, data);
    }
  }
  setCallback(callback) {
    this.callback = callback;
  }
}
class BfPageStore extends BfStore {
  constructor(context) {
    super(context);
    __publicField(this, "childStores", []);
    __publicField(this, "active", false);
    __publicField(this, "messageService");
    __publicField(this, "routeService");
    __publicField(this, "preferenceService");
    __publicField(this, "appContext");
    __publicField(this, "loadParams");
    __publicField(this, "selfChannel", null);
    __publicField(this, "menuStore", null);
    __publicField(this, "tabCloseBlockerRelease", null);
    __publicField(this, "onTabCloseBlockerInner", async (tabKey) => {
      return await this.onTabCloseBlock(tabKey);
    });
    this.appContext = BfAppContext.getInstance();
    this.messageService = BfAppContext.getInstance().getMessageService();
    this.routeService = BfAppContext.getInstance().getRouteService();
    this.preferenceService = BfAppContext.getInstance().getPreferenceService();
    this.menuStore = BfAppContext.getInstance().getMenuStore();
  }
  getInitialState() {
    return {
      ...super.getInitialState(),
      isLoading: true
    };
  }
  clearState() {
    this.setState(this.getInitialState());
    this.childStores.forEach((item) => {
      if (item instanceof BfPageStore) {
        item.clearState();
      }
    });
  }
  setActive(active) {
    this.active = active;
  }
  async onLeave(param) {
    this.setActive(false);
  }
  isActive() {
    return this.active;
  }
  addChildStore(store) {
    this.childStores.push(store);
  }
  destroy() {
    super.destroy();
    this.childStores.forEach((item) => {
      item.destroy();
    });
    this.childStores = [];
    if (this.selfChannel !== null) {
      BfPageChannels.removeChannel(this.selfChannel.getId());
      this.selfChannel = null;
    }
    if (this.tabCloseBlockerRelease) {
      this.tabCloseBlockerRelease();
      this.tabCloseBlockerRelease = null;
    }
  }
  setLoading(loading) {
    this.setState(
      {
        isLoading: loading
      },
      false,
      "\u6B63\u5728\u52A0\u8F7D\u4E2D"
    );
  }
  assignState(key, values, override) {
    this.setState(
      (state) => {
        if (override) {
          state[key] = values;
          return state;
        }
        if (!values) {
          return state;
        }
        Object.assign(state[key], values);
        return state;
      },
      false,
      `\u66F4\u65B0${key}`
    );
  }
  async onInit() {
    var _a;
    this.tabCloseBlockerRelease = (_a = this.menuStore) == null ? void 0 : _a.addCurrentTabCloseBlocker(
      this.onTabCloseBlockerInner
    );
  }
  async onLoad(param) {
    this.loadParams = param;
    this.setActive(true);
  }
  async onTabCloseBlock(tabKey) {
    return false;
  }
  getOpenerChannel() {
    var _a;
    const channelId = (_a = this.loadParams) == null ? void 0 : _a._pageChannelId;
    if (isNil(channelId)) {
      return null;
    }
    return BfPageChannels.getChannel(channelId);
  }
  getSelfChanelId() {
    if (this.selfChannel === null) {
      this.selfChannel = BfPageChannels.addChannel();
      this.selfChannel.setCallback((event, data) => {
        this.onPageChannelEvent(event, data);
      });
    }
    return this.selfChannel.getId();
  }
  onPageChannelEvent(eventId, data) {
    this.logger.debug("\u6536\u5230pageEvent: " + eventId);
  }
  routePush(params) {
    return this.routeService.push({
      ...params,
      pageChannelId: this.getSelfChanelId()
    });
  }
  routeReplace(params) {
    return this.routeService.replace({
      ...params,
      pageChannelId: this.getSelfChanelId()
    });
  }
  closeTab() {
    var _a;
    const pageTabId = this.getPageTabId();
    mustNotBeNull(pageTabId, "pageTabId\u4E0D\u80FD\u4E3A\u7A7A");
    (_a = this.menuStore) == null ? void 0 : _a.closeTabByKey(pageTabId);
  }
  getPageTabId() {
    var _a;
    return (_a = this.loadParams) == null ? void 0 : _a._pageTabId;
  }
  setTabTitle(title, style) {
    var _a;
    const pageTabId = this.getPageTabId();
    mustNotBeNull(pageTabId, "pageTabId\u4E0D\u80FD\u4E3A\u7A7A");
    (_a = this.menuStore) == null ? void 0 : _a.setTabTitleByKey(pageTabId, title, style);
  }
}
class BfAbstractService {
  constructor() {
    __publicField(this, "logger");
    __publicField(this, "initialized", false);
    this.logger = this.createLogger();
  }
  async initialize() {
    if (this.initialized) {
      return;
    }
    await this.doInitialize();
    this.initialized = true;
  }
  async doInitialize() {
  }
  async destroy() {
    this.doDestroy();
    this.initialized = false;
  }
  doDestroy() {
  }
  createLogger() {
    return BfLog4js.getDefaultLogger();
  }
}
class AbstractApiService extends BfAbstractService {
  constructor(context) {
    super();
    __publicField(this, "realClient");
    __publicField(this, "authStorageService");
    __publicField(this, "messageService");
    __publicField(this, "systemInfoService");
    this.authStorageService = context.authStorageService;
    this.messageService = context.messageService;
    this.systemInfoService = context.systemInfoService;
    this.realClient = this.createClient();
  }
  getRealClient() {
    return this.realClient;
  }
  isInvalidateTokenError(code) {
    return ApiUtils.isInvalidateTokenErrorCode(code);
  }
  createCancelTokenSource() {
  }
  getRequestHeaders() {
    const accessTokenKey = this.getAccessTokenKey();
    const token = this.authStorageService.getToken();
    const result = {};
    if (token) {
      result[accessTokenKey] = token;
    }
    return result;
  }
  appendSystemInfoToHeaders(headers) {
    if (!this.systemInfoService) {
      return;
    }
    const systemInfo = this.systemInfoService.getSystemInfo();
    headers["X-3w-client_device"] = systemInfo == null ? void 0 : systemInfo.deviceId;
    headers["X-3w-app_type"] = systemInfo == null ? void 0 : systemInfo.platform;
    headers["X-3w-app_id"] = systemInfo == null ? void 0 : systemInfo.appId;
    headers["X-3w-app_version"] = systemInfo == null ? void 0 : systemInfo.versionCode;
  }
  getAccessTokenKey() {
    return "X-3w-AccessToken";
  }
}
class ApiError extends Error {
  constructor(code, message, response) {
    super(message);
    __publicField(this, "_code");
    __publicField(this, "_response");
    this._code = code;
    this._response = response;
  }
  get code() {
    return this._code;
  }
  get response() {
    return this._response;
  }
}
class ApiService extends BfAbstractService {
  constructor(api) {
    super();
    __publicField(this, "api", null);
    this.api = api;
  }
  createCancelTokenSource() {
    var _a;
    return (_a = this.api) == null ? void 0 : _a.createCancelTokenSource();
  }
  getRequestHeaders() {
    return this.api.getRequestHeaders();
  }
  getBaseUrl() {
    return this.api.getBaseUrl();
  }
  get(url, query, config) {
    var _a;
    return (_a = this.api) == null ? void 0 : _a.get(url, query, config);
  }
  post(url, data, query, config) {
    var _a;
    return (_a = this.api) == null ? void 0 : _a.post(url, data, query, config);
  }
  put(url, data, query, config) {
    var _a;
    return (_a = this.api) == null ? void 0 : _a.put(url, data, mergeQueryConfig(query, config));
  }
  delete(url, query, config) {
    var _a;
    return (_a = this.api) == null ? void 0 : _a.delete(url, mergeQueryConfig(query, config));
  }
  getApiClient() {
    return this.api;
  }
  createLogger() {
    return BfLog4js.getLogger("apiService");
  }
}
class BfRestService extends BfAbstractService {
  constructor(api) {
    super();
    __publicField(this, "api");
    this.api = api;
  }
  createLogger() {
    return BfLog4js.getLogger("restService");
  }
}
const BO_API_BASE_URL = "/v2/rest/services/boService";
const DOC_API_BASE_URL = "/v2/rest/services/docService";
const SERVICE_API_BASE_URL = "/v2/rest/services/service";
const DATAVIEW_API_BASE_URL = "/v2/rest/services/dataviewService";
const PAGE_STATE_SCHEMA_BASE_URL = "/v2/rest/services/metaService/bo/pageStateSchema";
const PAGE_SCHEMA_BASE_URL_BO = "/v2/rest/services/metaService/bo/pageSchema";
const PAGE_SCHEMA_BASE_URL_DOC = "/v2/rest/services/metaService/doc/pageSchema";
const QNAME_PREFIX_BO = "bo://";
const QNAME_PREFIX_DOC = "doc://";
const QNAME_PREFIX_DATAVIEW = "dataview://";
const BO_AUDIT_HISTORY_BASE_URL = "/v2/rest/services/boService/meta/boCmdAuditHistoryDoc";
const FILE_SERVICE_BASE_URL = "/v2/rest/services/boService/infra/file";
const ENUM_VALUES_URL = "/v2/rest/services/boService/infra/enums/enumValues";
const APP_RUN_EVT_URL = "/v2/rest/services/boService/infra/appRunEvent";
class AbstractStructureRestApiService extends BfRestService {
  create(param, config) {
    return this.api.post(
      `${this.apiBaseUrl}/${this.apiDomainId}/${this.apiStructureId}/create`,
      param,
      null,
      config
    );
  }
  update(param, isMemoly, config) {
    return this.api.post(
      `${this.apiBaseUrl}/${this.apiDomainId}/${this.apiStructureId}/update`,
      param,
      { isMemoly },
      config
    );
  }
  delete(param, config) {
    return this.api.post(
      `${this.apiBaseUrl}/${this.apiDomainId}/${this.apiStructureId}/delete`,
      param,
      null,
      config
    );
  }
  info(param, config) {
    const newParams = Object.assign(
      {
        willConvertAttrs: true
      },
      param
    );
    return this.api.post(
      `${this.apiBaseUrl}/${this.apiDomainId}/${this.apiStructureId}/info`,
      newParams,
      null,
      config
    );
  }
  query(param, config) {
    const newParams = Object.assign(
      {
        willConvertAttrs: true
      },
      param
    );
    return this.api.post(
      `${this.apiBaseUrl}/${this.apiDomainId}/${this.apiStructureId}/query`,
      newParams,
      null,
      config
    );
  }
  count(param, config) {
    return this.api.post(
      `${this.apiBaseUrl}/${this.apiDomainId}/${this.apiStructureId}/count`,
      param,
      null,
      config
    );
  }
  bulkCreate(param, config) {
    return this.api.post(
      `${this.apiBaseUrl}/${this.apiDomainId}/${this.apiStructureId}/bulkCreate`,
      param,
      null,
      config
    );
  }
  bulkUpdate(param, isMemoly, config) {
    return this.api.post(
      `${this.apiBaseUrl}/${this.apiDomainId}/${this.apiStructureId}/bulkUpdate`,
      param,
      { isMemoly },
      config
    );
  }
  infoState(param, config) {
    return this.api.post(
      `${this.apiBaseUrl}/${this.apiDomainId}/${this.apiStructureId}/infoState`,
      param,
      null,
      config
    );
  }
  infoStateCommands(param, config) {
    return this.api.post(
      `${this.apiBaseUrl}/${this.apiDomainId}/${this.apiStructureId}/infoStateCommands`,
      param,
      null,
      config
    );
  }
  updateState(param, config) {
    return this.api.post(
      `${this.apiBaseUrl}/${this.apiDomainId}/${this.apiStructureId}/updateState`,
      param,
      null,
      config
    );
  }
}
class AbstractDocRestApiService extends AbstractStructureRestApiService {
  get apiBaseUrl() {
    return DOC_API_BASE_URL;
  }
}
class AbstractBORestApiService2 extends AbstractStructureRestApiService {
  get apiBaseUrl() {
    return BO_API_BASE_URL;
  }
}
class AbstractBORestApiService extends AbstractStructureRestApiService {
  get apiBaseUrl() {
    return BO_API_BASE_URL;
  }
}
class AbstractDataviewRestApiService extends AbstractStructureRestApiService {
  get apiBaseUrl() {
    return DATAVIEW_API_BASE_URL;
  }
}
class AbstractFunctionRestApiService extends BfRestService {
  post(url, data, query, config) {
    var _a;
    return (_a = this.api) == null ? void 0 : _a.post(url, data, query, config);
  }
  get(url, query, config) {
    return this.api.get(url, query, config);
  }
}
class FileServiceBase extends BfRestService {
  constructor(api, config) {
    var _a;
    super(api);
    __publicField(this, "taskList", /* @__PURE__ */ new Map());
    __publicField(this, "uploadUrl");
    __publicField(this, "headers");
    __publicField(this, "fileKey");
    __publicField(this, "config");
    this.uploadUrl = (_a = config.uploadUrl) != null ? _a : FILE_SERVICE_BASE_URL;
    this.headers = config.headers || {};
    this.fileKey = config.fileKey;
    this.config = config;
  }
  getFileExt(file) {
    const name = file.name;
    const extPos = name.lastIndexOf(".");
    if (extPos === -1) {
      return null;
    }
    return name.substr(extPos + 1, name.length);
  }
  getFileType(file) {
    return file.type;
  }
  getHeaders() {
    const commonHeaders = this.api.getRequestHeaders() || {};
    return Object.assign({}, commonHeaders, this.headers);
  }
  getFileKey() {
    return this.fileKey;
  }
  getUploadUrl() {
    return this.uploadUrl;
  }
  getFullUploadUrl() {
    if (this.uploadUrl.startsWith("http")) {
      return this.uploadUrl;
    }
    const apiBaseUrl = this.api.getBaseUrl();
    let baseUrl = apiBaseUrl != null ? apiBaseUrl : "";
    const index = baseUrl.lastIndexOf("/");
    if (index > 0) {
      baseUrl = baseUrl.substring(0, index);
    }
    return baseUrl + this.uploadUrl;
  }
}
class RandomService {
  constructor(prefix) {
    __publicField(this, "prefix");
    this.prefix = prefix || "";
  }
  getRandomKey() {
    if (this.prefix) {
      return `${this.prefix}-${this.randomNumber()}`;
    } else {
      return `${this.randomNumber()}`;
    }
  }
  getRandomNumberKey() {
    return `${this.prefix}${this.randomNumber()}`;
  }
  randomNumber() {
    return new Date().getTime() * 100 + Math.round(Math.random() * 100);
  }
}
const ENUM_STATUS_LOADING = "enum_status_loading";
class BfEnumService extends BfAbstractService {
  constructor(apiService, storage, options2) {
    super();
    __publicField(this, "storage");
    __publicField(this, "options");
    __publicField(this, "listenerMaps", {});
    __publicField(this, "loadingStatus", {});
    __publicField(this, "apiService");
    __publicField(this, "enumStorePrefix", "e_");
    __publicField(this, "url", ENUM_VALUES_URL);
    this.apiService = apiService;
    this.storage = storage;
    if (options2 == null ? void 0 : options2.storagePrefix) {
      this.enumStorePrefix = options2 == null ? void 0 : options2.storagePrefix;
    }
    if (options2 == null ? void 0 : options2.dataUrl) {
      this.url = options2 == null ? void 0 : options2.dataUrl;
    }
  }
  createLogger() {
    return BfLog4js.getLogger("enumService");
  }
  async createEnumItem(enumId, enumValue) {
    await this.apiService.post("/v2/rest/services/boService/sys/enumItem/create", {
      sysEnumId: {
        id: enumId
      },
      name: enumValue.name,
      enName: enumValue.code
    });
  }
  getEnumOptions(enumId) {
    return new Promise((resolve, reject) => {
      if (this._isEnumLoading(enumId)) {
        this._setEnumListener(enumId, (result) => {
          resolve(result);
        });
      } else {
        const result = this._getEnumCache(enumId);
        if (result) {
          resolve(result);
        } else {
          this._setEnumLoading(enumId);
          this.apiService.get(this.url, {
            enumId
          }).then((result2) => {
            this._clearEnumLoading(enumId);
            if (result2 && result2.length) {
              result2 = result2.map(this._convertFromServer);
              this._setEnumCache(enumId, result2);
              this._notifyEnumListener(enumId, result2);
              resolve(result2);
            } else {
              console.warn("\u6CA1\u6709\u67E5\u8BE2\u5230\u679A\u4E3E\uFF0C\u6216\u8005\u679A\u4E3E\u5185\u5BB9\u4E3A\u7A7A enumId=%s", enumId);
              reject(result2);
            }
          }).catch((error) => {
            this._clearEnumLoading(enumId);
            reject(error);
          });
        }
      }
    });
  }
  clearEnumCache() {
    this.logger.debug("\u6E05\u7406\u679A\u4E3E\u7F13\u5B58");
    const enumKeys = this.storage.keys().filter((item) => item.startsWith("e_"));
    enumKeys.forEach((item) => {
      this.storage.remove(item);
    });
  }
  _convertFromServer(enumOption) {
    return enumOption;
  }
  _getEnumCache(enumId) {
    return this.storage.get(this.enumStorePrefix + enumId, void 0);
  }
  _setEnumCache(enumId, value) {
    this.storage.set(this.enumStorePrefix + enumId, value);
  }
  _isEnumLoading(enumId) {
    return this.loadingStatus[this.enumStorePrefix + enumId] === ENUM_STATUS_LOADING;
  }
  _setEnumLoading(enumId) {
    this.loadingStatus[this.enumStorePrefix + enumId] = ENUM_STATUS_LOADING;
  }
  _clearEnumLoading(enumId) {
    delete this.loadingStatus[this.enumStorePrefix + enumId];
  }
  _setEnumListener(enumId, callback) {
    this.listenerMaps[enumId] = this.listenerMaps[enumId] || [];
    this.listenerMaps[enumId].push(callback);
  }
  _notifyEnumListener(enumId, message) {
    const listeners = this.listenerMaps[enumId] || [];
    listeners.forEach((listener) => listener(message));
    this.listenerMaps[enumId] = null;
    delete this.listenerMaps[enumId];
  }
}
class AreaService extends BfRestService {
  constructor() {
    super(...arguments);
    __publicField(this, "areaTreeCache", null);
  }
  async getCountry() {
    return Promise.resolve([]);
  }
  async getProvinces(countryId) {
    return this.api.get("/v2/rest/services/boService/infra/area/areaProvince");
  }
  async getCities(provinceId) {
    return this.api.get("/v2/rest/services/boService/infra/area/areaCity", { provinceId });
  }
  async getCounties(cityId) {
    return this.api.get("/v2/rest/services/boService/infra/area/areaCounty", { cityId });
  }
  async tree() {
    if (!this.areaTreeCache) {
      this.areaTreeCache = await this.api.get("/v2/rest/services/boService/infra/area/areaTree");
    }
    return this.areaTreeCache;
  }
}
class AbstractMessageService extends BfAbstractService {
  createLogger() {
    return BfLog4js.getLogger("messageService");
  }
}
class AbstractRouteService extends BfAbstractService {
  constructor() {
    super(...arguments);
    __publicField(this, "navigate");
    __publicField(this, "location");
  }
  canBack() {
    return true;
  }
  getNavigate() {
    return this.navigate;
  }
  setNavigateFunction(navigate) {
    if (!navigate) {
      console.error("setNavigateFunction null should not happen");
      return;
    }
    this.navigate = navigate;
  }
  setLocation(location) {
    this.location = location;
  }
  getLocation() {
    return this.location;
  }
  createLogger() {
    return BfLog4js.getLogger("route");
  }
  trigger(event, params) {
  }
}
const DEFAULT_KEY = "token";
class BfAuthStorageService extends BfAbstractService {
  constructor(option) {
    super();
    __publicField(this, "tokenKey", DEFAULT_KEY);
    __publicField(this, "storageService");
    this.tokenKey = option.tokenKey || DEFAULT_KEY;
    this.storageService = option == null ? void 0 : option.storageService;
  }
  getToken() {
    return this.storageService.get(this.tokenKey, null);
  }
  setToken(token) {
    this.logger.info("\u8BBE\u7F6EToken");
    this.storageService.set(this.tokenKey, token);
  }
  setTokenKey(tokenKey) {
    this.tokenKey = tokenKey;
  }
  removeToken() {
    this.logger.info("\u6E05\u7406Token");
    this.storageService.remove(this.tokenKey);
  }
  createLogger() {
    return BfLog4js.getLogger("authStorageService");
  }
}
class BfAbstractPushService extends ApiService {
  constructor() {
    super(...arguments);
    __publicField(this, "initialized", false);
    __publicField(this, "messageCallbacks", /* @__PURE__ */ new Set());
  }
  async initialize() {
    if (this.initialized) {
      this.logger.debug("initialized is true, skip");
      return;
    }
    try {
      this.logger.debug("initialize");
      this.initialized = true;
      const params = await this.getBindParam();
      return await this.bindWithServer(params);
    } catch (err) {
      this.logger.error(err);
      this.logger.error("initialize failed");
      this.initialized = false;
    }
  }
  async destroyService(silent) {
    if (!silent) {
      try {
        await this.unBindWithServer(this.getBindParam());
      } catch (e) {
        console.error(e);
      }
    }
    this.initialized = false;
  }
  registerMessageCallback(callback) {
    this.messageCallbacks.add(callback);
  }
  clearMessageCallback() {
    this.messageCallbacks.clear();
  }
  removeMessageCallback(callback) {
    this.messageCallbacks.delete(callback);
  }
  onMessageReceived(message) {
    try {
      if (typeof message === "string") {
        message = JSON.parse(message);
      }
    } catch (e) {
      this.logger.error("\u63A8\u9001\u6D88\u606F\u89E3\u6790JSON\u51FA\u9519");
    }
    this.logger.debug("\u6536\u5230\u63A8\u9001\u6D88\u606F", message);
    this.messageCallbacks.forEach((item) => {
      item({
        type: this.getChannelType(),
        message
      });
    });
  }
  async bindWithServer(param) {
    this.logger.debug("bind with server");
    this.logger.debug(param);
    await this.post(`/v2/rest/services/service/push/push/bind`, param);
    await this.afterSendBindParam();
  }
  async unBindWithServer(param) {
    return this.post(`/v2/rest/services/service/push/push/unbind`, param);
  }
  createLogger() {
    return BfLog4js.getLogger("pushService");
  }
}
class BfAbstractMenuService extends BfAbstractService {
  constructor() {
    super();
  }
  getTopLevelMenus(menus) {
    return menus.map((item) => {
      return omit$1(item, "children");
    });
  }
  getChildMenusOfTopLevel(menu) {
    return menu.children;
  }
  getFirstSlashPath(treePath) {
    const match = treePath.match(/^\/?(\w+)\/?/);
    if (!match) {
      return void 0;
    }
    if (!match[1]) {
      return;
    }
    return `/${match[1]}`;
  }
  getFirstChildPage(menu) {
    if (!menu.children) {
      return;
    }
    let result;
    treeTraverse(menu.children, (item, parent2) => {
      if (item.type === "page" && !result) {
        result = item;
        return true;
      }
    });
    return result;
  }
  getActiveTopLevelMenu(menuList, path) {
    return menuList.find((item) => {
      return item.treePath === path;
    });
  }
  getActiveMenuOfPagePath(menuList, path) {
    let node;
    treeTraverse(menuList, (item, parent2) => {
      if (item.type === "page" && item.treePath === path && !node) {
        node = parent2;
        return true;
      }
    });
    return node;
  }
  getPureMenuNodeList(menuList) {
    return treeFilter(menuList, (item) => {
      return item.type === "menu";
    });
  }
  createLogger() {
    return BfLog4js.getLogger("menuService");
  }
}
class BfApiMenuService extends BfAbstractMenuService {
  constructor(apiService, menuGroupId) {
    super();
    __publicField(this, "apiService");
    __publicField(this, "menuGroupId");
    this.apiService = apiService;
    this.menuGroupId = menuGroupId;
  }
  async fetchMenus() {
    const res = await this.apiService.post("/v2/rest/services/service/res/authorization/menuView", {
      menuGroupId: this.menuGroupId
    });
    return res.menuNode;
  }
}
class BfUserService extends BfAbstractService {
  constructor(apiService) {
    super();
    __publicField(this, "apiService");
    this.apiService = apiService;
  }
  async fetchUserInfo(config) {
    return await this.apiService.get(
      "v2/rest/services/service/res/authorization/userInfoRead",
      null,
      config
    );
  }
  async requestLogin(params, config) {
    var _a;
    const res = await this.apiService.post(
      `v2/rest/services/service/res/authorization/login`,
      params,
      null,
      config
    );
    return (_a = res.token) == null ? void 0 : _a.token;
  }
  async requestChangePwd(params, config) {
    return await this.apiService.post(
      "v2/rest/services/service/res/authorization/changePwd",
      params,
      null,
      config
    );
  }
  async requestResetPwd(params, config) {
    return await this.apiService.post(
      "v2/rest/services/service/res/authorization/resetPwd",
      params,
      config
    );
  }
  async requestLogout(config) {
    return await this.apiService.post(
      "v2/rest/services/service/res/authorization/logout",
      null,
      config
    );
  }
  createLogger() {
    return BfLog4js.getLogger("userService");
  }
  async sendCaptcha(params, config) {
    return await this.apiService.post(
      "/v2/rest/services/service/res/authorization/sendCaptcha",
      params,
      null,
      config
    );
  }
  async verifyCaptcha(params, config) {
    return await this.apiService.post(
      "/v2/rest/services/service/res/authorization/verifyCaptcha",
      params,
      null,
      config
    );
  }
}
class BaseStorageService extends BfAbstractService {
  deserialize(strVal, defaultValue) {
    if (!strVal) {
      return defaultValue != null ? defaultValue : null;
    }
    let val = null;
    try {
      val = JSON.parse(strVal);
    } catch (e) {
      return val;
    }
    return val !== void 0 ? val : defaultValue != null ? defaultValue : null;
  }
  serialize(data) {
    if (isNil(data)) {
      return data;
    }
    return JSON.stringify(data);
  }
}
class AbstractStorageService extends BaseStorageService {
  isEmpty(key) {
    return this.get(key, null) === null;
  }
  deserialize(strVal, defaultValue) {
    if (!strVal) {
      return defaultValue != null ? defaultValue : null;
    }
    let val = null;
    try {
      val = JSON.parse(strVal);
    } catch (e) {
      return val;
    }
    return val !== void 0 ? val : defaultValue != null ? defaultValue : null;
  }
  serialize(data) {
    if (isNil(data)) {
      return data;
    }
    return JSON.stringify(data);
  }
}
class AbstractAsyncStorageService extends AbstractStorageService {
}
class BoStateService extends BfRestService {
  fetchBoStatusModel(boMeta) {
    const meta = StructureMetaUtils.fill(boMeta);
    return this.api.get(PAGE_STATE_SCHEMA_BASE_URL, {
      boQname: meta.qname
    });
  }
  fetchBoStateCommands(boMeta, dataId) {
    const meta = StructureMetaUtils.fill(boMeta);
    return this.api.post(`${BO_API_BASE_URL}/${meta.domain}/${meta.id}/infoStateCommands`, {
      id: dataId
    });
  }
  updateBoState(boMeta, params) {
    const meta = StructureMetaUtils.fill(boMeta);
    return this.api.post(`${BO_API_BASE_URL}/${meta.domain}/${meta.id}/updateState`, params);
  }
  createLogger() {
    return BfLog4js.getLogger("BoStateService");
  }
  getPrimaryStateCommands(schema, stateId) {
    var _a, _b;
    const transforms = (_a = schema == null ? void 0 : schema.primaryState) == null ? void 0 : _a.transforms;
    if (!transforms) {
      return void 0;
    }
    return (_b = transforms.find((item) => item.stateId === stateId)) == null ? void 0 : _b.commands;
  }
  getSecondaryStateCommands(schema, stateId) {
    throw new Error("Method not implemented.");
  }
}
class PageSchemaService extends BfRestService {
  fetchPageSchema(params) {
    var _a, _b;
    if ((_a = params.qname) == null ? void 0 : _a.startsWith(QNAME_PREFIX_DOC)) {
      return this.api.get(PAGE_SCHEMA_BASE_URL_DOC, {
        docQname: params.qname,
        type: params.type,
        uiviewQname: params.uiviewQname,
        deviceType: params.deviceType,
        uuid: params.uuid
      });
    }
    if ((_b = params.qname) == null ? void 0 : _b.startsWith(QNAME_PREFIX_DATAVIEW)) {
      return this.api.get(PAGE_SCHEMA_BASE_URL_DOC, {
        dataviewQname: params.qname,
        type: params.type,
        uiviewQname: params.uiviewQname,
        deviceType: params.deviceType,
        uuid: params.uuid
      });
    }
    return this.api.get(PAGE_SCHEMA_BASE_URL_BO, {
      boQname: params.qname,
      type: params.type,
      uiviewQname: params.uiviewQname,
      deviceType: params.deviceType,
      uuid: params.uuid
    });
  }
  createLogger() {
    return BfLog4js.getLogger("PageSchemaService");
  }
  async updateSchemaSchemaIfNeeded(params) {
    return null;
  }
}
class CacheablePageSchemaService extends PageSchemaService {
  constructor(config) {
    super(config.apiService);
    __publicField(this, "storageService");
    __publicField(this, "storageScope", "_ui");
    __publicField(this, "schemaCache", /* @__PURE__ */ new Map());
    this.storageService = config.storageService;
    if (config.scope) {
      this.storageScope = config.scope;
    }
  }
  async initialize() {
    await super.initialize();
    this.schemaCache = /* @__PURE__ */ new Map();
    this.logger.debug("initialize");
  }
  async fetchPageSchema(params) {
    const cached = this.getSchema(params);
    if (!cached) {
      const res = await super.fetchPageSchema(params);
      this.writeSchema(params, res);
      return res;
    }
    return cached;
  }
  async updateSchemaSchemaIfNeeded(params) {
    const data = this.getSchema(params);
    const schema = await super.fetchPageSchema({ ...params, uuid: data == null ? void 0 : data.uuid });
    if (!(schema == null ? void 0 : schema.elements)) {
      return null;
    }
    this.writeSchema(params, schema);
    return schema;
  }
  writeSchema(params, schema) {
    const key = this.getKey(params);
    if (schema) {
      this.schemaCache.set(key, JSON.stringify(schema));
    } else {
      this.schemaCache.delete(key);
    }
  }
  getSchema(params) {
    const key = this.getKey(params);
    const schema = this.schemaCache.get(key);
    if (schema) {
      try {
        return JSON.parse(schema);
      } catch (e) {
        return null;
      }
    }
    return schema;
  }
  getKey(params) {
    return `${this.storageScope}:${params.qname}:${params.uiviewQname}`;
  }
  doDestroy() {
    super.doDestroy();
    this.schemaCache.clear();
  }
}
var PageSchemaRequestType = /* @__PURE__ */ ((PageSchemaRequestType2) => {
  PageSchemaRequestType2["listView"] = "ListView";
  PageSchemaRequestType2["infoView"] = "InfoView";
  PageSchemaRequestType2["splitListView"] = "SplitListView";
  PageSchemaRequestType2["splitDetailView"] = "SplitDetailView";
  PageSchemaRequestType2["wizardView"] = "WizardView";
  PageSchemaRequestType2["mandatoryInfoView"] = "MandatoryInfoView";
  return PageSchemaRequestType2;
})(PageSchemaRequestType || {});
var PageDeviceType = /* @__PURE__ */ ((PageDeviceType2) => {
  PageDeviceType2["mobile"] = "Mobile";
  PageDeviceType2["pc"] = "PC";
  return PageDeviceType2;
})(PageDeviceType || {});
class BoAuditHistoryService extends BfRestService {
  fetchHistory(bo, dataId) {
    bo = StructureMetaUtils.fill(bo);
    return this.api.post(`${BO_AUDIT_HISTORY_BASE_URL}/query`, {
      conditions: [newEqualCondition("boQname", bo.qname), newEqualCondition("dataIdStr", dataId)]
    });
  }
  createLogger() {
    return BfLog4js.getLogger("BoAuditHistoryService");
  }
}
class AbstractSystemInfoService extends BfAbstractService {
  constructor() {
    super(...arguments);
    __publicField(this, "systemInfoCache");
  }
  async doInitialize() {
    try {
      this.systemInfoCache = await this.internalGetSystemInfo();
    } catch (e) {
      console.error(e);
    }
  }
  getSystemInfo() {
    if (this.systemInfoCache) {
      return this.systemInfoCache;
    }
    this.initialize();
    return this.systemInfoCache;
  }
}
class BfMonitorLogAppender extends BfLogAppender {
  constructor() {
    super(...arguments);
    __publicField(this, "monitorService");
  }
  doAppend(loggingEvent) {
    const monitorService = this.getMonitorService();
    if (!monitorService) {
      return;
    }
    const message = `${loggingEvent.categoryName} ${loggingEvent.message} ${formatException(
      loggingEvent.exception
    )}`;
    switch (loggingEvent.level.valueOf()) {
      case BfLogLevel.DEBUG.valueOf():
        monitorService.emitCustomEvt({
          type: "debug",
          msg: message
        });
        break;
      case BfLogLevel.INFO.valueOf():
        monitorService.emitCustomEvt({
          type: "info",
          msg: message
        });
        break;
      case BfLogLevel.WARN.valueOf():
        monitorService.emitCustomEvt({
          type: "warn",
          msg: message
        });
        break;
      case BfLogLevel.ERROR.valueOf():
        monitorService.emitCustomEvt({
          type: "error",
          msg: message
        });
        break;
      default:
        monitorService.emitCustomEvt({
          type: "debug",
          msg: message
        });
    }
  }
  getMonitorService() {
    if (!this.monitorService) {
      this.monitorService = BfAppContext.getInstance().getMonitorService();
    }
    return this.monitorService;
  }
}
function formatException(e) {
  if (e instanceof Error) {
    return e.message + "\n" + e.stack;
  }
  if (typeof e === "string") {
    return e;
  }
  if (e === null || typeof e === "undefined") {
    return "";
  }
  if (typeof e === "object") {
    try {
      return JSON.stringify(e);
    } catch (e2) {
      console.error(e2);
    }
  }
  return "UNKNOWN_FORMAT_EX";
}
class BfMonitorService extends BfRestService {
  constructor(context, param) {
    super(context.apiService);
    __publicField(this, "userStore");
    __publicField(this, "systemInfoService");
    __publicField(this, "apiService");
    __publicField(this, "storageService");
    __publicField(this, "logStorageKey", "_monitor");
    __publicField(this, "lastUpdateTimeKey", "_monitor_update_time");
    __publicField(this, "uploadingLogKey", "_monitor_uploading");
    __publicField(this, "uploading", false);
    __publicField(this, "retryTimes", 0);
    __publicField(this, "enabled", true);
    __publicField(this, "uploadingLogs", []);
    __publicField(this, "lastLogTime", 0);
    __publicField(this, "MAX_CACHE_LOG_COUNT", 50);
    __publicField(this, "MAX_CACHE_DURATION", 10 * 1e3);
    __publicField(this, "MAX_UPLOAD_TIMES", 600);
    __publicField(this, "timer", null);
    this.userStore = context.userStore;
    this.systemInfoService = context.systemInfoService;
    this.apiService = context.apiService;
    this.storageService = context.storageService;
    if (param == null ? void 0 : param.logStorageKey) {
      this.logStorageKey = param.logStorageKey;
    }
    this.lastUpdateTimeKey = `${this.logStorageKey}_update_time`;
    this.uploadingLogKey = `${this.logStorageKey}_uploading`;
  }
  async doInitialize() {
    await this.systemInfoService.initialize();
  }
  isEnabled() {
    return this.enabled;
  }
  setEnabled(enabled) {
    this.enabled = enabled;
  }
  getUserId() {
    var _a;
    let userid = "none";
    const userInfo = this.userStore.getState().userInfo;
    try {
      userid = (userInfo == null ? void 0 : userInfo.mobile) || ((_a = userInfo == null ? void 0 : userInfo.employee) == null ? void 0 : _a.id);
    } catch (e) {
      console.error(e);
    }
    return userid;
  }
  emitApiRequestEvt(evt) {
    this.handleNewEvt({
      eventType: EnumUtils.newEnumValue("api"),
      param: evt
    });
  }
  emitClickEvt(evt) {
    this.handleNewEvt({
      eventType: EnumUtils.newEnumValue("click"),
      param: `${evt.module}:${evt.name}:${evt.desc}`
    });
  }
  emitCustomEvt(evt) {
    this.handleNewEvt({
      eventType: EnumUtils.newEnumValue("custom"),
      param: `[${evt.type}] ${evt.msg}`
    });
  }
  emitError(evt) {
    this.handleNewEvt({
      eventType: EnumUtils.newEnumValue("error"),
      param: `${evt.msg}
${evt.trace}`
    });
  }
  emitLocationChange(evt) {
    this.handleNewEvt({
      eventType: EnumUtils.newEnumValue("page"),
      param: evt.path
    });
  }
  emitLogEvent(evt) {
    this.handleNewEvt({
      eventType: EnumUtils.newEnumValue("log"),
      param: evt
    });
  }
  async handleNewEvt(evt) {
    if (!this.enabled) {
      return;
    }
    const log = await this.wrapCommonInfo(evt);
    await this.sendLog(log);
  }
  async wrapCommonInfo(evt) {
    evt.userId = this.getUserId();
    evt.eventTime = Date.now();
    try {
      const info = this.systemInfoService.getSystemInfo();
      evt.appId = info == null ? void 0 : info.appId;
      evt.versionNumber = info == null ? void 0 : info.versionCode;
      evt.versionName = info == null ? void 0 : info.versionName;
      evt.deviceId = info == null ? void 0 : info.deviceId;
      evt.deviceType = (info == null ? void 0 : info.deviceType) ? EnumUtils.newEnumValue(info.deviceType) : void 0;
      evt.deviceModel = info == null ? void 0 : info.deviceModel;
      evt.system = info == null ? void 0 : info.system;
      evt.clientType = (info == null ? void 0 : info.clientOsType) ? EnumUtils.newEnumValue(info.clientOsType) : void 0;
    } catch (e) {
      console.error(e);
    }
    return evt;
  }
  async sendLog(evt) {
    this.appendLog(evt);
    if (this.shouldUploadLog()) {
      await this.uploadLog();
      return;
    }
    this.resetUploadTimer();
  }
  appendLog(log) {
    const logs = this.getLogs();
    logs.push(log);
    this.setLogs(logs);
  }
  getLogs() {
    return this.storageService.get(this.logStorageKey, []);
  }
  setLogs(logs) {
    this.storageService.set(this.logStorageKey, logs != null ? logs : []);
  }
  resetUploadTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.timer = null;
      this.uploadLog().then().catch();
    }, this.MAX_CACHE_DURATION);
  }
  async uploadLog() {
    if (this.uploading) {
      return;
    }
    const logs = this.getLogs();
    if (!logs.length) {
      return;
    }
    this.uploadingLogs = this.uploadingLogs.concat(logs);
    this.setLogs([]);
    this.uploading = true;
    await this.doUploadLogs(this.uploadingLogs);
    this.uploadingLogs = [];
    this.uploading = false;
  }
  async doUploadLogs(logs) {
    if (!this.enabled) {
      return;
    }
    if (this.retryTimes > this.MAX_UPLOAD_TIMES) {
      await PromiseUtils.wait(5 * 60 * 1e3);
      this.retryTimes = 0;
      return;
    }
    try {
      await this.apiService.post(`${APP_RUN_EVT_URL}/bulkCreate`, logs, void 0, {
        ignoreByMonitor: true,
        showResErrorMsg: false
      });
      this.lastLogTime = Date.now();
      this.retryTimes = 0;
    } catch (e) {
      this.retryTimes++;
      console.error(e);
      await PromiseUtils.wait(10 * 1e3);
      await this.doUploadLogs(logs);
    }
  }
  shouldUploadLog() {
    const logs = this.readLogs();
    const time = this.readLastLogTime();
    if (logs.length > this.maxCacheLogCount()) {
      return true;
    }
    return Date.now() - time > this.maxCacheDuration();
  }
  maxCacheLogCount() {
    return this.MAX_CACHE_LOG_COUNT;
  }
  maxCacheDuration() {
    return this.MAX_CACHE_DURATION;
  }
  readLogs() {
    return this.getLogs();
  }
  readUploadLogs() {
    if (!this.uploadingLogs) {
      this.uploadingLogs = this.storageService.get(this.uploadingLogKey, []);
    }
    return this.uploadingLogs;
  }
  saveUploadingLogs(logs) {
    this.uploadingLogs = logs;
    if (logs.length)
      ;
  }
  readLastLogTime() {
    if (!this.lastLogTime) {
      this.lastLogTime = Date.now();
    }
    return this.lastLogTime;
  }
}
class StructureDataServiceCreatorUtils {
  static getRefStInfo(refEntity) {
    if (refEntity.refDoc) {
      return refEntity.refDoc;
    }
    if (refEntity.refDataview) {
      return refEntity.refDataview;
    }
    return refEntity.refBO;
  }
  static getBaseApiUrl(refEntity) {
    if (refEntity.refDoc) {
      return DOC_API_BASE_URL;
    }
    if (refEntity.refDataview) {
      return DATAVIEW_API_BASE_URL;
    }
    return BO_API_BASE_URL;
  }
  static getQNamePrefix(refEntity) {
    if (refEntity.refDoc) {
      return StructureQNameType.doc;
    }
    if (refEntity.refDataview) {
      return StructureQNameType.dataview;
    }
    return StructureQNameType.bo;
  }
}
class AbstractStructureDataService {
  constructor(apiService, refEntity) {
    __publicField(this, "api");
    __publicField(this, "refEntity");
    __publicField(this, "refBoDesc");
    __publicField(this, "baseUrl");
    __publicField(this, "qNamePrefix");
    this.api = apiService;
    this.refEntity = refEntity;
    const bodesc = StructureDataServiceCreatorUtils.getRefStInfo(refEntity);
    this.baseUrl = StructureDataServiceCreatorUtils.getBaseApiUrl(refEntity);
    this.qNamePrefix = StructureDataServiceCreatorUtils.getQNamePrefix(refEntity);
    this.refBoDesc = StructureMetaUtils.fill(bodesc, this.qNamePrefix);
  }
  getBaseUrl() {
    return this.baseUrl;
  }
  getQNamePrefix() {
    return this.qNamePrefix;
  }
  async create(data, config) {
    return await this.api.post(
      `${this.getBaseUrl()}/${this.refBoDesc.domain}/${this.refBoDesc.id}/create`,
      data,
      config
    );
  }
  async delete(param, config) {
    return this.api.post(
      `${this.getBaseUrl()}/${this.refBoDesc.domain}/${this.refBoDesc.id}/delete`,
      param,
      null,
      config
    );
  }
  async info(param, config) {
    const newParams = Object.assign(
      {
        willConvertAttrs: true
      },
      param
    );
    return await this.api.post(
      `${this.getBaseUrl()}/${this.refBoDesc.domain}/${this.refBoDesc.id}/info`,
      newParams,
      null,
      config
    );
  }
  async query(param, config) {
    const newParams = Object.assign(
      {
        willConvertAttrs: true
      },
      param
    );
    return await this.api.post(
      `${this.getBaseUrl()}/${this.refBoDesc.domain}/${this.refBoDesc.id}/query`,
      newParams,
      null,
      config
    );
  }
  async exportExcel(param, config) {
    const newParams = Object.assign(
      {
        willConvertAttrs: true
      },
      param
    );
    const response = await this.api.post(
      `${this.getBaseUrl()}/${this.refBoDesc.domain}/${this.refBoDesc.id}/export/excel`,
      newParams,
      null,
      {
        useTransformResponse: false,
        responseType: "blob",
        ...config
      }
    );
    this.downloadResponse(response);
  }
  async update(param, isMemoly, config) {
    return await this.api.post(
      `${this.getBaseUrl()}/${this.refBoDesc.domain}/${this.refBoDesc.id}/update`,
      param,
      {
        isMemoly
      },
      config
    );
  }
  async bulkCreate(param, config) {
    return await this.api.post(
      `${this.getBaseUrl()}/${this.refBoDesc.domain}/${this.refBoDesc.id}/bulkCreate`,
      param,
      null,
      config
    );
  }
  async bulkUpdate(param, isMemoly, config) {
    return await this.api.post(
      `${this.getBaseUrl()}/${this.refBoDesc.domain}/${this.refBoDesc.id}/bulkUpdate`,
      param,
      {
        isMemoly
      },
      config
    );
  }
  async fetchSubformData(mainstructure, subRefEntity, query, config) {
    const service = BfAppContext.getInstance().createStructureDataService(subRefEntity);
    const subStructureInfo = StructureDataServiceCreatorUtils.getRefStInfo(subRefEntity);
    const customQuery = query || {};
    const { conditions, ...restParams } = customQuery;
    let reqConditions = [
      newEqualCondition(subStructureInfo.fkAttributeId, mainstructure.id)
    ];
    if (conditions) {
      reqConditions = reqConditions.concat(conditions);
    }
    return await service.query(
      {
        noPaging: true,
        conditions: reqConditions,
        ...restParams
      },
      config
    );
  }
}
class BfProperties {
  static set(key, data) {
    this.values.set(key, data);
  }
  static get(key, defaultValue) {
    if (this.values.has(key)) {
      return this.values.get(key);
    }
    return defaultValue;
  }
}
__publicField(BfProperties, "values", /* @__PURE__ */ new Map());
const _BfDirectionService = class extends BfRestService {
  async direction(origin, destination, key, type = "driving") {
    const prefix = BfProperties.get(_BfDirectionService.PROP_API_PREFIX, "/_AMapService");
    const req = {
      key,
      origin: `${origin.longitude},${origin.latitude}`,
      destination: `${destination.longitude},${destination.latitude}`
    };
    const res = await this.api.get(`v3/direction/${type}`, req, {
      baseURL: prefix
    });
    if (res.status !== "1") {
      throw new Error(res.info);
    }
    return res.route;
  }
  async getDistance(origin, destination, key) {
    var _a;
    const res = await this.direction(origin, destination, key, "driving");
    const distances = (_a = res.paths) == null ? void 0 : _a.map((item) => Number(item.distance));
    if (!distances) {
      return null;
    }
    return Math.min(...distances);
  }
};
let BfDirectionService = _BfDirectionService;
__publicField(BfDirectionService, "PROP_API_PREFIX", "direction.apiprefix");
const _BfAbstractPlaceService = class extends BfRestService {
  constructor(apiService, options2) {
    super(apiService);
    __publicField(this, "webApiKey");
    __publicField(this, "jsApiKey");
    this.webApiKey = options2.webApiKey;
    this.jsApiKey = options2.jsApiKey;
    mustNotBeNull(this.webApiKey, "WebApi\u5FC5\u987B\u586B\u5199");
  }
  async placeSearch(keyword, currentLocation, searchLimit, pageIndex = 1, pageSize = 30) {
    var _a;
    this.logger.debug(`\u641C\u7D22\u4F4D\u7F6E\uFF1A${JSON.stringify(currentLocation)} limit: ${searchLimit}`);
    const key = this.webApiKey;
    const takeRegion = () => {
      if (!currentLocation) {
        return void 0;
      }
      if (searchLimit === "city") {
        return currentLocation.cityname;
      }
      if (searchLimit === "province") {
        return currentLocation.pname;
      }
    };
    const req = {
      key,
      keywords: keyword,
      page_num: pageIndex,
      page_size: pageSize,
      region: takeRegion()
    };
    this.logger.debug(`\u8BF7\u6C42\u641C\u7D22\uFF1A ${JSON.stringify(req)}`);
    let res = await this.api.get("v2/rest/services/service/infra/area/gd/poi", req);
    res = res == null ? void 0 : res.res;
    console.log("res", res);
    const isResOk = () => {
      return res.info === "OK";
    };
    const isResEmpty = () => {
      return res.info === "OK" && res.pois.length === 0;
    };
    const getNextLimit = () => {
      if (searchLimit === "none") {
        return "city";
      }
      if (searchLimit === "city") {
        return "province";
      }
      return void 0;
    };
    if (isResEmpty()) {
      this.logger.debug(`\u8FD4\u56DE\u7ED3\u679C\u7A7A`);
      const limit = getNextLimit();
      if (limit === void 0) {
        this.logger.debug(`\u4E0D\u80FD\u7EE7\u7EED\u6269\u5927\u8303\u56F4\uFF0C\u641C\u7D22\u7ED3\u675F`);
        return res;
      }
      this.logger.debug(`\u6269\u5927\u641C\u7D22\u8303\u56F4: ${limit}`);
      return await this.placeSearch(keyword, currentLocation, limit, pageIndex, pageSize);
    }
    if (isResOk()) {
      return (_a = res.pois) == null ? void 0 : _a.map((poi) => {
        const { location, ...rest } = poi;
        const l2 = location.split(",");
        return {
          ...rest,
          longitude: Number(l2[0]),
          latitude: Number(l2[1])
        };
      });
    }
    return void 0;
  }
  async placeGeo(address, city) {
    const key = this.webApiKey;
    const req = {
      key,
      address
    };
    if (city) {
      req.city = city;
    }
    const takeLngLat = (res) => {
      const location = res.geocodes[0].location;
      const [longitude, latitude] = location.split(",").map((item) => Number(item));
      return {
        latitude,
        longitude
      };
    };
    try {
      const res = await this.api.get("v3/geocode/geo?parameters", req, {
        baseURL: BfProperties.get(
          _BfAbstractPlaceService.PROP_API_PREFIX,
          "https://restapi.amap.com"
        )
      });
      if (res.status === "1" && res.count > 0) {
        return takeLngLat(res);
      }
      this.logger.error("\u8BF7\u6C42\u9AD8\u5FB7\u670D\u52A1\u5931\u8D25\uFF1A " + res.info);
      return null;
    } catch (e) {
      this.logger.error("\u8BF7\u6C42\u9AD8\u5FB7\u670D\u52A1\u5931\u8D25", e);
      return null;
    }
  }
  async placeRegeo(longitude, latitude) {
    const key = this.webApiKey;
    const req = {
      key,
      location: `${longitude},${latitude}`
    };
    this.logger.debug(`\u5730\u7406\u53CD\u7F16\u7801\uFF1A ${req}`);
    const res = await this.api.get("v3/geocode/regeo", req, {
      baseURL: BfProperties.get(_BfAbstractPlaceService.PROP_API_PREFIX, "https://restapi.amap.com")
    });
    if (res.info === "OK") {
      const address = res.regeocode.addressComponent;
      return {
        cityname: address.city,
        pname: address.province,
        adname: address.district,
        longitude,
        latitude
      };
    }
    this.logger.error(`\u7F16\u7801\u51FA\u9519:${JSON.stringify(res)}`);
    throw new Error("\u7F16\u7801\u51FA\u9519");
  }
  async getCurrentLocation() {
    const position = await this.getCurrentPosition();
    this.logger.debug(`\u83B7\u53D6\u5B9A\u4F4D\uFF1A ${JSON.stringify(position)}`);
    return await this.placeRegeo(position.location.longitude, position.location.latitude);
  }
  createLogger() {
    return BfLog4js.getLogger("placeService");
  }
};
let BfAbstractPlaceService = _BfAbstractPlaceService;
__publicField(BfAbstractPlaceService, "PROP_API_PREFIX", "place.apiprefix");
class BfPreferenceSet {
  constructor(values) {
    __publicField(this, "keyValuesMap", /* @__PURE__ */ new Map());
    if (isNil(values)) {
      return;
    }
    values.forEach((item) => {
      this.keyValuesMap.set(item.key, item.value);
    });
  }
  toDTO() {
    const keyValues = [];
    this.keyValuesMap.forEach((value, key) => {
      keyValues.push({
        key,
        value
      });
    });
    return keyValues;
  }
  get(key) {
    return this.keyValuesMap.get(key);
  }
  has(key) {
    return this.keyValuesMap.has(key);
  }
  hasKeys(keys2) {
    return keys2.every((key) => this.has(key));
  }
  set(key, value) {
    this.keyValuesMap.set(key, value);
  }
  clear() {
    this.keyValuesMap.clear();
  }
  clearKeys(keys2) {
    if (isNil(keys2)) {
      return;
    }
    keys2.forEach((key) => {
      this.keyValuesMap.delete(key);
    });
  }
  update(values) {
    values.forEach((item) => {
      this.keyValuesMap.set(item.key, item.value);
    });
  }
  getAll() {
    const values = {};
    this.keyValuesMap.forEach((value, key, map) => {
      values[key] = value;
    });
    return values;
  }
  getByKeys(keys2) {
    const values = {};
    keys2.forEach((key) => {
      values[key] = this.keyValuesMap.get(key);
      if (isNil(values[key]))
        ;
    });
    return values;
  }
}
class BfPreferenceService extends BfRestService {
  constructor() {
    super(...arguments);
    __publicField(this, "cache", new BfPreferenceSet(null));
  }
  async get(key, strategy) {
    if (strategy === "local-only") {
      const value2 = this.cache.get(key);
      this.fetchAndUpdateCache([key]).then();
      return value2;
    }
    if (strategy === "remote-only") {
      const values = await this.fetchPreference([key]);
      return values ? values[0].value : null;
    }
    const value = this.cache.get(key);
    if (value) {
      return value;
    }
    await this.fetchAndUpdateCache([key]);
    return this.cache.get(key);
  }
  async getAll() {
    const values = await this.fetchAllPreference();
    if (values) {
      this.cache = new BfPreferenceSet(values);
    } else {
      this.cache.clear();
    }
    return this.cache.getAll();
  }
  async getMultiple(keys2, strategy) {
    if (strategy === "local-only") {
      const value = this.cache.getByKeys(keys2);
      this.fetchAndUpdateCache(keys2).then();
      return value;
    }
    if (strategy === "remote-only") {
      return await this.fetchPreference(keys2);
    }
    if (this.cache.hasKeys(keys2)) {
      return this.cache.getByKeys(keys2);
    }
    await this.fetchAndUpdateCache(keys2);
    return this.cache.getByKeys(keys2);
  }
  async set(key, value) {
    await this.updatePreference([{ key, value }]);
    this.cache.set(key, value);
  }
  async setMultiple(values) {
    const dto = [];
    Object.keys(values).forEach((key) => {
      dto.push({
        key,
        value: values[key]
      });
    });
    await this.updatePreference(dto);
    this.cache.update(dto);
  }
  async fetchAndUpdateCache(keys2) {
    const values = await this.fetchPreference(keys2);
    if (values) {
      this.cache.update(values);
    } else {
      this.cache.clearKeys(keys2);
    }
  }
  clearAllCache() {
    this.cache.clear();
  }
  clearCache(keys2) {
    this.cache.clearKeys(keys2);
  }
  async fetchPreference(keys2) {
    if (isNil(keys2)) {
      return null;
    }
    const res = await this.api.post("v2/rest/services/service/infra/preference/get", {
      keys: keys2
    });
    return res.keyValues;
  }
  async fetchAllPreference() {
    const res = await this.api.post("v2/rest/services/service/infra/preference/getAll", {});
    return res.keyValues;
  }
  async updatePreference(dto) {
    await this.api.post("v2/rest/services/service/infra/preference/update", {
      keyValues: dto
    });
  }
}
const DEF_INITIAL_PRAMS = {
  enableAuth: true
};
class BfAppStore extends BfStore {
  constructor(context) {
    super({
      pluginOptions: {
        devTools: {
          name: "App"
        }
      }
    });
    __publicField(this, "menuStore");
    __publicField(this, "userStore");
    __publicField(this, "authStorageService");
    __publicField(this, "enumService");
    __publicField(this, "messageService");
    __publicField(this, "noticeStore");
    __publicField(this, "systemInfoService");
    __publicField(this, "pushStore");
    __publicField(this, "storageService");
    __publicField(this, "pageSchemaService");
    __publicField(this, "initialParams", DEF_INITIAL_PRAMS);
    __publicField(this, "onPushMessage", async (message) => {
      var _a, _b;
      this.logger.info("\u6536\u5230\u63A8\u9001,\u5237\u65B0\u672A\u8BFB\u6D88\u606F\u5217\u8868: ");
      if (this.noticeStore) {
        await Promise.all([(_a = this.noticeStore) == null ? void 0 : _a.loadUnReadNotices(), (_b = this.noticeStore) == null ? void 0 : _b.loadTopics()]);
      }
      this.onGlobalPushMessage(message);
    });
    this.initialParams = Object.assign({}, DEF_INITIAL_PRAMS, context.initialParams);
    this.menuStore = context.menuStore;
    this.userStore = context.userStore;
    this.authStorageService = context.authStorageService;
    this.enumService = context.enumService;
    this.noticeStore = context.noticeStore;
    this.pushStore = context.pushStore;
    this.storageService = context.storageService;
    this.messageService = context.messageService;
    this.pageSchemaService = context.pageSchemaService;
    this.systemInfoService = context.systemInfoService;
  }
  getInitialState() {
    return {
      initLoadFinished: false,
      initializeSucceed: false,
      initializing: false
    };
  }
  isInitializing() {
    return this.getState().initializing;
  }
  isInitialSucceed() {
    return this.getState().initializeSucceed;
  }
  async initialize() {
    if (this.systemInfoService) {
      try {
        await this.systemInfoService.initialize();
      } catch (e) {
        console.error(e);
      }
    }
    this.logger.info("[initialize]");
    this.setInitializing(true);
    this.setInitializedSucceed(false);
    this.setInitLoadFinished(false);
    this.setInitLoadError(null);
    try {
      await this.initializeServices();
      this.enumService.clearEnumCache();
    } catch (e) {
      this.onCatchInitialException(e);
      return;
    }
    if (!this.initialParams.enableAuth) {
      this.setInitializing(false);
      this.setInitializedSucceed(true);
      this.setInitLoadFinished(true);
      this.logger.info("\u672A\u542F\u7528\u8BA4\u8BC1\u529F\u80FD");
      return;
    }
    const token = this.authStorageService.getToken();
    if (isNil(token)) {
      this.logger.info("token is empty");
      this.setInitializing(false);
      this.setInitializedSucceed(true);
      this.setInitLoadFinished(true);
      return;
    }
    try {
      await this.userStore.prepareByToken();
      await this.afterUserAuthInfoPrepared();
      this.enumService.clearEnumCache();
      this.setInitLoadFinished(true);
      this.setInitializing(false);
      this.setInitializedSucceed(true);
      this.userStore.setLoggedIn(true);
    } catch (e) {
      this.onCatchInitialException(e);
    }
  }
  onCatchInitialException(e) {
    console.error(e);
    const errorMessage = e instanceof Error && e.message ? e.message : "\u521D\u59CB\u5316\u51FA\u9519";
    const errorCode = e instanceof ApiError && e.code ? e.code : void 0;
    this.setInitLoadError({
      message: errorMessage,
      code: errorCode
    });
    this.setInitLoadFinished(true);
    this.setInitializing(false);
    this.setInitializedSucceed(false);
  }
  async afterUserAuthInfoPrepared() {
    await Promise.all([this.fetchMenus(), this.initializeNotice(), this.initializePush()]);
  }
  async initializeServices() {
    this.logger.info("initializeServices");
    const tasks = [
      this.storageService.initialize(),
      this.authStorageService.initialize()
    ];
    if (this.pageSchemaService) {
      tasks.push(this.pageSchemaService.initialize());
    }
    await Promise.all(tasks);
  }
  async login(params) {
    this.setIsDongLogin(true);
    this.logger.info("\u5F00\u59CB\u767B\u5F55");
    try {
      await this.userStore.login(params);
      this.logger.info("\u767B\u5F55\u6210\u529F");
      await this.userStore.prepareByToken();
      this.logger.info("\u8BFB\u53D6\u7528\u6237\u4FE1\u606F\u6210\u529F");
      await this.afterUserAuthInfoPrepared();
      this.enumService.clearEnumCache();
    } catch (e) {
      this.setIsDongLogin(false);
      throw e;
    }
    const needSetPwd = await this.shouldRestWeekPwd(params);
    if (needSetPwd) {
      this.showPwdSettingPage();
      this.setIsDongLogin(false);
      return;
    }
    this.setIsDongLogin(false);
    await this.afterLoginSuccess();
  }
  async loginByToken(token) {
    this.setIsDongLogin(true);
    this.logger.info("\u5F00\u59CB\u767B\u5F55");
    try {
      await this.userStore.loginByToken(token);
      this.logger.info("\u767B\u5F55\u6210\u529F");
      await this.userStore.prepareByToken();
      this.logger.info("\u8BFB\u53D6\u7528\u6237\u4FE1\u606F\u6210\u529F");
      await this.afterUserAuthInfoPrepared();
      this.enumService.clearEnumCache();
    } catch (e) {
      this.setIsDongLogin(false);
      throw e;
    }
    this.setIsDongLogin(false);
    await this.afterLoginSuccess();
  }
  async clearCache() {
    this.getEnumService().clearEnumCache();
    await this.afterCacheClearSuccess();
  }
  async shouldRestWeekPwd(params) {
    const ok = this.validateWeekPwd(params);
    if (ok) {
      return false;
    }
    return await this.showSettingPwdSkipConfirm(params);
  }
  async fetchMenus() {
    const menus = await this.menuStore.fetchAccessibleMenus(this.userStore.getPermissionIds());
    this.logger.info("\u83B7\u53D6\u7528\u6237\u83DC\u5355\u6210\u529F");
    if (isEmpty$1(menus)) {
      this.setIsDongLogin(false);
      this.messageService.showError("\u6CA1\u6709\u5206\u914D\u8BBF\u95EE\u6743\u9650\uFF0C\u8BF7\u8054\u7CFB\u7BA1\u7406\u5458");
      throwRuntimeError(TwRTErrorCodes.NO_MENU_PERMISSION);
    }
  }
  async initializeNotice() {
    var _a, _b;
    try {
      await ((_a = this.noticeStore) == null ? void 0 : _a.initialize());
      (_b = this.noticeStore) == null ? void 0 : _b.loadUnReadNotices().then();
      this.logger.info("\u521D\u59CB\u5316\u6D88\u606F\u901A\u77E5");
    } catch (e) {
      throwRuntimeError(TwRTErrorCodes.NOTICE_INIT_ERR);
    }
  }
  async initializePush() {
    var _a;
    if (this.pushStore) {
      this.logger.info("\u521D\u59CB\u5316\u63A8\u9001");
      try {
        await ((_a = this.pushStore) == null ? void 0 : _a.initialize());
        this.logger.info("\u914D\u7F6E\u4E86\u63A8\u9001\u670D\u52A1, \u7ED1\u5B9A\u63A8\u9001\u4E0E\u6D88\u606F\u901A\u77E5");
        this.bindPushNotice();
      } catch (e) {
        throwRuntimeError(TwRTErrorCodes.PUSH_INIT_ERR);
      }
    } else {
      this.logger.info("\u6CA1\u6709\u914D\u7F6E\u63A8\u9001\u670D\u52A1,\u8DF3\u8FC7\u63A8\u9001\u6D88\u606F");
    }
  }
  bindPushNotice() {
    if (!this.pushStore) {
      return;
    }
    this.pushStore.registerMessageCallback(this.onPushMessage);
  }
  async unBindPushNotice(silent) {
    if (!this.pushStore) {
      return;
    }
    this.logger.info("\u89E3\u7ED1\u63A8\u9001\u548C\u6D88\u606F\u901A\u77E5");
    this.pushStore.removeMessageCallback(this.onPushMessage);
  }
  onGlobalPushMessage(message) {
    this.logger.info(`\u6536\u5230\u63A8\u9001\u6D88\u606F: type = ${message.type} `);
  }
  async logout(silent = false) {
    await this.beforeLogout(silent);
    await this.userStore.logout(silent);
    await this.afterLogoutSuccess();
    await this.menuStore.onLogout();
  }
  async onGlobalLayoutRouteChange(route) {
    if (await this.checkAuthState()) {
      this.menuStore.onRouteChange(route);
    } else {
      await this.onRoutePermissionCheckFailed(route);
    }
  }
  async checkAuthState() {
    return !isNil(this.authStorageService.getToken());
  }
  async checkRoutePermission(route) {
    if (this.isPublicPath(route)) {
      return true;
    }
    const hasToken = isNil(this.authStorageService.getToken());
    if (!hasToken) {
      await this.onRoutePermissionCheckFailed(route);
    }
    return false;
  }
  async onRoutePermissionCheckFailed(route) {
    await this.userStore.logout();
    const routeService = this.getRouteService();
    const loginPath = BfAppContext.getInstance().getLoginPath();
    await routeService.replace({
      path: loginPath
    });
  }
  async afterLoginSuccess() {
    await this.goEntryPage();
    this.userStore.setLoggedIn(true);
  }
  async goEntryPage() {
    const entry = this.menuStore.getEntryPath();
    await this.getRouteService().replace({
      path: entry
    });
  }
  async beforeLogout(silent) {
    var _a;
    try {
      await ((_a = this.pushStore) == null ? void 0 : _a.destroyService(silent));
    } catch (e) {
      this.logger.error("\u63A8\u9001\u9500\u6BC1\u5931\u8D25");
      this.logger.error(e);
    }
    await this.unBindPushNotice(silent);
  }
  async afterLogoutSuccess() {
    this.menuStore.onLogout();
    const loginUrl = BfAppContext.getInstance().getLoginPath();
    await BfAppContext.getInstance().getRouteService().replace({
      path: loginUrl
    });
  }
  async afterCacheClearSuccess() {
    const message = this.getMessageService();
    message.showSuccess("\u7F13\u5B58\u6E05\u7406\u6210\u529F");
  }
  setIsDongLogin(doing) {
    this.setState(
      {
        isDoingLogin: doing
      },
      false,
      "\u662F\u5426\u6B63\u5728\u6267\u884C\u767B\u5F55"
    );
  }
  setInitLoadFinished(finished) {
    this.setState(
      {
        initLoadFinished: finished
      },
      false,
      "\u8BBE\u7F6E\u662F\u5426\u521D\u59CB\u5316\u5B8C\u6210"
    );
  }
  setInitializing(initializing) {
    this.setState(
      {
        initializing
      },
      false,
      "\u8BBE\u7F6E\u662F\u5426\u6B63\u5728\u521D\u59CB\u5316\u4E2D"
    );
  }
  setInitializedSucceed(initializeSucceed) {
    this.setState(
      {
        initializeSucceed
      },
      false,
      "\u8BBE\u7F6E\u662F\u5426\u6B63\u5728\u521D\u59CB\u5316\u6210\u529F"
    );
  }
  setInitLoadError(error) {
    this.setState(
      {
        initLoadError: error
      },
      false,
      "\u8BBE\u7F6E\u662F\u5426\u521D\u59CB\u5316\u9519\u8BEF"
    );
  }
  createLogger() {
    return BfLog4js.getLogger("appStore");
  }
  getEnumService() {
    return BfAppContext.getInstance().getEnumService();
  }
  getMessageService() {
    return BfAppContext.getInstance().getMessageService();
  }
  getRouteService() {
    return BfAppContext.getInstance().getRouteService();
  }
  onNoticeBellClick() {
  }
  async onEditPwdClick() {
  }
}
var BfMenuPermissionControlType = /* @__PURE__ */ ((BfMenuPermissionControlType2) => {
  BfMenuPermissionControlType2["client"] = "client";
  BfMenuPermissionControlType2["server"] = "server";
  return BfMenuPermissionControlType2;
})(BfMenuPermissionControlType || {});
class BfMenuProvider extends AbstractBoTreeDataProvider {
  constructor(menus, needPermission) {
    super(menus);
    __publicField(this, "permissionIds", []);
    __publicField(this, "needPermission", true);
    this.needPermission = needPermission;
  }
  setPermissions(permissionIds) {
    this.permissionIds = permissionIds;
  }
  getAccessibleItems() {
    const predicate = (item) => {
      var _a;
      if ((item == null ? void 0 : item.type) == "menu" && !((_a = item.children) == null ? void 0 : _a.length)) {
        return false;
      }
      return this.isMenuMatchPermission(item);
    };
    return this.filterTree(cloneDeep(this.items), predicate);
  }
  filterTree(nodes, predicate) {
    if (!(nodes == null ? void 0 : nodes.length)) {
      return nodes;
    }
    return nodes == null ? void 0 : nodes.filter((it) => {
      if (it.type === "menu") {
        it.children = this.filterTree(it.children, predicate);
      }
      return predicate(it);
    });
  }
  getVisibleItems() {
    const predicate = (item) => {
      var _a;
      if ((item == null ? void 0 : item.type) === "menu") {
        return this.isMenuVisible(item) && !!((_a = item.children) == null ? void 0 : _a.length) && this.isMenuMatchPermission(item);
      }
      return true;
    };
    return this.filterTree(cloneDeep(this.items), predicate);
  }
  getVisiblePureMenus() {
    const items = this.getVisibleItems();
    const filter = (menu) => {
      return menu.type === "menu";
    };
    return treeFilter(items, filter, {
      childrenKey: "children"
    });
  }
  isMenuMatchPermission(menu) {
    if (!this.needPermission) {
      return true;
    }
    if (isEmpty$1(menu.access)) {
      return true;
    }
    return this.permissionIds.some((item) => item.startsWith(menu.access));
  }
  isMenuVisible(menu) {
    var _a;
    return (_a = menu.visible) != null ? _a : true;
  }
  internalGetKey(data) {
    return data.treePath;
  }
  isSelectable(data) {
    return true;
  }
}
class BfMenuStore extends BfStore {
  constructor(context) {
    var _a, _b, _c;
    super({
      pluginOptions: {
        devTools: {
          name: "\u5E94\u7528\u5168\u5C40\u83DC\u5355"
        }
      }
    });
    __publicField(this, "menuProvider");
    __publicField(this, "menuService");
    __publicField(this, "displayMode");
    __publicField(this, "_isTabSupported", false);
    __publicField(this, "menuPermissionControl", BfMenuPermissionControlType.client);
    __publicField(this, "tabCloseBlocker", {});
    this.displayMode = (_a = context.displayMode) != null ? _a : "topSide";
    this.menuService = context.menuService;
    this._isTabSupported = (_b = context.isTabSupported) != null ? _b : false;
    this.menuPermissionControl = (_c = context.menuPermissionControl) != null ? _c : BfMenuPermissionControlType.client;
  }
  get isTabSupported() {
    return this._isTabSupported;
  }
  getInitialState() {
    return {
      tabs: [],
      allMenuList: [],
      visibleMenuList: [],
      isSideMenuCollapsed: false,
      topMenus: [],
      sideMenus: [],
      entryPage: void 0
    };
  }
  onTopMenuSelect(menu, autoOpen) {
    var _a;
    const { allMenuList } = this.getState();
    const curTopMenu = this.menuService.getActiveTopLevelMenu(allMenuList, menu.treePath);
    const first = (_a = curTopMenu == null ? void 0 : curTopMenu.children) == null ? void 0 : _a[0];
    if (autoOpen || (first == null ? void 0 : first.type) === "page") {
      this.onMenuSelect(menu);
    } else {
      this.setTopMenuId(menu.id);
      const sideMenus = curTopMenu == null ? void 0 : curTopMenu.children;
      if (isEmpty$1(sideMenus)) {
        this.setSideMenus(void 0);
        this.setSideMenus(void 0);
      } else {
        this.setSideMenus(this.menuService.getPureMenuNodeList(sideMenus));
        this.setNeedSelectSubMenu(true);
      }
    }
  }
  onMenuSelect(menu) {
    var _a;
    this.setNeedSelectSubMenu(false);
    const pageNode = this.getIndexPageOfMenu(menu.treePath);
    warnEmpty(pageNode, "\u83DC\u5355\u4E0B\u6CA1\u6709\u53EF\u4EE5\u8BBF\u95EE\u7684\u9875\u9762");
    if (!pageNode) {
      return;
    }
    const path = (_a = pageNode.path) != null ? _a : pageNode.treePath;
    const routeService = this.getRouteService();
    const location = routeService.getLocation();
    const fullPath = location.pathname + location.search;
    if (fullPath == path) {
      routeService.replace({
        path
      });
    } else {
      routeService.push({
        path
      });
    }
  }
  getRouteService() {
    return BfAppContext.getInstance().getRouteService();
  }
  getMenu(treePath) {
    mustNotBeNull(this.menuProvider, "\u83DC\u5355\u672A\u83B7\u53D6");
    return this.menuProvider.getItemByKey(treePath);
  }
  getIndexPageOfMenu(treePath) {
    const { allMenuList } = this.getState();
    const menu = treeFind(allMenuList, (item) => item.treePath === treePath);
    if (!(menu == null ? void 0 : menu.children)) {
      return void 0;
    }
    let target;
    treeTraverse(
      menu.children,
      (item) => {
        if (target) {
          return;
        }
        if (item.type === "page") {
          target = item;
        }
      },
      {
        childrenKey: "children"
      }
    );
    return target;
  }
  needPermissionIds() {
    return this.menuPermissionControl == BfMenuPermissionControlType.client;
  }
  async fetchAccessibleMenus(permissionIds) {
    const menus = await this.fetchMenus();
    if (!menus || !(menus == null ? void 0 : menus.length)) {
      throwRuntimeError(TwRTErrorCodes.MENU_ILLEGAL, JSON.stringify(menus));
    }
    const needPermission = this.needPermissionIds();
    this.menuProvider = this.creatMenuProvider(menus, needPermission);
    if (needPermission) {
      this.menuProvider.setPermissions(permissionIds);
    }
    const accessibleItems = this.menuProvider.getAccessibleItems();
    const visibleMenuItems = this.menuProvider.getVisiblePureMenus();
    this.setState({
      allMenuList: accessibleItems,
      visibleMenuList: visibleMenuItems,
      entryPage: void 0
    });
    this.afterGetMenus(visibleMenuItems);
    return accessibleItems;
  }
  async fetchMenus() {
    return await this.menuService.fetchMenus();
  }
  afterGetMenus(menus) {
    this.genHomeTab();
    if (this.displayMode === "topSide") {
      const topLevel = this.menuService.getTopLevelMenus(menus);
      this.setTopMenus(topLevel);
      return;
    }
    this.setSideMenus(menus);
  }
  genHomeTab() {
    const entryPage = this.getEntryPage();
    mustNotBeNull(entryPage, "\u5165\u53E3\u9875\u9762\u4E3A\u7A7A");
    const routePath = entryPage.treePath;
    this.setTabs([
      {
        key: routePath,
        menu: entryPage,
        route: {
          path: routePath
        },
        title: "\u9996\u9875",
        closeable: false
      }
    ]);
  }
  getEntryPage() {
    if (!this.getState().entryPage) {
      const { allMenuList } = this.getState();
      mustNotBeNull(allMenuList, "\u83DC\u5355\u5217\u8868\u4E3A\u7A7A");
      const entry = this.menuService.getFirstChildPage(allMenuList[0]);
      this.setState({
        entryPage: entry
      });
    }
    return this.getState().entryPage;
  }
  getEntryPath() {
    var _a;
    const entry = (_a = this.getEntryPage()) == null ? void 0 : _a.treePath;
    mustNotBeNull(entry, "entry is null");
    return entry;
  }
  setTopMenus(menus) {
    this.setState(
      {
        topMenus: menus
      },
      false,
      "\u8BBE\u7F6E\u9876\u90E8\u83DC\u5355"
    );
  }
  setSideMenus(menus) {
    this.setState(
      {
        sideMenus: menus
      },
      false,
      "\u8BBE\u7F6E\u4FA7\u8FB9\u83DC\u5355"
    );
  }
  onRouteChange(route) {
    console.log("[tab] menuStore onRouteChange");
    if (this.displayMode === "topSide") {
      this.doPathChangeOfSideTopMode(route);
      return;
    }
    this.doPathChangeOfSideMode(route);
  }
  doPathChangeOfSideMode(route) {
    var _a;
    const path = route.path;
    const { allMenuList, sideMenuId } = this.getState();
    const selectedNode = (_a = this.menuProvider) == null ? void 0 : _a.getItemByKey(path);
    if (!selectedNode) {
      return;
    }
    const rootPath = this.menuService.getFirstSlashPath(path);
    mustNotBeNull(rootPath, "\u5F53\u524D\u6FC0\u6D3B\u7684\u83DC\u5355\u5BF9\u5E94\u7684\u6839\u8DEF\u5F84\u4E3A\u7A7A");
    const curSideMenu = this.menuService.getActiveMenuOfPagePath(allMenuList, path);
    this.logger.debug("curSideMenu: " + (curSideMenu == null ? void 0 : curSideMenu.treePath));
    if (!curSideMenu) {
      this.logger.error("root menu \u4E3A\u7A7A");
      return;
    }
    mustNotBeNull(curSideMenu, "root menu \u4E3A\u7A7A");
    const isSame = sideMenuId === curSideMenu.treePath;
    if (!isSame) {
      this.setSideMenuId(curSideMenu.treePath);
    }
    if (selectedNode) {
      if (selectedNode.type === "page") {
        this.setCurrentPageNode(selectedNode);
        this.updateTab(selectedNode, route);
      }
    } else {
      this.setCurrentPageNode(void 0);
    }
  }
  doPathChangeOfSideTopMode(route) {
    var _a;
    const path = route.path;
    const { allMenuList, topMenuId } = this.getState();
    const selectedNode = (_a = this.menuProvider) == null ? void 0 : _a.getItemByKey(path);
    if (!selectedNode) {
      return;
    }
    const rootPath = this.menuService.getFirstSlashPath(path);
    mustNotBeNull(rootPath, "\u5F53\u524D\u6FC0\u6D3B\u7684\u83DC\u5355\u5BF9\u5E94\u7684\u6839\u8DEF\u5F84\u4E3A\u7A7A");
    const curTopMenu = this.menuService.getActiveTopLevelMenu(allMenuList, rootPath);
    this.logger.debug("curTopMenu: " + (curTopMenu == null ? void 0 : curTopMenu.treePath));
    mustNotBeNull(curTopMenu, "root menu \u4E3A\u7A7A");
    const isSameTop = topMenuId === curTopMenu.treePath;
    if (!isSameTop) {
      this.setTopMenuId(curTopMenu.treePath);
    }
    const sideMenus = curTopMenu == null ? void 0 : curTopMenu.children;
    if (isEmpty$1(sideMenus)) {
      this.setSideMenus(void 0);
      this.setSideMenuId(void 0);
    } else {
      if (!isSameTop) {
        this.setSideMenus(this.menuService.getPureMenuNodeList(sideMenus));
      }
      const activeSideMenu = this.menuService.getActiveMenuOfPagePath(sideMenus, path);
      this.setSideMenuId(activeSideMenu == null ? void 0 : activeSideMenu.treePath);
    }
    if (selectedNode) {
      if (selectedNode.type === "page") {
        this.setCurrentPageNode(selectedNode);
        this.updateTab(selectedNode, route);
      }
    } else {
      this.setCurrentPageNode(void 0);
    }
  }
  getMenuPageOfPath(path) {
    var _a;
    if (!path) {
      return void 0;
    }
    const selectedNode = (_a = this.menuProvider) == null ? void 0 : _a.getItemByKey(path);
    if (!selectedNode) {
      return void 0;
    }
    if (selectedNode.type !== "page") {
      return;
    }
    return selectedNode;
  }
  toggleSideMenu() {
    const isSideMenuCollapsed = this.getState().isSideMenuCollapsed;
    this.setState(
      {
        isSideMenuCollapsed: !isSideMenuCollapsed
      },
      false,
      "\u8BBE\u7F6E\u83DC\u5355\u5C55\u5F00\u6298\u53E0\u72B6\u6001"
    );
  }
  setTopMenuId(topMenuId) {
    this.setState(
      {
        topMenuId
      },
      false,
      "\u66F4\u65B0\u5F53\u524D\u9876\u90E8\u83DC\u5355Id"
    );
  }
  setSideMenuId(sideMenuId) {
    this.setState(
      {
        sideMenuId
      },
      false,
      "\u66F4\u65B0\u5F53\u524D\u4FA7\u8FB9\u83DC\u5355Id"
    );
  }
  setNeedSelectSubMenu(needSelectSubMenu) {
    this.setState(
      {
        needSelectSubMenu
      },
      false,
      "\u9700\u8981\u9009\u62E9\u4E0B\u7EA7\u83DC\u5355"
    );
  }
  setCurrentPageNode(page) {
    this.setState(
      {
        currentPage: page
      },
      false,
      "\u8BBE\u7F6E\u5F53\u524D\u9875\u9762"
    );
  }
  onLogout() {
    this.setState(this.getInitialState());
  }
  creatMenuProvider(menus, needPermission = true) {
    return new BfMenuProvider(menus, needPermission);
  }
  createLogger() {
    return BfLog4js.getLogger("menuStore");
  }
  updateTab(menu, route) {
    if (!this.isTabSupported) {
      return;
    }
    const queryStr = this.queryToString(route.query);
    const key = queryStr ? route.path + "?" + queryStr : route.path;
    const tabs = this.getState().tabs;
    const index = tabs.findIndex((item) => item.key === key);
    const targetTab = index === -1 ? {
      key,
      route,
      menu,
      title: menu.name,
      closeable: true
    } : tabs[index];
    if (index === -1) {
      const newTabs = tabs.slice(0);
      newTabs.push(targetTab);
      this.setTabs(newTabs);
    }
    this.setCurrentTab(targetTab);
  }
  async onTabsClose(tabs) {
    if (!this.isTabSupported) {
      return;
    }
    const toCloseTabs = [];
    for (let i2 = 0; i2 < tabs.length; i2++) {
      const tab = tabs[i2];
      const block = await this.checkTabCloseBlocker(tab.key);
      if (block) {
        this.closeTabs(toCloseTabs);
        this.setCurrentTab(tab);
        await this.getRouteService().replace(tab.route);
        return;
      }
      if (!block) {
        toCloseTabs.push(tab);
      }
    }
    this.closeTabs(toCloseTabs);
    const otherTabs = this.getState().tabs;
    const last2 = otherTabs[otherTabs.length - 1];
    await this.getRouteService().replace(last2.route);
  }
  closeTabs(tabs) {
    if (!this.isTabSupported) {
      console.log("[menu] closeTabs \u65E0\u6548\uFF0C\u4E0D\u652F\u6301TAB");
      return;
    }
    if (!(tabs == null ? void 0 : tabs.length)) {
      return;
    }
    const stateTabs = this.getState().tabs;
    const keys2 = tabs.map((item) => item.key);
    const otherTabs = stateTabs.filter((item) => !keys2.includes(item.key));
    this.setTabs(otherTabs);
  }
  closeTabByKey(key) {
    if (!this.isTabSupported) {
      console.log("[menu] closeTabByKey \u65E0\u6548\uFF0C\u4E0D\u652F\u6301TAB");
      return;
    }
    const stateTabs = this.getState().tabs;
    const otherTabs = stateTabs.filter((item) => item.key !== key);
    this.setTabs(otherTabs);
    const last2 = otherTabs[otherTabs.length - 1];
    this.getRouteService().replace(last2.route);
  }
  closeCurrentTab() {
    if (!this.isTabSupported) {
      console.log("[menu] closeCurrentTab \u65E0\u6548\uFF0C\u4E0D\u652F\u6301TAB");
      return;
    }
    const current = this.getState().currentTab;
    if (!current) {
      console.error("\u5F53\u524D\u6CA1\u6709\u9875\u7B7E");
      return;
    }
    this.checkTabCloseBlocker(current.key).then((block) => {
      if (block) {
        this.logger.debug("\u9875\u7B7E\u5173\u95ED\u88AB\u963B\u65AD");
        return;
      }
      this.closeTabByKey(current.key);
    });
  }
  async checkTabCloseBlocker(tabKey) {
    const blockers = this.tabCloseBlocker[tabKey];
    if (!blockers) {
      return false;
    }
    for (const blocker of blockers) {
      const shouldBlock = await blocker(tabKey);
      if (shouldBlock) {
        return true;
      }
    }
    return false;
  }
  onTabActive(tab) {
    if (!this.isTabSupported) {
      return;
    }
    this.getRouteService().replace(tab.route);
  }
  setTabTitleByKey(key, title, style) {
    if (!this.isTabSupported) {
      console.log("[menu] setCurrentTabTitle \u65E0\u6548\uFF0C\u4E0D\u652F\u6301TAB");
      return;
    }
    this.setState((state) => {
      const index = state.tabs.findIndex((item) => item.key === key);
      if (index == -1) {
        return state;
      }
      if (state.currentTab && state.currentTab.key === key) {
        state.currentTab.title = title;
      }
      state.tabs[index].title = title;
      state.tabs[index].style = style;
      return state;
    });
  }
  setCurrentTabTitle(title, style) {
    const currentTab = this.getState().currentTab;
    if (!currentTab) {
      console.log("[menu] currentTab \u4E3A\u7A7A\uFF0C\u66F4\u65B0\u6807\u9898\u5931\u8D25");
      return;
    }
    this.setTabTitleByKey(currentTab.key, title, style);
  }
  addCurrentTabCloseBlocker(blocker) {
    if (!this.isTabSupported) {
      return void 0;
    }
    const currentTab = this.getState().currentTab;
    if (!currentTab) {
      console.error("\u5F53\u524D\u9875\u7B7E\u6CA1\u6709");
      return;
    }
    if (!this.tabCloseBlocker[currentTab.key]) {
      this.tabCloseBlocker[currentTab.key] = /* @__PURE__ */ new Set();
    }
    this.tabCloseBlocker[currentTab.key].add(blocker);
    return () => {
      this.tabCloseBlocker[currentTab.key].delete(blocker);
    };
  }
  setTabs(tabs) {
    const keepAliveTabKeyList = tabs.filter((item) => item.menu.cacheable !== false).map((item) => item.key);
    this.setState(
      (state) => {
        state.tabs = tabs;
        if (!isEqual(keepAliveTabKeyList, state.keepAliveTabKeyList)) {
          state.keepAliveTabKeyList = keepAliveTabKeyList;
        }
        return state;
      },
      false,
      "\u66F4\u65B0\u6807\u7B7E\u5217\u8868"
    );
  }
  setCurrentTab(tab) {
    this.setState(
      {
        currentTab: tab
      },
      false,
      "\u66F4\u65B0\u6FC0\u6D3B\u7684\u6807\u7B7E"
    );
  }
  queryToString(query) {
    if (!query) {
      return "";
    }
    const strArr = [];
    Object.keys(query).forEach((key) => {
      const pair = encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
      strArr.push(pair);
    });
    return strArr.join("&");
  }
}
class BfUserStore extends BfStore {
  constructor(context) {
    super({
      pluginOptions: {
        devTools: {
          name: "\u7528\u6237"
        }
      }
    });
    __publicField(this, "autoStorageService");
    __publicField(this, "userService");
    this.autoStorageService = context.authStorageService;
    this.userService = context.userService;
  }
  getInitialState() {
    return {
      userInfo: {}
    };
  }
  async login(params) {
    this.setLoginLoading(true);
    let token;
    try {
      token = await this.userService.requestLogin(params);
    } catch (e) {
      this.setLoginLoading(false);
      throw e;
    }
    if (!token) {
      console.error("token is null");
      this.setLoginLoading(false);
      return;
    }
    this.setUserToken(token);
    this.setLoginLoading(false);
  }
  async loginByToken(token) {
    this.setLoginLoading(true);
    this.setUserToken(token);
    this.setLoginLoading(false);
  }
  async readUserInfo() {
    const res = await this.userService.fetchUserInfo();
    this.setUserInfo(res);
    return res;
  }
  getPermissionIds() {
    var _a;
    return (_a = this.getState().userInfo.permissionIds) != null ? _a : [];
  }
  async logout(silent = false) {
    if (!silent) {
      await promiseSafeRun(async () => {
        await this.userService.requestLogout();
      });
    }
    this.setLoggedIn(false);
    this.autoStorageService.removeToken();
  }
  async prepareByToken() {
    await this.readUserInfo();
  }
  setUserToken(token) {
    if (isNil(token)) {
      this.autoStorageService.removeToken();
    } else {
      this.autoStorageService.setToken(token);
    }
  }
  setUserInfo(userInfo) {
    this.setState(
      {
        userInfo
      },
      false,
      "\u66F4\u65B0\u7528\u6237\u4FE1\u606F"
    );
  }
  setLoginLoading(loading) {
    this.setState(
      {
        loginLoading: loading
      },
      false,
      "\u66F4\u65B0\u767B\u5F55\u4E2D\u72B6\u6001"
    );
  }
  setLoggedIn(loggedIn) {
    this.setState(
      {
        loggedIn
      },
      false,
      "\u8BBE\u7F6E\u7528\u6237\u5DF2\u767B\u5F55"
    );
  }
}
class BfNoticeStore extends BfStore {
  constructor(context) {
    super({
      pluginOptions: {
        devTools: {
          name: "\u6D88\u606F\u901A\u77E5"
        }
      }
    });
    __publicField(this, "apiService");
    this.apiService = context.apiService;
  }
  getInitialState() {
    return {
      initLoading: true,
      noticeListPage: this.getDefaultNoticePage()
    };
  }
  async initialize() {
    this.setInitLoading(true);
    await promiseSafeRun(async () => {
      await this.loadTopics();
    });
    this.setInitLoading(false);
    setTimeout(() => {
      this.loadUnReadNotices().then();
    }, 500);
  }
  async loadTopics() {
    this.setLoadingTopics(true);
    await promiseSafeRun(async () => {
      const res = await this.fetchTopics();
      this.setTopics(res.rows);
    });
    this.setLoadingTopics(false);
  }
  setLoadingTopics(loading) {
    this.setState(
      {
        isLoadingTopic: loading
      },
      false,
      "\u52A0\u8F7DTopics\u4E2D"
    );
  }
  isAutoSelectTopic() {
    return true;
  }
  async onTopicSelected(topic) {
    const { topics } = this.getState();
    if (topic && topics) {
      topic = topics.find((item) => item.id == (topic == null ? void 0 : topic.id));
    }
    this.setSelectedTopic(topic);
    await this.requestNoticeReadByTopic(topic);
    await this.reloadNoticeOfTopic(topic);
  }
  async reloadNoticeOfTopic(topic) {
    const { topics } = this.getState();
    if (topic && topics) {
      topic = topics.find((item) => item.id == (topic == null ? void 0 : topic.id));
    }
    this.resetNoticePage();
    await this.loadNextPageNotice();
    await this.noticeReadByTopic(topic);
  }
  async selectedOnTopic() {
    var _a;
    const topic = (_a = this.getState().topics) == null ? void 0 : _a[0];
    if (!topic) {
      return;
    }
    await this.onTopicSelected(topic);
  }
  async loadNextPageNotice() {
    const { selectedTopic, noticeListPage } = this.getState();
    mustNotBeNull(selectedTopic, "\u5F53\u524D\u88AB\u9009\u4E2D\u7684 topic \u4E3A\u7A7A,\u65E0\u6CD5\u52A0\u8F7D notice");
    this.setLoadingNotices(true);
    await promiseSafeRun(async () => {
      const notices = await this.fetchNotices(selectedTopic, noticeListPage);
      this.onLoadNotices(notices);
    });
    this.setLoadingNotices(false);
  }
  onLoadNotices(notices) {
    const { noticeList, noticeListPage } = this.getState();
    const items = noticeList ? noticeList : [];
    const { rows, total } = notices;
    const { pageNo, pageSize } = noticeListPage;
    const newItems = items.concat(rows);
    this.setNotices(newItems);
    const noMorePage = pageSize * pageNo > total;
    if (noMorePage) {
      this.setState((state) => {
        state.noticeListPage.hasMore = false;
        return state;
      });
    } else {
      this.setState((state) => {
        state.noticeListPage.hasMore = true;
        state.noticeListPage.pageNo++;
        return state;
      });
    }
  }
  setNotices(notices) {
    this.setState({
      noticeList: notices
    });
  }
  resetNoticePage() {
    this.setState({
      noticeListPage: this.getDefaultNoticePage(),
      noticeList: void 0
    });
  }
  getDefaultNoticePage() {
    return {
      pageNo: 1,
      pageSize: 10,
      hasMore: true
    };
  }
  setSelectedTopic(topic) {
    this.setState(
      {
        selectedTopic: topic
      },
      false,
      "\u8BBE\u7F6E\u9009\u4E2D\u7684 topic"
    );
  }
  setInitLoading(loading) {
    this.setState(
      {
        initLoading: loading
      },
      false,
      "\u8BBE\u7F6E\u521D\u59CB\u5316\u52A0\u8F7D\u72B6\u6001"
    );
  }
  setTopics(topics) {
    this.setState(
      {
        topics
      },
      false,
      "\u8BBE\u7F6E\u6570\u636Eprovider"
    );
  }
  setLoadingNotices(loading) {
    this.setState(
      {
        isLoadingNotices: loading
      },
      false,
      "\u66F4\u65B0\u662F\u5426\u6B63\u5728\u52A0\u8F7D"
    );
  }
  async noticeReadAll() {
    return await this.apiService.post(`/v2/rest/services/boService/push/notice/readAllMine`);
  }
  async noticeReadByTopic(topic) {
    await this.requestNoticeReadByTopic(topic);
    this.setTopicRead(topic);
  }
  async requestNoticeReadByTopic(topic) {
    await this.apiService.post(
      `/v2/rest/services/boService/push/notice/readByTopic`,
      null,
      {
        topicId: topic.id
      }
    );
    this.setState((state) => {
      if (!state.topics) {
        return state;
      }
      const index = state.topics.findIndex((item) => item.id === topic.id);
      if (index !== -1) {
        state.topics[index].unreadCount = 0;
      }
      return state;
    });
  }
  async fetchTopics() {
    const pageQuery = {
      noPaging: true,
      sorts: [
        {
          field: "latestNoticeTime",
          sortType: SortTypeDTO.DESC
        }
      ]
    };
    return await this.apiService.post(
      "v2/rest/services/boService/push/noticeTopic/getTopicList",
      pageQuery
    );
  }
  async fetchNotices(topic, page) {
    const params = {
      pageNo: page.pageNo,
      pageSize: page.pageSize,
      conditions: [newEqualCondition("noticeTopicId", topic.id)],
      sorts: [
        {
          field: "createTime",
          sortType: SortTypeDTO.DESC
        }
      ]
    };
    return this.apiService.post(`/v2/rest/services/boService/push/notice/query`, params);
  }
  async loadUnReadNotices() {
    const list = await this.fetchUnReadNotices();
    this.setUnreadNotices(list);
  }
  async clearUnReadNotices() {
    this.setUnreadNotices([]);
  }
  async fetchUnReadNotices() {
    const listAllQuery = {
      conditions: [newEqualCondition("isRead", false)]
    };
    const res = await this.apiService.post(
      `/v2/rest/services/boService/push/notice/query`,
      listAllQuery
    );
    return res == null ? void 0 : res.rows;
  }
  setTopicRead(topic) {
    this.setState((state) => {
      var _a;
      const topics = (_a = state.topics) != null ? _a : [];
      const topicIndex = topics == null ? void 0 : topics.findIndex((item) => item.id === topic.id);
      if (topicIndex === -1) {
        console.error("makeTopicRead failed cannot find topic ", topic);
        return state;
      }
      topics[topicIndex].unreadCount = 0;
      state.topics = topics;
      return state;
    });
  }
  setUnreadNotices(notices) {
    this.setState(
      {
        unReadNoticeList: notices
      },
      false,
      "\u8BBE\u7F6E\u672A\u8BFB\u6D88\u606F\u5217\u8868"
    );
  }
}
__publicField(BfNoticeStore, "mapUnReadMsgCount", (state) => {
  var _a;
  if (!((_a = state.topics) == null ? void 0 : _a.length)) {
    return 0;
  }
  return state.topics.reduce((previousValue, currentValue) => {
    var _a2;
    return previousValue + ((_a2 = currentValue == null ? void 0 : currentValue.unreadCount) != null ? _a2 : 0);
  }, 0);
});
class BfPushStore extends BfStore {
  constructor(context) {
    super({
      pluginOptions: {
        devTools: {
          name: "Push"
        }
      }
    });
    __publicField(this, "services");
    this.services = context.services;
  }
  destroy() {
    this.logger.debug("\u9500\u6BC1");
    this.services.forEach((service) => {
      service.clearMessageCallback();
    });
    super.destroy();
  }
  async destroyService(silent) {
    await Promise.all(this.services.map((service) => service.destroyService(silent)));
  }
  async initialize() {
    this.logger.debug("\u521D\u59CB\u5316");
    try {
      await Promise.all(this.services.map((item) => item.initialize()));
    } catch (e) {
      this.logger.error("\u63A8\u9001\u521D\u59CB\u5316\u51FA\u9519", e);
    }
  }
  registerMessageCallback(callback) {
    this.services.forEach((service) => {
      service.registerMessageCallback(callback);
    });
  }
  removeMessageCallback(callback) {
    this.services.forEach((service) => {
      service.removeMessageCallback(callback);
    });
  }
  createLogger() {
    return BfLog4js.getLogger("push");
  }
  getInitialState() {
    return {};
  }
}
var BfListDetailStatus = /* @__PURE__ */ ((BfListDetailStatus2) => {
  BfListDetailStatus2["loading"] = "loading";
  BfListDetailStatus2["error"] = "error";
  BfListDetailStatus2["success"] = "success";
  return BfListDetailStatus2;
})(BfListDetailStatus || {});
class BfAbstractListDetailCacheStore extends BfPageStore {
  getInitialState() {
    return {
      ...super.getInitialState(),
      data: {},
      status: {}
    };
  }
  getData(state, key) {
    return state.data[key];
  }
  getStatus(state, key) {
    return state.status[key];
  }
  contains(key) {
    const { data } = this.getState();
    return !isNil(data[key]);
  }
  async loadIfNeeded(key) {
    const status = this.getStatus(this.getState(), key);
    if (status === BfListDetailStatus.success) {
      return;
    }
    this.logger.debug("\u52A0\u8F7D\u8BE6\u60C5:" + key);
    this.setStatus(key, BfListDetailStatus.loading);
    try {
      const data = await this.doLoad(key);
      this.setData(key, data);
    } catch (e) {
      console.error(e);
      this.setStatus(key, BfListDetailStatus.error);
      return;
    }
    this.setStatus(key, BfListDetailStatus.success);
  }
  setStatus(key, status) {
    this.setState(
      (state) => {
        state.status[key] = status;
        return state;
      },
      false,
      "\u52A0\u8F7D\u72B6\u6001"
    );
  }
  setData(key, data) {
    this.setState(
      (state) => {
        state.data[key] = data;
        return state;
      },
      false,
      "\u66F4\u65B0\u6570\u636E"
    );
  }
}
var BfLoginCodeSendStatus = /* @__PURE__ */ ((BfLoginCodeSendStatus2) => {
  BfLoginCodeSendStatus2["sending"] = "sending";
  BfLoginCodeSendStatus2["afterSending"] = "afterSending";
  BfLoginCodeSendStatus2["idle"] = "idle";
  return BfLoginCodeSendStatus2;
})(BfLoginCodeSendStatus || {});
var BfLoginMethod = /* @__PURE__ */ ((BfLoginMethod2) => {
  BfLoginMethod2["pwd"] = "pwd";
  BfLoginMethod2["captcha"] = "captcha";
  return BfLoginMethod2;
})(BfLoginMethod || {});
const DEFAULT_SEND_TIME_MS = 60 * 1e3;
const DEFAULT_SEND_TIME_UPDATE_MS = 1e3;
class BfLoginStore extends BfStore {
  constructor(params) {
    var _a, _b;
    super({
      pluginOptions: {
        devTools: {
          name: "\u767B\u5F55"
        }
      }
    });
    __publicField(this, "userService");
    __publicField(this, "countDownTimer");
    __publicField(this, "captchaSendTimeMs", DEFAULT_SEND_TIME_MS);
    __publicField(this, "captchaSendUpdatePeriodMs", DEFAULT_SEND_TIME_UPDATE_MS);
    __publicField(this, "appContext");
    __publicField(this, "onCountDownFinish", () => {
      this.updateCaptchaStatus(BfLoginCodeSendStatus.idle);
    });
    __publicField(this, "onCountDownTimerUpdate", (time) => {
      this.updateCaptchaCountdown(time);
    });
    this.appContext = BfAppContext.getInstance();
    this.userService = params.userService;
    this.countDownTimer = new CountDownTimer({
      onFinish: this.onCountDownFinish,
      onTimer: this.onCountDownTimerUpdate
    });
    this.captchaSendTimeMs = (_a = params.captchaSendTimeMs) != null ? _a : DEFAULT_SEND_TIME_MS;
    this.captchaSendUpdatePeriodMs = (_b = params.captchaSendUpdatePeriodMs) != null ? _b : DEFAULT_SEND_TIME_UPDATE_MS;
  }
  getInitialState() {
    return {
      loginMethod: this.getDefaultLoginMethod(),
      formState: this.getDefaultFormState(),
      captchaStatus: {}
    };
  }
  getDefaultLoginMethod() {
    return BfLoginMethod.captcha;
  }
  getDefaultFormState() {
    return {
      userType: this.getDefaultUserType()
    };
  }
  async sendCaptcha() {
    const { formState } = this.getState();
    const params = {
      userType: formState.userType,
      identifier: formState.phone,
      usage: this.getCaptchaUsage()
    };
    this.updateCaptchaStatus(BfLoginCodeSendStatus.sending);
    try {
      await this.requestSendCaptcha(params);
      this.afterSendCaptcha();
    } catch (e) {
      this.logger.error(e);
      console.error(e);
      this.updateCaptchaStatus(BfLoginCodeSendStatus.idle);
    }
  }
  getCaptchaUsage() {
    return newEnumValue("Verify");
  }
  async requestSendCaptcha(params) {
    await this.userService.sendCaptcha(params);
  }
  stopTimer() {
    this.countDownTimer.stop();
  }
  async checkUserNameExits(loginMethod = this.getDefaultLoginMethod()) {
    const { formState } = this.getState();
    const reqUserName = loginMethod === BfLoginMethod.captcha ? formState.phone : formState.userName;
    const params = {
      loginMethod: newEnumValue(loginMethod),
      userName: reqUserName,
      userType: this.getDefaultUserType()
    };
    return await this.requestCheckUserNameExits(params);
  }
  async requestCheckUserNameExits(params) {
    const api = this.appContext.getApiService();
    const res = await api.post("/v2/rest/services/service/res/authorization/checkUserName", params);
    return res == null ? void 0 : res.isExist;
  }
  async login() {
    const { loginMethod, formState } = this.getState();
    const loginParams = {};
    loginParams.loginMethod = newEnumValue(loginMethod);
    loginParams.userType = this.getDefaultUserType();
    loginParams.deviceType = this.getDeviceType();
    if (loginMethod === BfLoginMethod.pwd) {
      this.logger.debug("\u4F7F\u7528\u5BC6\u7801\u767B\u5F55");
      loginParams.userName = formState.userName;
      loginParams.pwd = formState.pwd;
    } else if (loginMethod === BfLoginMethod.captcha) {
      this.logger.debug("\u4F7F\u7528\u9A8C\u8BC1\u7801\u767B\u5F55");
      loginParams.userName = formState.phone;
      loginParams.captcha = formState.captcha;
    }
    await this.doLogin(loginParams);
    this.stopTimer();
  }
  async doLogin(params) {
    const appStore = BfAppContext.getInstance().getAppStore();
    await appStore.login(params);
  }
  getDeviceType() {
    return newEnumValue(BfLoginDeviceType.desktop);
  }
  async beforeLogin() {
  }
  afterSendCaptcha() {
    this.appContext.getMessageService().showSuccess("\u9A8C\u8BC1\u7801\u5DF2\u53D1\u9001");
    this.updateCaptchaStatus(BfLoginCodeSendStatus.afterSending);
    this.updateCaptchaCountdown(this.captchaSendTimeMs);
    this.countDownTimer.start(this.captchaSendTimeMs, this.captchaSendUpdatePeriodMs);
  }
  async isPhoneValidate() {
    const phone = this.getState().formState.phone;
    if (!phone) {
      return false;
    }
    return this.validatePhone(phone);
  }
  validatePhone(phone) {
    return /^1[3-9]\d{9}$/.test(phone);
  }
  isPolicyAgreed() {
    return this.getState().isPolicyAgreed;
  }
  setPolicyAgree(agree) {
    this.setState(
      {
        isPolicyAgreed: agree
      },
      false,
      "\u670D\u52A1\u6761\u6B3E\u540C\u610F\u53D8\u66F4"
    );
  }
  setLoginMethod(method) {
    this.logger.debug("\u5207\u6362\u767B\u5F55\u65B9\u5F0F:" + method);
    this.setState({
      loginMethod: method
    });
  }
  updateLoginForm(form) {
    if (!form) {
      return;
    }
    this.setState(
      (state) => {
        Object.assign(state.formState, form);
        return state;
      },
      false,
      "\u66F4\u65B0\u767B\u5F55\u8868\u5355"
    );
  }
  updateCaptchaStatus(sendingStatus) {
    this.setState(
      (state) => {
        state.captchaStatus.sendingStatus = sendingStatus;
        return state;
      },
      false,
      "\u66F4\u65B0\u9A8C\u8BC1\u7801\u53D1\u9001\u72B6\u6001"
    );
  }
  updateCaptchaCountdown(countdown) {
    this.setState(
      (state) => {
        state.captchaStatus.countdown = countdown;
        return state;
      },
      false,
      "\u66F4\u65B0\u9A8C\u8BC1\u7801\u53D1\u9001\u5012\u8BA1\u65F6"
    );
  }
  createLogger() {
    return BfLog4js.getLogger("login");
  }
}
var BfIntentResultType = /* @__PURE__ */ ((BfIntentResultType2) => {
  BfIntentResultType2["CANCEL"] = "CANCEL";
  BfIntentResultType2["OK"] = "OK";
  return BfIntentResultType2;
})(BfIntentResultType || {});
class BfAbstractIntentNavigator {
  constructor() {
    __publicField(this, "intent");
    __publicField(this, "resolve");
  }
  cancel() {
    if (!this.resolve) {
      return;
    }
    this.resolve({
      type: BfIntentResultType.CANCEL
    });
    this.resolve = void 0;
  }
  finish(data) {
    if (!this.resolve) {
      return;
    }
    this.resolve({
      type: BfIntentResultType.OK,
      data
    });
    this.resolve = void 0;
  }
  getIntent() {
    return this.intent;
  }
  start(intent) {
    const route = BfAppContext.getInstance().getRouteService();
    console.log("Intent Start ", intent);
    this.intent = intent;
    route.push({
      path: this.ROUTE_NAME
    });
    return new Promise((resolve) => {
      this.resolve = resolve;
    });
  }
  get ROUTE_NAME() {
    return "";
  }
}
export {
  APP_RUN_EVT_URL,
  AbstractApiService,
  AbstractAsyncStorageService,
  AbstractBORestApiService,
  AbstractBORestApiService2,
  AbstractBfStatePlugin,
  AbstractBoCommandProvider,
  AbstractBoDataProvider,
  AbstractBoFilterViewProvider,
  AbstractBoIdentifiableProvider,
  AbstractBoLabelProvider,
  AbstractBoListDataProvider,
  AbstractBoPerspectiveProvider,
  AbstractBoTreeDataProvider,
  AbstractDataviewRestApiService,
  AbstractDocRestApiService,
  AbstractFunctionRestApiService,
  AbstractMessageService,
  AbstractRouteService,
  AbstractStorageService,
  AbstractStructureDataService,
  AbstractStructureRestApiService,
  AbstractSystemInfoService,
  ApiError,
  ApiService,
  ApiUtils,
  AreaService,
  ArrayUtils,
  BO_API_BASE_URL,
  BO_AUDIT_HISTORY_BASE_URL,
  Base64Utils,
  BaseStorageService,
  BfAbstractIntentNavigator,
  BfAbstractListDetailCacheStore,
  BfAbstractMenuService,
  BfAbstractPlaceService,
  BfAbstractPushService,
  BfAbstractService,
  BfApiMenuService,
  BfAppContext,
  BfAppStore,
  BfAuthStorageService,
  BfDirectionService,
  BfEditMode,
  BfEnumService,
  BfIntentResultType,
  BfListDetailStatus,
  BfLoginCodeSendStatus,
  BfLoginDeviceType,
  BfLoginMethod,
  BfLoginStore,
  BfMenuPermissionControlType,
  BfMenuStore,
  BfMonitorLogAppender,
  BfMonitorService,
  BfNoticeStore,
  BfObjectRef,
  BfPageMode,
  BfPageStore,
  BfPreferenceService,
  BfPreferenceSet,
  BfProperties,
  BfPushStore,
  BfRestService,
  BfSlice,
  BfStatePluginCommon,
  BfStates,
  BfStore,
  BfUserService,
  BfUserStore,
  BoAttributeTypes,
  BoAuditHistoryService,
  BoCommandAlertType,
  BoCommandTypes,
  BoMemoUtil,
  BoStateService,
  BoValidationTriggerTypes,
  CacheablePageSchemaService,
  ColorUtils,
  ConditionDtoUtils,
  ConditionMatchTypeDTO,
  ConditionRuleDTO,
  ConditionValueTypes,
  CountDownTimer,
  DATAVIEW_API_BASE_URL,
  DOC_API_BASE_URL,
  DataUtils,
  DefaultBoListDataProvider,
  DefaultBoTreeDataProvider,
  DefaultFilterViewProvider,
  DefaultLabelProvider,
  DeviceType,
  ENUM_VALUES_URL,
  EnumUtils,
  FILE_SERVICE_BASE_URL,
  FileServiceBase,
  FileUtils,
  HttpClient,
  HttpLogLevel,
  HttpclientError,
  IdCardUtils,
  Intl,
  LOCALE,
  MapUtils,
  Md5Utils,
  MomentUtils,
  NumberUtils,
  Observable,
  PAGE_SCHEMA_BASE_URL_BO,
  PAGE_SCHEMA_BASE_URL_DOC,
  PAGE_STATE_SCHEMA_BASE_URL,
  PageDeviceType,
  PageSchemaRequestType,
  PageSchemaService,
  PerspectiveTypes,
  PhoneNumberType,
  PhoneUtils,
  PinyinUtils,
  PromiseUtils,
  PushChannelType,
  QNAME_PREFIX_BO,
  QNAME_PREFIX_DATAVIEW,
  QNAME_PREFIX_DOC,
  RandomService,
  RefEntityUtils,
  SERVICE_API_BASE_URL,
  SortTypeDTO,
  StandardCommandProvider,
  StorageBase,
  StorageType,
  StringUtils,
  StructureDataServiceCreatorUtils,
  StructureMetaUtils,
  StructureQNameType,
  TreeUtils,
  TwCmdOperatorConstrainType,
  TwCmdType,
  TwRTConst,
  TwStateLvType,
  UidUtils,
  ZhNumUtils,
  arrayBuildIdsMap,
  arrayMap,
  arrayToIdList,
  boListCommandStatusDecode,
  colors,
  createColor,
  createEnumService,
  createRefService,
  decodeMomentValue,
  decodeMomentValueAsObject,
  fileSizeFormat,
  findConditionByField,
  findConditionIndexByField,
  formatException,
  formatMomentValue,
  formatString,
  getBirthday,
  getPinyinAsync,
  getRefStInfo,
  getZhNum,
  ifConditionExits,
  isCommandGroup,
  isEmpty,
  isInvalidateTokenErrorCode,
  isValidateCondition,
  mapKeysToArray,
  mapValuesToArray,
  md5Of,
  mergeQueryConfig,
  momentable,
  mustBe,
  mustNotBeNull,
  newBoValue,
  newConditionFromAttribute,
  newEnumValue,
  newEqualCondition,
  newLikeCondition,
  newRuleCondition,
  newRuleSort,
  newSortAsc,
  newSortDesc,
  newSubCondition,
  omitUndefined,
  promiseSafeRun,
  promiseWait,
  queryConditionsByField,
  rangeInForce,
  replaceWhiteSpace,
  structureMetaFill,
  treeBuildFlatMap,
  treeBuildIdsMap,
  treeFilter,
  treeFind,
  treeFlatten,
  treeMap,
  treeTraverse,
  tuple,
  tupleNum,
  uid,
  updateCondition,
  warnEmpty,
  warnNotArrayType
};
