var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
class BfLoggingEvent {
  constructor(categoryName, level, message, logger, exception) {
    __publicField(this, "startTime");
    __publicField(this, "categoryName");
    __publicField(this, "message");
    __publicField(this, "exception");
    __publicField(this, "level");
    __publicField(this, "logger");
    this.startTime = new Date();
    this.categoryName = categoryName;
    this.message = message;
    this.exception = exception;
    this.level = level;
    this.logger = logger;
  }
  getFormattedTimestamp() {
    if (this.logger) {
      return this.logger.getFormattedTimestamp(this.startTime);
    } else {
      return this.startTime.toTimeString();
    }
  }
}
class BfLogAppender {
  constructor() {
    __publicField(this, "logger", null);
    __publicField(this, "layout");
    this.layout = null;
  }
  doAppend(loggingEvent) {
  }
  doClear() {
    return;
  }
  setLayout(layout) {
    this.layout = layout;
  }
  setLogger(logger) {
    logger.onlog.addListener(this.doAppend.bind(this));
    logger.onclear.addListener(this.doClear.bind(this));
    this.logger = logger;
  }
}
const _BfLogLevel = class {
  constructor(level, levelStr) {
    __publicField(this, "level");
    __publicField(this, "levelStr");
    this.level = level;
    this.levelStr = levelStr;
  }
  toLevel(sArg, defaultLevel) {
    if (sArg === null) {
      return defaultLevel;
    }
    if (typeof sArg == "string") {
      const s = sArg.toUpperCase();
      switch (s) {
        case "ALL":
          return _BfLogLevel.ALL;
        case "DEBUG":
          return _BfLogLevel.DEBUG;
        case "INFO":
          return _BfLogLevel.INFO;
        case "WARN":
          return _BfLogLevel.WARN;
        case "ERROR":
          return _BfLogLevel.ERROR;
        case "FATAL":
          return _BfLogLevel.FATAL;
        case "OFF":
          return _BfLogLevel.OFF;
        case "TRACE":
          return _BfLogLevel.TRACE;
        default:
          return defaultLevel;
      }
    } else if (typeof sArg == "number") {
      switch (sArg) {
        case _BfLogLevel.ALL_INT:
          return _BfLogLevel.ALL;
        case _BfLogLevel.DEBUG_INT:
          return _BfLogLevel.DEBUG;
        case _BfLogLevel.INFO_INT:
          return _BfLogLevel.INFO;
        case _BfLogLevel.WARN_INT:
          return _BfLogLevel.WARN;
        case _BfLogLevel.ERROR_INT:
          return _BfLogLevel.ERROR;
        case _BfLogLevel.FATAL_INT:
          return _BfLogLevel.FATAL;
        case _BfLogLevel.OFF_INT:
          return _BfLogLevel.OFF;
        case _BfLogLevel.TRACE_INT:
          return _BfLogLevel.TRACE;
        default:
          return defaultLevel;
      }
    } else {
      return defaultLevel;
    }
  }
  toString() {
    return this.levelStr;
  }
  valueOf() {
    return this.level;
  }
};
let BfLogLevel = _BfLogLevel;
__publicField(BfLogLevel, "OFF_INT", Number.MAX_VALUE);
__publicField(BfLogLevel, "FATAL_INT", 5e4);
__publicField(BfLogLevel, "ERROR_INT", 4e4);
__publicField(BfLogLevel, "WARN_INT", 3e4);
__publicField(BfLogLevel, "INFO_INT", 2e4);
__publicField(BfLogLevel, "DEBUG_INT", 1e4);
__publicField(BfLogLevel, "TRACE_INT", 5e3);
__publicField(BfLogLevel, "ALL_INT", Number.MIN_VALUE);
__publicField(BfLogLevel, "OFF", new _BfLogLevel(_BfLogLevel.OFF_INT, "OFF"));
__publicField(BfLogLevel, "FATAL", new _BfLogLevel(_BfLogLevel.FATAL_INT, "FATAL"));
__publicField(BfLogLevel, "ERROR", new _BfLogLevel(_BfLogLevel.ERROR_INT, "ERROR"));
__publicField(BfLogLevel, "WARN", new _BfLogLevel(_BfLogLevel.WARN_INT, "WARN"));
__publicField(BfLogLevel, "INFO", new _BfLogLevel(_BfLogLevel.INFO_INT, "INFO"));
__publicField(BfLogLevel, "DEBUG", new _BfLogLevel(_BfLogLevel.DEBUG_INT, "DEBUG"));
__publicField(BfLogLevel, "TRACE", new _BfLogLevel(_BfLogLevel.TRACE_INT, "TRACE"));
__publicField(BfLogLevel, "ALL", new _BfLogLevel(_BfLogLevel.ALL_INT, "ALL"));
class BfDateFormatter {
  formatDate(vDate, vFormat) {
    const vDay = this.addZero(vDate.getDate());
    const vMonth = this.addZero(vDate.getMonth() + 1);
    const vYearLong = this.addZero(vDate.getFullYear());
    const vYearShort = this.addZero(vDate.getFullYear().toString().substring(3, 4));
    const vYear = vFormat.indexOf("yyyy") > -1 ? vYearLong : vYearShort;
    const vHour = this.addZero(vDate.getHours());
    const vMinute = this.addZero(vDate.getMinutes());
    const vSecond = this.addZero(vDate.getSeconds());
    const vTimeZone = this.O(vDate);
    let vDateString = vFormat.replace(/dd/g, vDay).replace(/MM/g, vMonth).replace(/y{1,4}/g, vYear);
    vDateString = vDateString.replace(/hh/g, vHour).replace(/mm/g, vMinute).replace(/ss/g, vSecond);
    vDateString = vDateString.replace(/O/g, vTimeZone);
    return vDateString;
  }
  formatUTCDate(vDate, vFormat) {
    const vDay = this.addZero(vDate.getUTCDate());
    const vMonth = this.addZero(vDate.getUTCMonth() + 1);
    const vYearLong = this.addZero(vDate.getUTCFullYear());
    const vYearShort = this.addZero(vDate.getUTCFullYear().toString().substring(3, 4));
    const vYear = vFormat.indexOf("yyyy") > -1 ? vYearLong : vYearShort;
    const vHour = this.addZero(vDate.getUTCHours());
    const vMinute = this.addZero(vDate.getUTCMinutes());
    const vSecond = this.addZero(vDate.getUTCSeconds());
    let vDateString = vFormat.replace(/dd/g, vDay).replace(/MM/g, vMonth).replace(/y{1,4}/g, vYear);
    vDateString = vDateString.replace(/hh/g, vHour).replace(/mm/g, vMinute).replace(/ss/g, vSecond);
    return vDateString;
  }
  addZero(vNumber) {
    return (vNumber < 10 ? "0" : "") + vNumber;
  }
  O(date) {
    const os = Math.abs(date.getTimezoneOffset());
    let h = String(Math.floor(os / 60));
    let m = String(os % 60);
    if (h.length == 1)
      h = "0" + h;
    if (m.length == 1)
      m = "0" + m;
    return date.getTimezoneOffset() < 0 ? "+" + h + m : "-" + h + m;
  }
}
__publicField(BfDateFormatter, "DEFAULT_DATE_FORMAT", "yyyy-MM-ddThh:mm:ssO");
class BfSimpleDataFormatter {
  constructor(pattern) {
    __publicField(this, "formatter");
    __publicField(this, "pattern");
    this.pattern = pattern;
    this.formatter = new BfDateFormatter();
  }
  format(date) {
    return this.formatter.formatDate(date, this.formatter);
  }
}
class BfCustomEvent {
  constructor() {
    __publicField(this, "listeners");
    this.listeners = [];
  }
  addListener(method) {
    this.listeners.push(method);
  }
  removeListener(method) {
    const foundIndexes = this.findListenerIndexes(method);
    for (let i = 0; i < foundIndexes.length; i++) {
      this.listeners.splice(foundIndexes[i], 1);
    }
  }
  dispatch(handler) {
    for (let i = 0; i < this.listeners.length; i++) {
      try {
        this.listeners[i](handler);
      } catch (e) {
        console.warn("Could not run the listener " + this.listeners[i] + ". \n" + e);
      }
    }
  }
  findListenerIndexes(method) {
    const indexes = [];
    for (let i = 0; i < this.listeners.length; i++) {
      if (this.listeners[i] === method) {
        indexes.push(i);
      }
    }
    return indexes;
  }
}
class BfLogLayout {
  format(loggingEvent) {
    return "";
  }
  getContentType() {
    return "text/plain";
  }
  getHeader() {
    return null;
  }
  getFooter() {
    return null;
  }
  getSeparator() {
    return "";
  }
}
function formatException(e) {
  if (e instanceof Error) {
    return e.message + "\n" + e.stack;
  }
  if (typeof e === "string") {
    return e;
  }
  if (e === null || typeof e === "undefined") {
    return "";
  }
  if (typeof e === "object") {
    try {
      return JSON.stringify(e);
    } catch (e2) {
      console.error(e2);
    }
  }
  return "UNKNOWN_FORMAT_EX";
}
class BfLogBasicLayout extends BfLogLayout {
  constructor() {
    super(...arguments);
    __publicField(this, "LINE_SEP", "\n");
  }
  format(loggingEvent) {
    return loggingEvent.categoryName + "~" + loggingEvent.startTime.toLocaleString() + " [" + loggingEvent.level.toString() + "] " + loggingEvent.message + formatException(loggingEvent.exception) + this.LINE_SEP;
  }
  getContentType() {
    return "text/plain";
  }
  getHeader() {
    return "";
  }
  getFooter() {
    return "";
  }
}
class BfLogJsonLayout extends BfLogLayout {
  constructor() {
    super();
    __publicField(this, "df");
    this.df = new BfDateFormatter();
  }
  format(loggingEvent) {
    let jsonString = '{\n "LoggingEvent": {\n';
    jsonString += '	"logger": "' + loggingEvent.categoryName + '",\n';
    jsonString += '	"level": "' + loggingEvent.level.toString() + '",\n';
    jsonString += this.formatMessage(loggingEvent.message);
    jsonString += '	"timestamp": "' + this.df.formatUTCDate(loggingEvent.startTime, "yyyy-MM-ddThh:mm:ssZ") + '",\n';
    jsonString += '	"exception": "' + loggingEvent.exception + '"\n';
    jsonString += "}}";
    return jsonString;
  }
  formatMessage(message) {
    let stream = "";
    if (typeof message == "string") {
      stream += '	"message": "' + message + '",\n';
    } else if (typeof message == "object") {
      if ("message" in message) {
        stream += '	"message": "' + message.message + '",\n';
      }
      for (const property in message) {
        if (property == "message")
          continue;
        const val = message[property];
        if (val instanceof Date)
          stream += '	"' + property + '_dt": "' + this.df.formatUTCDate(val, "yyyy-MM-ddThh:mm:ssZ") + '",\n';
        else {
          switch (typeof val) {
            case "string":
              stream += '	"' + property + '_s": "' + val + '",\n';
              break;
            case "number":
              stream += '	"' + property + '_l": ' + val + ",\n";
              break;
            default:
              stream += '	"' + property + '_s": "' + val.toString() + '",\n';
              break;
          }
        }
      }
    } else {
      stream += '	"message": "' + message.toString() + '",\n';
    }
    return stream;
  }
  getContentType() {
    return "text/json";
  }
  getHeader() {
    return '{"Log4js": [\n';
  }
  getFooter() {
    return "\n]}";
  }
  getSeparator() {
    return ",\n";
  }
}
const _BfLogPatternLayout = class extends BfLogLayout {
  constructor(pattern) {
    super();
    __publicField(this, "pattern");
    this.pattern = pattern || _BfLogPatternLayout.DEFAULT_CONVERSION_PATTERN;
  }
  getContentType() {
    return "text/plain";
  }
  getHeader() {
    return null;
  }
  getFooter() {
    return null;
  }
  format(loggingEvent) {
    const regex = /%(-?[0-9]+)?(\.?[0-9]+)?([cdmnpr%])(\{([^}]+)\})?|([^%]+)/;
    let formattedString = "";
    let result;
    let searchString = this.pattern;
    while (result = regex.exec(searchString)) {
      const matchedString = result[0];
      const padding = result[1];
      const truncation = result[2];
      const conversionCharacter = result[3];
      const specifier = result[5];
      const text = result[6];
      if (text) {
        formattedString += "" + text;
      } else {
        let replacement = "";
        switch (conversionCharacter) {
          case "c": {
            const loggerName = loggingEvent.categoryName;
            if (specifier) {
              const precision = parseInt(specifier, 10);
              const loggerNameBits = loggingEvent.categoryName.split(".");
              if (precision >= loggerNameBits.length) {
                replacement = loggerName;
              } else {
                replacement = loggerNameBits.slice(loggerNameBits.length - precision).join(".");
              }
            } else {
              replacement = loggerName;
            }
            break;
          }
          case "d": {
            let dateFormat = _BfLogPatternLayout.ISO8601_DATEFORMAT;
            if (specifier) {
              dateFormat = specifier;
              if (dateFormat == "ISO8601") {
                dateFormat = _BfLogPatternLayout.ISO8601_DATEFORMAT;
              } else if (dateFormat == "ABSOLUTE") {
                dateFormat = _BfLogPatternLayout.ABSOLUTETIME_DATEFORMAT;
              } else if (dateFormat == "DATE") {
                dateFormat = _BfLogPatternLayout.DATETIME_DATEFORMAT;
              }
            }
            replacement = new BfSimpleDataFormatter(dateFormat).format(loggingEvent.startTime);
            break;
          }
          case "m": {
            replacement = loggingEvent.message;
            if (loggingEvent.exception) {
              replacement += formatException(loggingEvent.exception);
            }
            break;
          }
          case "n": {
            replacement = "\n";
            break;
          }
          case "p": {
            replacement = loggingEvent.level.toString();
            break;
          }
          case "r": {
            replacement = "" + loggingEvent.startTime.toLocaleTimeString();
            break;
          }
          case "%": {
            replacement = "%";
            break;
          }
          default: {
            replacement = matchedString;
            break;
          }
        }
        let len;
        if (truncation) {
          len = parseInt(truncation.substr(1), 10);
          replacement = replacement.substring(0, len);
        }
        if (padding) {
          if (padding.charAt(0) == "-") {
            len = parseInt(padding.substr(1), 10);
            while (replacement.length < len) {
              replacement += " ";
            }
          } else {
            len = parseInt(padding, 10);
            while (replacement.length < len) {
              replacement = " " + replacement;
            }
          }
        }
        formattedString += replacement;
      }
      searchString = searchString.substr(result.index + result[0].length);
    }
    return formattedString;
  }
};
let BfLogPatternLayout = _BfLogPatternLayout;
__publicField(BfLogPatternLayout, "TTCC_CONVERSION_PATTERN", "%r %p [%c] %m%n");
__publicField(BfLogPatternLayout, "DEFAULT_CONVERSION_PATTERN", "%m%n");
__publicField(BfLogPatternLayout, "ISO8601_DATEFORMAT", "yyyy-MM-dd HH:mm:ss,SSS");
__publicField(BfLogPatternLayout, "DATETIME_DATEFORMAT", "dd MMM YYYY HH:mm:ss,SSS");
__publicField(BfLogPatternLayout, "ABSOLUTETIME_DATEFORMAT", "HH:mm:ss,SSS");
class BfLogSimpleLayout extends BfLogLayout {
  constructor() {
    super(...arguments);
    __publicField(this, "LINE_SEP", "\n");
    __publicField(this, "LINE_SEP_LEN", 1);
  }
  format(loggingEvent) {
    return loggingEvent.level.toString() + " - " + loggingEvent.message + formatException(loggingEvent.exception) + this.LINE_SEP;
  }
  getContentType() {
    return "text/plain";
  }
  getHeader() {
    return "";
  }
  getFooter() {
    return "";
  }
}
class BfLogConsoleAppender extends BfLogAppender {
  constructor(inline = true) {
    super();
    __publicField(this, "inline");
    __publicField(this, "outputCount", 0);
    this.layout = new BfLogPatternLayout(BfLogPatternLayout.TTCC_CONVERSION_PATTERN);
    this.inline = inline;
  }
  doAppend(loggingEvent) {
    let style = "";
    let logFunc;
    if (loggingEvent.level.valueOf() === BfLogLevel.ERROR.valueOf()) {
      style += "color:red";
      logFunc = console.error;
    } else if (loggingEvent.level.valueOf() === BfLogLevel.FATAL.valueOf()) {
      style += "color:red";
      logFunc = console.error;
    } else if (loggingEvent.level.valueOf() === BfLogLevel.WARN.valueOf()) {
      style += "color:orange";
      logFunc = console.warn;
    } else if (loggingEvent.level.valueOf() === BfLogLevel.DEBUG.valueOf()) {
      style += "color:green";
      logFunc = console.debug;
    } else if (loggingEvent.level.valueOf() === BfLogLevel.INFO.valueOf()) {
      style += "color:white";
      logFunc = console.info;
    } else {
      style += "color:yellow";
      logFunc = console.log;
    }
    this.output(this.layout.format(loggingEvent), style, logFunc);
  }
  output(message, style, logFunc) {
    this.outputCount++;
    if (this.outputCount % 2 === 0)
      ;
    message = message || "undefined";
    message = message.toString();
    logFunc(message);
  }
}
const internalConf = {
  level: BfLogLevel.ERROR,
  appenderFactory: () => [new BfLogConsoleAppender()]
};
function setConfiguration(params) {
  if (params.level) {
    internalConf.level = params.level;
  }
  if (params.appenderFactory) {
    internalConf.appenderFactory = params.appenderFactory;
  }
}
function getConfiguration() {
  return internalConf;
}
class BfLogger {
  constructor(name) {
    __publicField(this, "loggingEvents");
    __publicField(this, "appenders");
    __publicField(this, "category");
    __publicField(this, "level", BfLogLevel.ERROR);
    __publicField(this, "dateformat");
    __publicField(this, "dateformatter");
    __publicField(this, "onlog");
    __publicField(this, "onclear");
    this.loggingEvents = [];
    this.appenders = [];
    this.category = name || "";
    this.dateformat = BfDateFormatter.DEFAULT_DATE_FORMAT;
    this.dateformatter = new BfDateFormatter();
    this.onlog = new BfCustomEvent();
    this.onclear = new BfCustomEvent();
    this.updateConfiguration(getConfiguration());
  }
  updateConfiguration(configuration) {
    this.level = configuration.level;
    this.setAppenders(configuration.appenderFactory());
  }
  addAppender(appender) {
    if (appender instanceof BfLogAppender) {
      appender.setLogger(this);
      this.appenders.push(appender);
    } else {
      throw "Not instance of an Appender: " + appender;
    }
  }
  setAppenders(appenders) {
    for (let i = 0; i < this.appenders.length; i++) {
      this.appenders[i].doClear();
    }
    this.appenders = appenders;
    for (let j = 0; j < this.appenders.length; j++) {
      this.appenders[j].setLogger(this);
    }
  }
  setLevel(level) {
    this.level = level;
  }
  log(logLevel, message, exception) {
    const loggingEvent = new BfLoggingEvent(this.category, logLevel, message, this, exception);
    this.loggingEvents.push(loggingEvent);
    this.onlog.dispatch(loggingEvent);
  }
  clear() {
    try {
      this.loggingEvents = [];
      this.onclear.dispatch();
    } catch (e) {
      console.error(e);
    }
  }
  isTraceEnabled() {
    if (this.level.valueOf() <= BfLogLevel.TRACE.valueOf()) {
      return true;
    }
    return false;
  }
  trace(message) {
    if (this.isTraceEnabled()) {
      this.log(BfLogLevel.TRACE, message, null);
    }
  }
  isDebugEnabled() {
    if (this.level.valueOf() <= BfLogLevel.DEBUG.valueOf()) {
      return true;
    }
    return false;
  }
  debug(message, throwable) {
    if (this.isDebugEnabled()) {
      this.log(BfLogLevel.DEBUG, message, throwable);
    }
  }
  isInfoEnabled() {
    if (this.level.valueOf() <= BfLogLevel.INFO.valueOf()) {
      return true;
    }
    return false;
  }
  info(message, throwable = null) {
    if (this.isInfoEnabled()) {
      this.log(BfLogLevel.INFO, message, throwable);
    }
  }
  isWarnEnabled() {
    if (this.level.valueOf() <= BfLogLevel.WARN.valueOf()) {
      return true;
    }
    return false;
  }
  warn(message, throwable = null) {
    if (this.isWarnEnabled()) {
      this.log(BfLogLevel.WARN, message, throwable);
    }
  }
  isErrorEnabled() {
    if (this.level.valueOf() <= BfLogLevel.ERROR.valueOf()) {
      return true;
    }
    return false;
  }
  error(message, throwable) {
    if (this.isErrorEnabled()) {
      this.log(BfLogLevel.ERROR, message, throwable);
    }
  }
  isFatalEnabled() {
    if (this.level.valueOf() <= BfLogLevel.FATAL.valueOf()) {
      return true;
    }
    return false;
  }
  fatal(message, throwable = null) {
    if (this.isFatalEnabled()) {
      this.log(BfLogLevel.FATAL, message, throwable);
    }
  }
  setDateFormat(format) {
    this.dateformat = format;
  }
  getFormattedTimestamp(date) {
    return this.dateformatter.formatDate(date, this.dateformat);
  }
}
const BfLog4js = {
  version: "2.0.0",
  applicationStartDate: new Date(),
  loggers: {},
  configure: function(conf) {
    setConfiguration(conf);
    Object.keys(this.loggers).forEach((key) => {
      this.loggers[key].updateConfiguration(conf);
    });
  },
  getConfiguration() {
    return getConfiguration();
  },
  getLogger: function(categoryName) {
    if (typeof categoryName !== "string") {
      categoryName = "[default]";
    }
    if (!BfLog4js.loggers[categoryName]) {
      BfLog4js.loggers[categoryName] = new BfLogger(categoryName);
    }
    return BfLog4js.loggers[categoryName];
  },
  getDefaultLogger: function() {
    return BfLog4js.getLogger("[default]");
  }
};
export {
  BfCustomEvent,
  BfDateFormatter,
  BfLog4js,
  BfLogAppender,
  BfLogBasicLayout,
  BfLogConsoleAppender,
  BfLogJsonLayout,
  BfLogLayout,
  BfLogLevel,
  BfLogPatternLayout,
  BfLogSimpleLayout,
  BfLogger,
  BfLoggingEvent,
  BfSimpleDataFormatter
};
